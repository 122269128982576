import React from 'react';
import { connect } from 'react-redux';
import '../assets/css/SearchInput.css'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSearchResultsInitiated } from '../actions/actions'

const mapStateToProps = state => {
	return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    searchCoaches: (word) => dispatch(getSearchResultsInitiated(word)),
  }
}

class SearchInput extends React.Component {
	constructor() {
		super()
		this.state = {
			search: ''
		}
	}

	handleChange = (event) => {
    this.setState({search: event.target.value});
  }

	submitSearch = (event) => {
		
		event.preventDefault();
		if(this.state.search.length>0)
		this.props.history.push(`/${this.props.path}/${this.state.search}`);
		// this.props.searchCoaches(this.state.search)
	}

	render() {
		return (
			<form className="searchInput" style={{bottom:this.props.bottom,width:this.props.width}} onSubmit={this.submitSearch}>
				<div className="input-group mb-3 search rounded">
					<div className="input-group-prepend">
						<span className="input-group-text">
							<FontAwesomeIcon icon={faSearch} inverse={true} />
						</span>
					</div>
					<input placeholder="Search. Share. Read Reviews" type="text" className="form-control txbSearchBox" onChange={this.handleChange} />
					<div className="input-group-append">
						<button className="btn btnSearch">Search</button>
					</div>
				</div>
			</form>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);