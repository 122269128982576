import React from "react";
import { connect } from "react-redux";
import "../assets/css/SearchPage.css";
import CoachCard from "../components/CoachCard"
import SearchInput from "../components/SearchInput";
import AdvertisementCard from "../components/AdvertisementCard";
import { setNavbarCustomCss,getSearchResultsInitiated } from '../actions/actions'
import api from '../api';


const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchCoaches: (keyword, from, size) => dispatch(getSearchResultsInitiated({keyword, from, size})),
    setNavbarCustomCss: data => dispatch(setNavbarCustomCss(data))
  }
}


class SearchPage extends React.Component {

  constructor() {
    super()
    this.state = {
      keyword:'',
      from:0,
      size:5,
      hasMore:true,
      CoachesResultSet:[],
    }
  }
  componentDidMount=async()=> {
   
    this.props.setNavbarCustomCss({
      navbarMode:'darkmode'
    });

    let res=await api.getSearchResults(this.props.match.params.keyword, 0, this.state.size);
    var hasMore=false;
    if(res.data.data.length>0){
      hasMore=true;
    }
    this.setState({
      hasMore:hasMore,
      CoachesResultSet:res.data.data,
      from:this.state.size
    });
    

  }

  componentDidUpdate=async(prevProps,prevState)=> {
    if(prevProps!=this.props){
      const keyword = this.props.match.params.keyword;
      if (prevProps.match.params.keyword !== this.props.match.params.keyword) {
        let res=await api.getSearchResults(this.props.match.params.keyword, 0, this.state.size);
        
        var hasMore=false;
        if(res.data.data.length>0){
          hasMore=true;
        }
    
        this.setState({
          hasMore:hasMore,
          CoachesResultSet:res.data.data,
          from:this.state.size
        });
      }
    }
  }

  // loadMoreProfiles = () => {
  //   this.setState({from: this.state.from + this.state.size}, () => {
	// 	  this.props.searchCoaches(this.state.keyword, this.state.from, this.state.size);
  //   })
  // }
  loadMore=async()=>{
   
    let res=await api.getSearchResults(this.props.match.params.keyword, this.state.from, this.state.size);
    var hasMore=false;
    if(res.data.data.length>0){
      hasMore=true;
    }
    var nextResults=this.state.CoachesResultSet;
    res.data.data.map((prop) => {

      nextResults.push(prop);
     
    });

    this.setState({
      hasMore:hasMore,
      CoachesResultSet:nextResults,
      from:this.state.from+this.state.size
    });
  }

  render() {
   
 
    return (
      <div className="col-xl-12">
        <section  style={{minHeight: window.innerHeight/1.5}}>
            <div className="row searchPageLandingSection"  style={{ minHeight: window.innerHeight/1.5,maxHeight: window.innerHeight/1.5}}>
              <div className="col-xl-1"></div>
                <div className="col-xl-10" style={{ minHeight: window.innerHeight/1.5,textAlign:"center"}}>
                <div className="row SearchPageRow">
                <div className="col-xl-12" style={{textAlign:"center"}}>
                    <span className="SearchPageLandingSectionTitle">
                    Your Reviews Matter
                    </span>
                  </div>
                  <div className="col-xl-12" style={{textAlign:"center"}}>
                    <span className="SearchPageLandingSectionSubTitle">Get The Truth About Online Authority Brands.</span>
                  </div>
                  <div className="col-xl-2" style={{textAlign:"center"}}> 
                  </div>
                  <div className="col-xl-8" style={{textAlign:"center",paddingTop:"5%"}}> 
                   <SearchInput history={this.props.history} path='search'/>
                  </div>
                  <div className="col-xl-2" style={{textAlign:"center"}}> 
                  </div>
                  </div>
                
                 
                
                  
                </div>
                <div className="col-xl-1"></div>
            </div>
            <div className="row searchPageBackground">
              <div className="col-xl-1"></div>
              <div className="col-xl-10">
                  {/* <div className="row">
                      <div className="col-xl-12">
                        <AdvertisementCard />
                      </div>
                  </div> */}
                  
                  <div className="row">
                      <div className="col-xl-12">
                        <CoachCard loadMore={this.loadMore} hasMore={this.state.hasMore} CoachesResultSet={this.state.CoachesResultSet} length={this.state.CoachesResultSet.length} />
                      </div>
                  </div>
                  {/* {this.props.search.searchResults.map((coach, i) => {
                    return <div className="row" key={i}>
                      <div className="col-xl-12">
                      <CoachCard coach={coach}/>
                      {(i+1)%3 === 0 ? <AdvertisementCard/> : null}
                      </div></div> ;
                  })
                  } */}
                  {/* <div className="row" style={{padding:"10px 0px"}}>
                    <div className="col-xl-12" style={{display:"flex",justifyContent:"center",alignItems:"center" }}>
                        <button hidden={this.props.search.searchResultsLastPageReached} className="btn btn-primary" style={{background: "#0B3790"}} onClick={this.loadMoreProfiles}>Load More</button>
                        <span className="Nomoreresults" hidden={!this.props.search.searchResultsLastPageReached}>No more results.</span>
                    </div>
                  </div> */}
              </div>
              <div className="col-xl-1"></div>
            </div>
        </section>
 
    
      </div>
    );
    
  
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(SearchPage);
