import {
  CONTACT_ME_DIRECTLY_INITIATED,
  CONTACT_ME_DIRECTLY_SUCCESS,
  CONTACT_ME_DIRECTLY_FAILURE,
  CONTACT_ME_DIRECTLY_RESET,
  CONTACT_US_INITIATED,
  CONTACT_US_SUCCESS,
  CONTACT_US_FAILURE,
  CONTACT_US_RESET,
} from "../constants/constants";
const initialState = {
  contactMeDirectlyOperationCompleted : false,
  contactMeDirectlyOperationStatus:"Not_Started",
  contactUsOperationCompleted: false,
  contactUsOperationStatus:"Not_Started",
};
const messageReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONTACT_ME_DIRECTLY_INITIATED:
      return { ...state, contactMeDirectlyOperationCompleted: false,contactMeDirectlyOperationStatus:"InProcess"};
    case CONTACT_ME_DIRECTLY_SUCCESS:
        return { ...state,  contactMeDirectlyOperationCompleted: true, contactMeDirectlyOperationStatus:"Completed_Success"};
    case CONTACT_ME_DIRECTLY_FAILURE:
      return { ...state,  contactMeDirectlyOperationCompleted: true,contactMeDirectlyOperationStatus:"Completed_Failed", };
      case CONTACT_ME_DIRECTLY_RESET:
      return { ...state,  contactMeDirectlyOperationCompleted: false,contactMeDirectlyOperationStatus:"Not_Started", };
    case CONTACT_US_INITIATED:
      return { ...state, contactUsOperationCompleted : false,contactUsOperationStatus:"InProcess"  };
    case CONTACT_US_SUCCESS:
      return { ...state, contactUsOperationCompleted: true,contactUsOperationStatus:"Completed_Success" };
    case CONTACT_US_FAILURE:
      return { ...state, contactUsOperationCompleted: true,contactUsOperationStatus:"Completed_Failed" };
      case CONTACT_US_RESET:
      return { ...state,  contactUsOperationCompleted: false,contactUsOperationStatus:"Not_Started" };
    default:
      return state;
  }
};

export default messageReducer;
