import React from "react";
import { Link } from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faStar as farStar,
  faThumbsUp as farThumbsUp
} from "@fortawesome/free-regular-svg-icons";
import {
  faStar as fasStar,
  faPlay as fasPlay,
  faBullhorn
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faLinkedinIn,
  faFacebook
} from "@fortawesome/free-brands-svg-icons";
import Rating from "react-rating";
import C3Chart from "react-c3js";

import RateThisCoach from "./RateThisCoach";
import Badge from "../components/Badge";
import {
  getProfileByIdInitiated,
  getUserProfileInitiated,
  getCommentsByIdInitiated,
  postCommentInitiated,
  deleteCommentInitiated,
  postMessageToCoachInitiated,
  setNavbarCustomCss
} from "../actions/actions";
import "c3/c3.css";
import "../assets/css/Profile.css";
import ProfilePicture from "../components/ProfilePicture";
import { levelRestrictions } from "../constants/level-restrictions";
import { message, Spin, Modal, Button, Icon } from "antd";
import { thisExpression } from "@babel/types";
import AuthorizationProvider from "../AuthorizationProvider/authorizationProvider";
import YouTube from "react-youtube";
import ReactPlayer from "react-player";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Badges from "../constants/badges";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";
import { ColumnWidthOutlined,
  DeleteOutlined ,
  HighlightOutlined ,
  RiseOutlined ,
  CheckOutlined,
  CheckCircleOutlined ,
  CloseCircleOutlined,
  StarOutlined,
  AimOutlined,

 } from '@ant-design/icons'


const mapStateToProps = state => {
  return state;
};
const mapDispatchToProps = dispatch => {
  return {
    searchProfileById: category => dispatch(getProfileByIdInitiated(category)),
    getUserProfile: () => dispatch(getUserProfileInitiated()),
    getComments: id => dispatch(getCommentsByIdInitiated(id)),
    rateThisCoach: data => dispatch(postCommentInitiated(data)),
    deleteComment: data => dispatch(deleteCommentInitiated(data)),
    contactMeDirectly: data => dispatch(postMessageToCoachInitiated(data)),
    setNavbarCustomCss: data => dispatch(setNavbarCustomCss(data))
  };
};
class CoachProfile extends React.Component {
  constructor() {
    super();
    this.state = {
      isMounted: false,
      hasUserRatedThisCoach: false,
      isThisCoachesOwnProfile: false,
      refreshComments: false,
      yourEmail:"",
      yourMessage:""
    };
  }
  componentDidMount() {

    if(this.props.oauth.isUserAuthenticated &&  !this.props.user.userProfile.level){
      this.setState({messageLevel:"error",
            message:"An error occured while getting your profile. Please contact your system administrator.",
            isMounted:false, 
            errorOnGettingProfile:true
            },
            () => this.Notify())
            setTimeout(
              function() {
                this.props.history.push('/')
              }
              .bind(this),
              5000
          );
    }
    this.currentUserAuthorizationProvider = new AuthorizationProvider(
      this.props.user.userProfile.level
    );
    this.authorizationProvider = new AuthorizationProvider(
      ""
    );
  
    this.props.setNavbarCustomCss({
      navbarMode:'lightmode'
    });
    // if (this.props.oauth.isUserAuthenticated) {
    //   this.props.getUserProfile();
    // }
    let coachId = this.props.match.params.id;
    this.props.searchProfileById(coachId);
    this.props.getComments(coachId);
    this.setState({ isMounted: true,yourEmail:this.props.user.userProfile.email });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps != this.props) {
      if (this.props.oauth.isUserAuthenticated) {
        if (this.props.comments.getCoachCommentsOperationCompleted) {
          let userComment = this.props.comments.coachComments.filter(
            comment => comment.commentee.id === this.props.user.userProfile._id
          );
          if (userComment.length) {
            this.setState({ hasUserRatedThisCoach: true });
          }
        }
     
      }
      if (this.props.search.getProfileByIdOperationCompleted) {
         
         
        console.log("coach profile");
        console.log(this.props.search.coachProfile);
        if (
          this.props.search.coachProfile._id ===
          this.props.user.userProfile._id
        ) {

          
          this.setState({ isThisCoachesOwnProfile: true });
        }
        this.authorizationProvider = new AuthorizationProvider(
          this.props.search.coachProfile.level
        );
      

      }
      if (this.props.comments.postCommentOperationCompleted) {
        if (
          this.props.comments.postCommentOperationStatus == "Completed_Success"
        ) {
          this.setState(
            {
              messageLevel: "success",
              message: "Your feedback posted successfully.",
              refreshComments: true,
              hasUserRatedThisCoach: true
            },
            () => {
              this.Notify();
              //this.props.getComments(this.props.match.params.id);
            }
          );
        } else if (
          this.props.comments.postCommentOperationStatus == "Completed_Failed"
        ) {
          if (this.props.comments.postCommetResult.status == 422) {
            this.setState({
              hasUserRatedThisCoach: true
            });
          } else {
            this.setState(
              {
                messageLevel: "error",
                message: "Failed to post your feedback.Please try again."
              },
              () => this.Notify()
            );
          }
        }
      }
      if (this.props.comments.deleteCommentOperationCompleted) {
        if (
          this.props.comments.deleteCommentOperationStatus ==
          "Completed_Success"
        ) {
          this.setState(
            {
              messageLevel: "success",
              message: "Comment deleted successfully.",
              refreshComments: true
            },
            () => {
              this.Notify();
              //this.props.getComments(this.props.match.params.id);
            }
          );
        } else if (
          this.props.comments.deleteCommentOperationStatus == "Completed_Failed"
        ) {
          this.setState(
            {
              messageLevel: "error",
              message: "Failed to delete comment.Please try again."
            },
            () => this.Notify()
          );
        }
      }
      if (this.props.message.contactMeDirectlyOperationCompleted) {
        if (
          this.props.message.contactMeDirectlyOperationStatus ==
          "Completed_Success"
        ) {
          this.setState(
            {
              messageLevel: "success",
              message: "An email is sent to this person successfully."
            },
            () => this.Notify()
          );
        } else if (
          this.props.message.contactMeDirectlyOperationStatus ==
          "Completed_Failed"
        ) {
          this.setState(
            { messageLevel: "error", message: "Failed to send an email." },
            () => this.Notify()
          );
        }
      }
      if (this.state.refreshComments) {
        this.setState({ refreshComments: false }, () =>
          this.props.getComments(this.props.match.params.id)
        );
      }
    }
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  getYoutubeVideoId = url => {
    let id = "";
    url = url
      .replace(/(>|<)/gi, "")
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      id = url[2].split(/[^0-9a-z_\-]/i);
      id = id[0];
    } else {
      id = url;
    }
    return id;
  };
  closeContactMeDirectly = () => {
    this.setState({ contactMeDirectly: false });
  };
  openContactMeDirectly = () => {
    this.setState({ contactMeDirectly: true });
  };
  closeRateThisCoach = () => {
    this.setState({ rateThisCoach: false });
  };
  openRateThisCoach = () => {
    if (this.state.hasUserRatedThisCoach) {
      //alert("You have already rated this coach.")
      return;
    }
    if (this.state.isThisCoachesOwnProfile) {
      //alert("You cannot rate yourself.")
      return;
    }
    this.setState({ rateThisCoach: true });
  };
  LoginToRateCoach=()=>{
    localStorage.setItem("LoginToRateCoachName", this.props.match.params.name);
    localStorage.setItem("LoginToRateCoachId", this.props.match.params.id);
    this.props.history.push("/signin");
  }
  submitContactMeDirectly = () => {

    if(this.state.yourEmail.length==0){
      this.setState(
        { messageLevel: "error", message: "Please eneter your email address." },
        () => this.Notify()
      );
    }
    else if(!this.ValidateEmail(this.state.yourEmail)){
      this.setState(
        { messageLevel: "error", message: "Please eneter a valid email address." },
        () => this.Notify()
      );
    }
    else if(this.state.yourMessage.trim().length==0){
      this.setState(
        { messageLevel: "error", message: "Please write some message." },
        () => this.Notify()
      );
    }
   
    else if(this.state.yourEmail.length>0 &&this.state.yourMessage.length>0 && this.ValidateEmail(this.state.yourEmail))
    {
    const body = {
      to: this.props.search.coachProfile.email,
      from: this.state.yourEmail,
      subject: "A Message from AuthorityAlert platform",
      text: this.state.yourMessage,
      html: this.state.yourMessage
    };
    this.props.contactMeDirectly(body);
    }
    //this.closeContactMeDirectly();
  };
 ValidateEmail=(mail) =>
  {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      return (true)
    }
      
      return (false)
  }
  submitRateThisCoach = (event, state) => {
    event.preventDefault();
    if (state.badges.length > 3) {
      this.setState(
        { messageLevel: "error", message: "You can only select upto 3 badges." },
        () => this.Notify()
      );
      return;
    }
    if (!state.starRatingAwardded) {
      this.setState(
        {
          messageLevel: "error",
          message: "Kindly select star ratings for the coach."
        },
        () => this.Notify()
      );
      return;
    }
    const data = {
      userId: this.props.search.coachProfile._id,
      commentee: {
        id: this.props.user.userProfile._id,
        name: this.props.user.userProfile.fullName,
        photoLink: this.props.user.userProfile.photoLink
      },
      createdAt: Date.now(),
      rating: state.rating,
      comment: state.comment,
      badges: state.badges
    };
    this.props.rateThisCoach(data);

    this.closeRateThisCoach();
  };
  claimThisProfile = () => {
    localStorage.setItem("claimProfile", this.props.search.coachProfile._id);
    localStorage.setItem("name", this.props.search.coachProfile.fullName);
    this.props.history.push("/signup");
  };
  deleteComment = commentId => {
    this.props.deleteComment(commentId);
    //this.props.getComments(this.props.match.params.id);
  };
  spinner = () => {
    return (
      <div className="row">
        <div
          className="col-xl-12"
          style={{
            textAlign: "center",
            position: "absolute",
            top: "20px",
            zIndex: "1000"
          }}
        >
          <Spin size="large"></Spin>
        </div>
      </div>
    );
  };
  spinnerModalPopup = () => {
    return (
      <div style={{ position: "absolute", left: "50%", top: "10px" }}>
        <Spin size="large"></Spin>
      </div>
    );
  };
  Notify = () => {
    if (this.state.messageLevel == "success") {
      message.success(this.state.message);
    } else if (this.state.messageLevel == "error") {
      message.error(this.state.message);
    }
  };
  render() {
    const {
      _id,
      fullName,
      photoLink,
      rating,
      website,
      aboutMe,
      fbProfilePage,
      //linkedIn,
      categoryNames,
      storyVideoLinks,
     
      score = 0,
      trainingVideoLinks,
      level
    } = this.props.search.coachProfile;

    let allBadges=[
      { name: "Great Communicator", count: 0,imgName:"Communicator_img.png" },
      { name: "Very Intuitive", count: 0 ,imgName:"Intuitive_img.png"},
      { name: "Problem Solver", count: 0,imgName:"Problem Solver_img.png"},
      { name: "Very Smart", count: 0,imgName:"Smart_img.png"},
      { name: "A True Specialist", count: 0 ,imgName:"Specialist_img.png"},
      { name: "Always On Time", count: 0 ,imgName:"Time_img.png"},
      { name: "Has Vision", count: 0,imgName:"Vision_img.png"},
      { name: "Great Friend", count: 0,imgName:"Good_Friend_img.png"}];
   
    if (this.props.search.coachProfile.badges && Array.isArray(this.props.search.coachProfile.badges)) {

      allBadges=allBadges.map((obj1) => {
        this.props.search.coachProfile.badges.map((obj2)=>{

          if(obj2.name === obj1.name){
            obj1.count=obj2.count;
            
          }
        });
        return obj1;
       
      }); 
    }
  
    return (
      this.state.isMounted && (
        <div className="col-xl-12">
          {(this.props.comments.getCoachCommentsOperationStatus ==
            "InProcess" ||
            this.props.search.getProfileByIdOperationStatus == "InProcess" ||
            this.props.comments.postCommentOperationStatus == "InProcess") &&
            this.spinner()}
          {this.props.message.contactMeDirectlyOperationStatus == "InProcess" &&
            this.spinnerModalPopup()}
          <Modal
            maskClosable={false}
            title="Contact Me Directly"
            onOk={this.submitContactMeDirectly}
            onCancel={this.closeContactMeDirectly}
            visible={this.state.contactMeDirectly}
            footer={[
              <Button
                key="submit"
                className="btnContactMeDirectlySendMessage"
                onClick={this.submitContactMeDirectly}
              >
                Send Message
              </Button>
            ]}
          >
            <div className="row">
              <div className="col-xl-12" style={{ paddingBottom: "10px" }}>
                <span className="enterYourEmail">Enter your email</span>
              </div>
              <div className="col-xl-12" style={{ paddingBottom: "10px" }}>
                <input
                  type="email"
                  className="form-control"
                  name="yourEmail"
                  onChange={this.handleChange}
                  value={this.state.yourEmail}
                />
              </div>
              <div className="col-xl-12" style={{ paddingBottom: "10px" }}>
                <span className="enterYourEmail">
                  What do you want to discuss?
                </span>
              </div>
              <div className="col-xl-12" style={{ paddingBottom: "10px" }}>
                <textarea
                  className="form-control"
                  name="yourMessage"
                  onChange={this.handleChange}
                  value={this.state.yourMessage}
                ></textarea>
              </div>
            </div>
          </Modal>
          <RateThisCoach
            show={this.state.rateThisCoach}
            handleClose={this.closeRateThisCoach}
            handleSubmit={this.submitRateThisCoach}
          />
          <section
            id="main-section"
            style={{ minHeight: window.innerHeight - 150 }}
          >
            <div className="row" style={{ width: "100%" }}>
              <div className="col-xl-1"></div>
              <div className="col-xl-10">
                <div className="row" style={{ paddingBottom: "30px" }}>
                  <div className="col-xl-12">
                    <div className="row" style={{ margin: "0px" }}>
                      {this.authorizationProvider.userCanReceiveEmailDirectly &&
                                            
                      <div
                        className="col-xl-2"
                        style={{ padding: "0px", paddingBottom: "10px" }}
                      >
                        <button
                          onClick={this.openContactMeDirectly}
                          className="btnContactMeDirectly btn"
                        >
                          Contact me directly{" "}
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            inverse={true}
                            style={{ marginLeft: "5px" }}
                          />
                        </button>
                      </div>
                       }
                      <div className="col-xl-10" style={{ padding: "0px" }}>
                        {categoryNames && categoryNames.length > 0 ? (
                          categoryNames.map((category,i) => {
                            return (
                              <Link to={{pathname: `/category/${category}`}} key={i}  className="btn btnBadgePill">
                              {category}
                              </Link>
                              // <button
                              //   className="btn btnBadgePill"
                              //   key={category}
                              // >
                              //   {category}
                              // </button>
                            );
                          })
                        ) : (
                          <span></span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ margin: "0px" }}>
                  <div
                    className="col-xl-8"
                    style={{
                      border: "1px solid #beceeb",
                      borderRadius: "4px",
                      backgroundColor: "white"
                    }}
                  >
                    <div className="row">
                      <div
                        className="col-xl-4"
                        style={{
                          border: "1px solid #f3f3f9",
                          backgroundColor: "#f3f3f9"
                        }}
                      >
                        <div className="row" style={{ paddingTop: "10px" }}>
                          <div className="col-xl-1"> </div>
                          <div
                            className="col-xl-10"
                            style={{ textAlign: "center" }}
                          >
                            {photoLink && photoLink !== "" ? (
                              <ProfilePicture
                                photoLink={photoLink}
                              ></ProfilePicture>
                            ) : (
                              <ProfilePicture photoLink={null}></ProfilePicture>
                            )}
                          </div>
                          <div className="col-xl-1"> </div>
                        </div>
                        <div
                          className="row"
                          style={{
                            marginTop: "10px",
                            backgroundColor: "#dce2ef"
                          }}
                        >
                          <div
                            className="col-xl-12"
                            style={{ textAlign: "center" }}
                          >
                            <span className="username">{fullName}</span>
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{
                            backgroundColor: "#e7ecf4",
                            padding: "10px 0px"
                          }}
                        >
                          <div
                            className="col-xl-12"
                            style={{ textAlign: "center" }}
                          >
                            Ratings
                            <span className="star-block">
                              <Rating
                                emptySymbol={
                                  <FontAwesomeIcon
                                    icon={farStar}
                                    style={{ color: "#DF0000" }}
                                    inverse={true}
                                  />
                                }
                                fullSymbol={
                                  <FontAwesomeIcon
                                    icon={fasStar}
                                    style={{ color: "#DF0000" }}
                                    inverse={true}
                                  />
                                }
                                fractions={2}
                                initialRating={rating}
                                readonly
                              />{" "}
                              {rating}
                            </span>
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: "30px" }}>
                          <div
                            className="col-xl-12"
                            style={{ textAlign: "center" }}
                          >
                            <h6>Badges Awarded</h6>
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: "10px" }}>
                          <div
                            className="col-xl-12"
                            style={{ textAlign: "center" }}
                          >
                            {allBadges.length ? (
                              allBadges.map((badge, i) => {
                                return (
                                  <Badge
                                    name={badge.name}
                                    imgName={badge.imgName}
                                    count={badge.count}
                                    key={i}
                                    width="50px"
                                  />
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-xl-12"
                            style={{ textAlign: "center" }}
                          >
                            <C3Chart
                              data={{
                                columns: [["data", score]],
                                type: "gauge"
                              }}
                              color={{
                                pattern: ["#0B3790"]
                              }}
                              gauge={{
                                min: 0, // 0 is default, //can handle negative min e.g. vacuum / voltage / current flow / rate of change
                                max: 100, // 100 is default
                                units: "",
                                width: 35 // for adjusting arc thickness
                              }}
                              legend={{
                                show: false
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{ marginTop: "10px", paddingBottom: "10px" }}
                        >
                          <div
                            className="col-xl-12"
                            style={{ textAlign: "center" }}
                          >
                            {this.props.oauth.isUserAuthenticated ? (
                              <button
                                onClick={this.openRateThisCoach}
                                style={{
                                  display:
                                    !this.state.hasUserRatedThisCoach &&
                                    !this.state.isThisCoachesOwnProfile
                                      ? "inline-block"
                                      : "none"
                                }}
                                className="btnRateThisCoach btn"
                              >
                                {this.props.comments.coachComments.length
                                  ? "Rate this coach"
                                  : "Be the first to rate this coach"}
                              </button>
                            ) : (
                              <button className="btnRateThisCoach btn" onClick={this.LoginToRateCoach}>
                                Please Log In to Rate
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-xl-8"
                        style={{
                          border: "1px solid #fff",
                          backgroundColor: "white",
                          padding: "20px 30px"
                        }}
                      >
                        <div className="row">
                          <div className="col-xl-12">
                            <span className="aboutStart">About </span>
                            <span className="about"> {fullName}</span>
                          </div>
                        </div>
                        <div className="row" style={{ padding: "10px 0px" }}>
                          <div className="col-xl-12">
                            <span className="aboutme">{aboutMe}</span>
                          </div>
                        </div>
                        {this.authorizationProvider.userCanAddTrainingVideos && trainingVideoLinks && trainingVideoLinks.length > 0 && (
                          <div className="row">
                            <div className="col-xl-12">
                              <div
                                className="row"
                                style={{ padding: "20px 0px" }}
                              >
                                <div className="col-xl-12">
                                  <span className="tainingvideos">
                                    Training Videos
                                  </span>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-xl-12">
                                  <Carousel showIndicators={false}>
                                    {trainingVideoLinks.map(
                                      (trainingvideoLink, i) => {
                                        return (
                                          //<YouTube videoId="2g811Eo7K8U" className="youtubePlayer" containerClassName="youtubePlayerContainer" opts={{height: '250',width: '100%'}}/>

                                          // <ReactPlayer url={trainingvideoLink}
                                          // width="100%"
                                          //   config={{
                                          //     youtube: {
                                          //       playerVars: { showsearch:0 ,rel:0,fs:0}
                                          //     },
                                          //     facebook: {
                                          //       appId: '12345'
                                          //     }
                                          //   }}>
                                          // </ReactPlayer>

                                          <iframe
                                            key={i}
                                            width="100%"
                                            height="250"
                                            src={
                                              trainingvideoLink.replace(
                                                "watch?v=",
                                                "embed/"
                                              ) + "?&rel=0&modestbranding=1"
                                            }
                                            frameBorder="0"
                                          ></iframe>
                                          //                                   <div>  <div>
                                          // 	<img src="https://res.klook.com/image/upload/fl_lossy.progressive,q_65/c_fill,w_480,h_384/cities/jrfyzvgzvhs1iylduuhj.jpg" alt="Hong Kong" />
                                          // 	<p className="legend">Hong Kong</p>
                                          // </div>
                                          // <div>
                                          // 	<img src="https://res.klook.com/image/upload/fl_lossy.progressive,q_65/c_fill,w_480,h_384/cities/c1cklkyp6ms02tougufx.webp" alt="Singapore"/>
                                          // 	<p className="legend"></p>
                                          // </div>
                                          // <div>
                                          // 	<img src="https://res.klook.com/image/upload/fl_lossy.progressive,q_65/c_fill,w_480,h_384/cities/e8fnw35p6zgusq218foj.webp" alt="Japan"/>
                                          // 	<p className="legend">Japan</p>
                                          // </div>
                                          // <div>
                                          // 	<img src="https://res.klook.com/image/upload/fl_lossy.progressive,q_65/c_fill,w_480,h_384/cities/liw377az16sxmp9a6ylg.webp" alt="Las Vegas"/>
                                          // 	<p className="legend">Las Vegas</p>
                                          // </div></div>
                                        );
                                      }
                                    )}
                                  </Carousel>
                                  :<></>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-xl-12">
                            <span className="clientFeedBack">
                              Client's feedback
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-12">
                            {this.props.comments.coachComments.map(
                              (comment, index) => {
                                if (
                                  index > 1 &&
                                  !this.props.oauth.isUserAuthenticated
                                ) {
                                  return <span />;
                                }
                                return (
                                  <div className="row" key={index}>
                                    <div className="col-xl-12">
                                      <div className="row">
                                        <div className="col-xl-3">
                                          <ProfilePicture
                                            photoLink={
                                              comment.commentee.photoLink
                                            }
                                          ></ProfilePicture>
                                        </div>
                                        <div className="col-xl-9">
                                          <div
                                            className="row"
                                            style={{ paddingTop: "20px" }}
                                          >
                                            <div className="col-xl-10">
                                              <span className="commenteeName">
                                                {comment.commentee.name}
                                              </span>
                                            </div>
                                            {((this.authorizationProvider
                                              .userCanDeleteComments &&
                                              this.state
                                                .isThisCoachesOwnProfile) ||
                                              this.currentUserAuthorizationProvider
                                                .userIsAdmin) && (
                                              <div className="col-xl-2">
                                                <Icon
                                                  onClick={() =>
                                                    this.deleteComment(
                                                      comment._id
                                                    )
                                                  }
                                                  style={{
                                                    fontSize: "16px",
                                                    color: "darkred",
                                                    cursor: "pointer"
                                                  }}
                                                  type="delete"
                                                ></Icon>
                                              </div>
                                            )}
                                          </div>
                                          <div className="row">
                                            <div className="col-xl-12">
                                              <span>
                                                <Rating
                                                  emptySymbol={
                                                    <FontAwesomeIcon
                                                      icon={farStar}
                                                      style={{
                                                        color: "#DF0000"
                                                      }}
                                                      inverse={true}
                                                    />
                                                  }
                                                  fullSymbol={
                                                    <FontAwesomeIcon
                                                      icon={fasStar}
                                                      style={{
                                                        color: "#DF0000"
                                                      }}
                                                      inverse={true}
                                                    />
                                                  }
                                                  fractions={2}
                                                  initialRating={comment.rating}
                                                  readonly
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="row"
                                        style={{ paddingTop: "10px" }}
                                      >
                                        <div className="col-xl-12">
                                          <span className="commenteeComment">
                                            {comment.comment}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-12">
                            {!this.props.oauth.isUserAuthenticated ? (
                              <span className="loginToRateLink" onClick={this.LoginToRateCoach} >
                                Please log in to view  comments
                              </span>
                            ) : (
                              <span />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4">
                    <div className="row profileRightSectionRow">
                      <div className="col-xl-12">
                        <div className="row" style={{ paddingTop: "30px" }}>
                          <div className="col-xl-12">
                            {this.currentUserAuthorizationProvider.userIsAdmin && (
                              <Link
                                to={{ pathname: `/coach/${_id}/edit` }}
                                className="btn btnClaimProfile"
                              >
                                <span>Edit Profile</span>
                              </Link>
                            )}
                            {!this.props.oauth.isUserAuthenticated &&
                              !this.props.search.coachProfile.isClaimed && (
                                <button
                                  className="btn btnClaimProfile"
                                  onClick={this.claimThisProfile}
                                >
                                  <span>Claim this profile</span>
                                  <FontAwesomeIcon
                                    icon={faBullhorn}
                                    style={{ color: "#fff", marginLeft: "5px" }}
                                    inverse={true}
                                  />
                                </button>
                              )}
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{
                            padding: "20px 0px",
                            borderBottom: "1px solid rgba(190, 206, 235, 0.3)"
                          }}
                        >
                          <div
                            className="col-xl-12"
                            style={{ textAlign: "center" }}
                          >
                            <div className="btn btnBadgePill">
                               <FacebookShareButton
                                  url={window.location.href}
                                  quote="Check Me Out Here"
                                  className="Demo__some-network__share-button"
                                >
                                  Share <FacebookIcon size={20} style={{borderRadius:"5px"}} />
                                </FacebookShareButton>
                              </div>
                              <div className="btn btnBadgePill">
                                <TwitterShareButton
                                    url={window.location.href}
                                    title="Check Me Out Here"
                                    className="Demo__some-network__share-button"
                                    style={{paddingLeft:"5px"}}
                                  >
                                    Share <TwitterIcon size={20} style={{borderRadius:"5px"}} />
                                </TwitterShareButton>
                              </div>
                              <div className="btn btnBadgePill">
                              
                              
                              <LinkedinShareButton
                                    url={window.location.href}
                                    title="Check Me Out Here"
                                    className="Demo__some-network__share-button"
                                    style={{paddingLeft:"5px"}}
                                  >
                                    Share <LinkedinIcon size={20} style={{borderRadius:"5px"}} />
                                </LinkedinShareButton>

                              </div>
                          
                        
                          </div>
                        </div>
                        {this.authorizationProvider.userCanAddWebsiteLink && website && website.length > 0 ? (
                          <div className="row" style={{ paddingTop: "10px" }}>
                            <div
                              className="col-xl-12"
                              style={{ textAlign: "center" }}
                            >
                              <a className="btn  website-button" target="_blank" href={website}>
                                <span>Check out my website</span>
                                <AimOutlined style={{fontSize:"30px",paddingLeft:"25px"}} />
                              </a>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {this.authorizationProvider.userCanAddSocialMediaLinks &&  fbProfilePage && fbProfilePage.length > 0 ? (
                          <div className="row" style={{ paddingTop: "10px",paddingBottom:"10px" }}>
                            <div
                              className="col-xl-12"
                              style={{ textAlign: "center" }}
                            >
                              <a
                                className="btn  facebook-button"  target="_blank"
                                href={fbProfilePage}
                              >
                                <span>Find me on social media</span>
                                <AimOutlined style={{fontSize:"30px",paddingLeft:"10px"}} />
                             
                              </a>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* {this.authorizationProvider.userCanAddSocialMediaLinks && linkedIn && linkedIn.length > 0 ? (
                          <div className="row" style={{ paddingTop: "10px" }}>
                            <div
                              className="col-xl-12"
                              style={{ textAlign: "center" }}
                            >
                              <a
                                className="btn  linkedin-button"  target="_blank"
                                href={linkedIn}
                              >
                                <span>Connect with me on </span>
                                <FontAwesomeIcon
                                  icon={faLinkedinIn}
                                  style={{ color: "#374EC3" }}
                                  inverse={true}
                                />
                              </a>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )} */}

                        {this.authorizationProvider.userCanAddStoryVideos && storyVideoLinks && storyVideoLinks.length > 0 ? (
                          storyVideoLinks.map((yourStoryVideoLink, i) => {
                            return (
                              <div
                                className="row"
                                style={{ paddingTop: "10px" }}
                              >
                                <div
                                  className="col-xl-12"
                                  style={{ textAlign: "center" }}
                                >
                                  <div
                                    className="row"
                                    style={{ padding: "10px 0px" }}
                                  >
                                    <div className="col-xl-12">
                                      <span className="yourStoryVideoTitle">
                                        {yourStoryVideoLink.Title}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className="row"
                                    style={{ padding: "0px 0px" }}
                                  >
                                    <div className="col-xl-12">
                                      <iframe
                                        width="100%"
                                        height="250"
                                        style={{borderRadius:"10px",boxShadow:"0 0 10px black"}}
                          
                                        src={
                                          yourStoryVideoLink.Link.replace(
                                            "watch?v=",
                                            "embed/"
                                          ) + "?&rel=0&modestbranding=1"
                                        }
                                        frameBorder="0"
                                      ></iframe>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-1"></div>
            </div>
          </section>
        </div>
      )
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoachProfile);
