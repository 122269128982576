import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getUserProfileInitiated,
  getUserCommentsInitiated,
  setNavbarCustomCss
} from "../actions/actions";
import {
  cfLinkToUpgrade,
  cfLinkToUpgradeLevel3,
  cfLinkToUpgradeLevel4,
  cfLinkToUpgradeLevel5,
  cfLinkToUpgradeLevel6,
  cfLinkToUpgradeLevel6Premium,
  cfLinkToUpgradeLevel6Alt,
  cfLinkToUpgradeLevel6Standard,

} from '../constants/cf_levelupgrade_links';

import Toast from "react-bootstrap/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";
import {
  faStar as fasStar,
  faPlay as fasPlay
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedinIn, faFacebook } from "@fortawesome/free-brands-svg-icons";
import Rating from "react-rating";
import C3Chart from "react-c3js";
// import ContactMeDirectly from './ContactMeDirectly';
// import RateThisCoach from './RateThisCoach';
import Badge from "../components/Badge";
import ProfilePicture from "../components/ProfilePicture";
import "../assets/css/Profile.css";
import "c3/c3.css";
import { message, Spin, Upload, Icon } from "antd";
import { Carousel } from "react-responsive-carousel";
import Badges from "../constants/badges";
import levelTypes from "../constants/level";
import { ColumnWidthOutlined,
  DeleteOutlined ,
  HighlightOutlined ,
  RiseOutlined ,
  CheckOutlined,
  CheckCircleOutlined ,
  CloseCircleOutlined,
  StarOutlined,
  AimOutlined,

 } from '@ant-design/icons'
const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => {
  return {
    getUserProfile: () => dispatch(getUserProfileInitiated()),
    getComments: id => dispatch(getUserCommentsInitiated(id)),
    setNavbarCustomCss: data => dispatch(setNavbarCustomCss(data))
  }
};

class UserProfile extends React.Component {
  constructor() {
    super();
    this.state = {};
   
  }

  componentDidMount() {
    this.props.setNavbarCustomCss({
      navbarMode:'lightmode'
    });

    if(this.props.oauth.isUserAuthenticated &&  !this.props.user.userProfile.level){
      this.setState({messageLevel:"error",
            message:"An error occured while getting your profile. Please contact your system administrator.",
            isMounted:false, 
            errorOnGettingProfile:true
            },
            () => this.Notify())
            setTimeout(
              function() {
                this.props.history.push('/')
              }
              .bind(this),
              5000
          );
    }
  
    //alert(this.props.user.getUserProfileOperationCompleted);
    this.props.getUserProfile();
    this.props.getComments(this.props.user.userProfile._id);
    //this.props.getUserProfile();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps != this.props) {
      if (this.props.user.getUserProfileOperationCompleted){
        if(this.props.user.getUserProfileOperationStatus=="Completed_Success")
        {
          this.setState({isMounted:true, errorOnGettingProfile:false});
        
        }
        else if(this.props.user.getUserProfileOperationStatus=="Completed_Failed")
        {
        
          // this.setState({messageLevel:"error",
          //   message:"An error occured while getting your profile.Please contact system administrator.",
          //   isMounted:false, 
          //   errorOnGettingProfile:true
          //   },
          //   () => this.Notify())
          //   setTimeout(
          //     function() {
          //       this.props.history.push('/')
          //     }
          //     .bind(this),
          //     5000
          // );
          
        }
      }
        
    }
  }
  getYoutubeVideoId = url => {
    let id = "";
    url = url
      .replace(/(>|<)/gi, "")
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      id = url[2].split(/[^0-9a-z_\-]/i);
      id = id[0];
    } else {
      id = url;
    }
    return id;
  };
  upgradePlan=()=>{
    var currentUserLevel=this.props.user.userProfile.level;
    var currentUserEmail=this.props.user.userProfile.email;
    var currentUserAddOnLevel=this.props.user.userProfile.addOnLevel;

    if(this.props.user.userProfile.affiliateId && currentUserAddOnLevel){
      window.location.href = cfLinkToUpgrade+"?email="+currentUserEmail+"&prev-level="+currentUserLevel+"&affiliateId="+this.props.user.userProfile.affiliateId+"&add-on-prev-level="+currentUserAddOnLevel+"";
    }
    else if (this.props.user.userProfile.affiliateId){
      window.location.href = cfLinkToUpgrade+"?email="+currentUserEmail+"&prev-level="+currentUserLevel+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
    }
    else if(currentUserAddOnLevel){
      window.location.href =cfLinkToUpgrade+"?email="+currentUserEmail+"&prev-level="+currentUserLevel+"&add-on-prev-level="+currentUserAddOnLevel+"";
    }
    else{
      window.location.href = cfLinkToUpgrade+"?email="+currentUserEmail+"&prev-level="+currentUserLevel+"";
    }
  }
  spinner = () => {
    return (
      <div className="row">
        <div
          className="col-xl-12"
          style={{
            textAlign: "center",
            position: "absolute",
            top: "20px",
            zIndex: "1000"
          }}
        >
          <Spin size="large"></Spin>
        </div>
      </div>
    );
  };
  Notify = () => {
    if (this.state.messageLevel == "success") {
      message.success(this.state.message);
    } else if (this.state.messageLevel == "error") {
      message.error(this.state.message);
    }
  };
  render() {
    const {
      email,
      fullName,
      photoLink,
      rating,
      website,
      fbProfilePage,
      //linkedIn,
      aboutMe,
      categoryIds,
      trainingVideoLinks,
      storyVideoLinks,
      level,
      addOnLevel,
      featuredCategory,
    
      score = 0
    } = this.props.user.userProfile;

    let allBadges=Badges;
    if (this.props.user.userProfile.badges && Array.isArray(this.props.user.userProfile.badges)) {
      allBadges=allBadges.map((obj1) => {
        this.props.user.userProfile.badges.map((obj2)=>{

          if(obj2.name === obj1.name){
            obj1.count=obj2.count;
            
          }
        });
        return obj1;
       
      }); 
    }
    return (
      
      <div className="col-xl-12">
        {(this.props.comments.getUserCommentsOperationStatus == "InProcess" ||
          this.props.user.getUserProfileOperationStatus == "InProcess") &&
          this.spinner()}
        <section
          id="main-section"
          style={{ minHeight: window.innerHeight - 150 }}
        >
          <div className="row" style={{ width: "100%" }}>
            <div className="col-xl-1"></div>
            <div className="col-xl-10">
              <div className="row" style={{ paddingBottom: "30px" }}>
                <div className="col-xl-12">
                  <div className="row" style={{ margin: "0px" }}>
                    {categoryIds.length > 0 ? (
                      categoryIds.map(category => {
                        return (
                          <Link to={{pathname: `/category/${category}`}} key={category} className="btn btnBadgePill">
                          {category}
                          </Link>
                    
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="row" style={{ margin: "0px" }}>
                <div
                  className="col-xl-8"
                  style={{
                    border: "1px solid #beceeb",
                    borderRadius: "4px",
                    backgroundColor: "white"
                  }}
                >
                  <div className="row">
                    <div
                      className="col-xl-4"
                      style={{
                        border: "1px solid #f3f3f9",
                        backgroundColor: "#f3f3f9"
                      }}
                    >
                      <div className="row" style={{ paddingTop: "10px" }}>
                        <div
                          className="col-xl-1"
                          style={{ textAlign: "center" }}
                        ></div>
                        <div
                          className="col-xl-10"
                          style={{ textAlign: "center" }}
                        >
                          {photoLink && photoLink !== "" ? (
                            <ProfilePicture
                              photoLink={photoLink}
                            ></ProfilePicture>
                          ) : (
                            <ProfilePicture photoLink={null}></ProfilePicture>
                          )}
                        </div>
                        <div
                          className="col-xl-1"
                          style={{ textAlign: "center" }}
                        ></div>
                      </div>
                      <div
                        className="row"
                        style={{
                          marginTop: "10px",
                          backgroundColor: "#dce2ef"
                        }}
                      >
                        <div
                          className="col-xl-12"
                          style={{ textAlign: "center" }}
                        >
                          <span className="username">{fullName}</span>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{
                          marginTop: "10px",
                          backgroundColor: "#dce2ef"
                        }}
                      >
                        <div
                          className="col-xl-12"
                          style={{ textAlign: "center" }}
                        >
                          <a href={"mailto:" + email} className="useremail">
                            {email}
                          </a>
                        </div>
                      
                      </div>
                      <div
                        className="row"
                        style={{
                          backgroundColor: "#e7ecf4",
                          padding: "10px 0px"
                        }}
                      >
                        <div
                          className="col-xl-12"
                          style={{ textAlign: "center" }}
                        >
                          Ratings
                          <span className="star-block">
                            <Rating
                              emptySymbol={
                                <FontAwesomeIcon
                                  icon={farStar}
                                  style={{ color: "#DF0000" }}
                                  inverse={true}
                                />
                              }
                              fullSymbol={
                                <FontAwesomeIcon
                                  icon={fasStar}
                                  style={{ color: "#DF0000" }}
                                  inverse={true}
                                />
                              }
                              fractions={2}
                              initialRating={rating}
                              readonly
                            />{" "}
                            {rating}
                          </span>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "30px" }}>
                        <div
                          className="col-xl-12"
                          style={{ textAlign: "center" }}
                        >
                          <h6>Badges Awarded</h6>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "10px" }}>
                        <div
                          className="col-xl-12"
                          style={{ textAlign: "center" }}
                        >
                          {allBadges.length ? (
                            allBadges.map((badge,i) => {
                              return (
                                <Badge
                                  name={badge.name}
                                  imgName={badge.imgName}
                                  count={badge.count}
                                  width="50px"
                                  key={i}
                                />
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-xl-12"
                          style={{ textAlign: "center" }}
                        >
                          <C3Chart
                            data={{
                              columns: [["data", score]],
                              type: "gauge"
                            }}
                            color={{
                              pattern: ["#0B3790"]
                            }}
                            gauge={{
                              min: 0, // 0 is default, //can handle negative min e.g. vacuum / voltage / current flow / rate of change
                              max: 100, // 100 is default
                              units: "",
                              width: 35 // for adjusting arc thickness
                            }}
                            legend={{
                              show: false
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-xl-8"
                      style={{
                        border: "1px solid #fff",
                        backgroundColor: "white",
                        padding: "20px 30px"
                      }}
                    >
                      <div className="row">
                        <div className="col-xl-12">
                        <span className="aboutStart">About</span>
                        <span className="about"> {fullName}</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="aboutme">
                          <div className="col-xl-12">
                            <span>{aboutMe}</span>
                          </div>
                        </div>
                      </div>
                      {trainingVideoLinks && trainingVideoLinks.length > 0 && (
                        <div className="row">
                          <div className="col-xl-12">
                            <div
                              className="row"
                              style={{ padding: "20px 0px" }}
                            >
                              <div className="col-xl-12">
                                <span className="tainingvideos">
                                  Training Videos
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xl-12">
                                <Carousel showIndicators={false}>
                                  {trainingVideoLinks.map(
                                    (trainingvideoLink, i) => {
                                      return (
                                        <iframe
                                         key={i}
                                          width="100%"
                                          height="250"
                                          src={
                                            trainingvideoLink.replace(
                                              "watch?v=",
                                              "embed/"
                                            ) + "?&rel=0&modestbranding=1"
                                          }
                                          frameBorder="0"
                                        ></iframe>
                                      );
                                    }
                                  )}
                                </Carousel>
                                :<></>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-xl-12">
                          {this.props.comments.getUserCommentsSuccess ? (
                            this.props.comments.userComments.map(
                              (comment, index) => {
                                return (
                                  <div className="row">
                                    <div className="col-xl-12">
                                      <div className="row">
                                        <div className="col-xl-3">
                                          <ProfilePicture
                                            photoLink={
                                              comment.commentee.photoLink
                                            }
                                          ></ProfilePicture>
                                        </div>
                                        <div className="col-xl-9">
                                          <div
                                            className="row"
                                            style={{ paddingTop: "20px" }}
                                          >
                                            <div className="col-xl-12">
                                              <span className="commenteeName">
                                                {comment.commentee.name}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-xl-12">
                                              <span>
                                                <Rating
                                                  emptySymbol={
                                                    <FontAwesomeIcon
                                                      icon={farStar}
                                                      style={{
                                                        color: "#DF0000"
                                                      }}
                                                      inverse={true}
                                                    />
                                                  }
                                                  fullSymbol={
                                                    <FontAwesomeIcon
                                                      icon={fasStar}
                                                      style={{
                                                        color: "#DF0000"
                                                      }}
                                                      inverse={true}
                                                    />
                                                  }
                                                  fractions={2}
                                                  initialRating={comment.rating}
                                                  readonly
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="row"
                                        style={{ paddingTop: "10px" }}
                                      >
                                        <div className="col-xl-12">
                                          <span className="commenteeComment">
                                            {comment.comment}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="row profileRightSectionRow">
                    <div className="col-xl-12">
                      <div className="row" style={{ paddingTop: "30px" }}>
                       {this.state.isMounted&& 

                       <div className="col-xl-12">
                        <div
                          className="col-xl-12"
                          style={{ textAlign: "center",paddingBottom:"10px" }}
                        >

                          <span className="subscriptionLevel">Primary Plan: {levelTypes.find(x=>x.value==level).name}</span>
                        </div>
                        {addOnLevel ?
                         <div
                         className="col-xl-12">
                         <div
                          className="col-xl-12"
                          style={{ textAlign: "center",paddingBottom:"10px" }}
                        >

                          <span className="subscriptionLevel">Advertising Plan: {levelTypes.find(x=>x.value==addOnLevel).name }</span>
                        </div>
                           <div
                           className="col-xl-12"
                           style={{ textAlign: "center",paddingBottom:"10px" }}
                         >
 
                           <span className="subscriptionLevel">Category: {featuredCategory}</span>
                         </div> </div>
                        :
                         <div
                         className="col-xl-12"
                         style={{ textAlign: "center",paddingBottom:"10px" }}
                       >
                         <span className="subscriptionLevel">Advertising Plan: Not Subscribed</span>
                         </div>
                        }
                        </div>
                       }
                       
                        {this.state.isMounted&& 
                        <div
                          className="col-xl-12"
                          style={{ textAlign: "center" }}
                        >
                          <button className="btnUpgrade btn"  onClick={this.upgradePlan}>
                            Upgrade
                          </button>
                        </div>
                        }
                      </div>
                      <div className="row" style={{ paddingTop: "10px" }}>
                      {this.state.isMounted&& 
                        <div className="col-xl-12">
                          <Link
                            to="/profileForm"
                            className="btn btnClaimProfile"
                          >
                            <span>Edit Profile</span>
                          </Link>
                        </div>
                      }
                      </div>

                      {website && website.length > 0 ? (
                        <div className="row" style={{ paddingTop: "10px" }}>
                          <div
                            className="col-xl-12"
                            style={{ textAlign: "center" }}
                          >
                            <a className="btn  website-button"  target="_blank" href={website}>
                              <span>Check out my website </span>
                              <AimOutlined style={{fontSize:"30px", paddingLeft:"20px"}} />
                            </a>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {fbProfilePage && fbProfilePage.length > 0 ? (
                        <div className="row" style={{ paddingTop: "10px" , paddingBottom:"10px" }}>
                          <div
                            className="col-xl-12"
                            style={{ textAlign: "center" }}
                          >
                            <a
                              className="btn  facebook-button"  target="_blank"
                              href={fbProfilePage}
                            >
                              <span>Find me on social media</span>
                              <AimOutlined style={{fontSize:"30px", paddingLeft:"10px"}} />
                            </a>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* {linkedIn && linkedIn.length > 0 ? (
                        <div className="row" style={{ paddingTop: "10px" }}>
                          <div
                            className="col-xl-12"
                            style={{ textAlign: "center" }}
                          >
                            <a className="btn  linkedin-button"  target="_blank" href={linkedIn}>
                              <span>Connect with me on </span>
                              <FontAwesomeIcon
                                icon={faLinkedinIn}
                                style={{ color: "#374EC3" }}
                                inverse={true}
                              />
                            </a>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )} */}

                      {storyVideoLinks && storyVideoLinks.length > 0 ? (
                        storyVideoLinks.map((yourStoryVideoLink, i) => {
                          return (
                            <div className="row" style={{ paddingTop: "10px" }}>
                              <div
                                className="col-xl-12"
                                style={{ textAlign: "center" }}
                              >
                                <div
                                  className="row"
                                  style={{ padding: "10px 0px" }}
                                >
                                  <div className="col-xl-12">
                                    <span className="yourStoryVideoTitle">
                                      {yourStoryVideoLink.Title}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="row"
                                  style={{ padding: "0px 0px" }}
                                >
                                  <div className="col-xl-12">
                                    <iframe
                                      width="100%"
                                      height="250"
                                      style={{borderRadius:"10px",boxShadow:"0 0 10px black"}}
                                      src={
                                        yourStoryVideoLink.Link.replace(
                                          "watch?v=",
                                          "embed/"
                                        ) + "?&rel=0&modestbranding=1"
                                      }
                                      frameBorder="0"
                                    ></iframe>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-1"></div>
          </div>
        </section>
      </div>
      
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
