import { takeEvery, put, call, all, fork } from 'redux-saga/effects';
import { SUBSCRIPTION_REQUEST_INITIATED } from '../constants/constants';
import api from '../api';
import { subscriptionRequestSuccess, subscriptionRequestFailure,subscriptionRequestReset } from '../actions/actions';

function* handleSubscriptionRequestInitiated(action) {
  try {
    const res = yield call(api.subscriptionRequest, action.payload);
    yield put(subscriptionRequestSuccess(res.data));
    yield put(subscriptionRequestReset(null));
  } catch (error) {

    yield put(subscriptionRequestFailure(error.response));
    yield put(subscriptionRequestReset(null));
  }
}



function* watchSubscriptionRequestInitiated() {
  yield takeEvery(SUBSCRIPTION_REQUEST_INITIATED, handleSubscriptionRequestInitiated)
}



export default function* subscriptionSagas() {
  yield all([fork(watchSubscriptionRequestInitiated)])
}