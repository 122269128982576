import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import queryString from 'query-string'

const mapStateToProps = state => {
  return state
}

class VerifyEmail extends React.Component {
  constructor() {
    super()
    this.state = {
      confirmed: false
    }
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search)
    console.log(values.userName) // "top"
    console.log(values.code)
    Auth.confirmSignUp(values.userName, values.code).then((data) => {
      console.log(data);
      this.setState({confirmed: true})
    }).catch((error) => {
      console.log(error);
      this.setState({confirmed: true})
    })
  }
  render() {
    return (
      <div className="container">
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "90vh" }}>
          {this.state.confirmed ? <h6>Your email has been verified. You can now <Link className="btn btn-outline-dark my-2 mr-4 my-sm-0" to="/signin">Sign In</Link></h6> : <h6>Verifying your email...</h6>}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(VerifyEmail);