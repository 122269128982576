export const NEW_USER_SIGNED_UP = 'NEW_USER_SIGNED_UP';
export const USER_AUTHENTICATION_INNITIATED = 'USER_AUTHENTICATION_INNITIATED';
export const USER_INFO_RECEIVED = 'USER_INFO_RECEIVED';

export const USER_TYPE_SELECTED = 'USER_TYPE_SELECTED';

export const USER_SIGNUP_INITIATED = 'USER_SIGNUP_INITIATED';
export const USER_SIGNED_UP_SUCCESS = 'USER_SIGNED_UP_SUCCESS';
export const USER_SIGNED_UP_FAILURE = 'USER_SIGNED_UP_FAILURE';

export const VERIFICATION_CODE_SUBMITED = 'VERIFICATION_CODE_SUBMITED';
export const VERIFICATION_CODE_SUCCESS = 'VERIFICATION_CODE_SUCCESS';
export const VERIFICATION_CODE_FAILURE = 'VERIFICATION_CODE_FAILURE';

export const USER_SIGNIN_INITIATED = 'USER_SIGNIN_INITIATED';
export const USER_SIGNED_IN_SUCCESS = 'USER_SIGNED_IN_SUCCESS';
export const USER_SIGNED_IN_FAILURE = 'USER_SIGNED_IN_FAILURE';

export const USER_SIGNOUT = 'USER_SIGNOUT';

export const GET_USER_PROFILE_INITIATED = 'GET_USER_PROFILE_INITIATED';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';
export const GET_USER_PROFILE_RESET = 'GET_USER_PROFILE_RESET';

export const UPDATE_USER_PROFILE_INITIATED = 'UPDATE_USER_PROFILE_INITIATED';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';
export const UPDATE_USER_PROFILE_RESET = 'UPDATE_USER_PROFILE_RESET';

export const UPDATE_USER_TYPE_INITIATED = 'UPDATE_USER_TYPE_INITIATED';
export const UPDATE_USER_TYPE_SUCCESS = 'UPDATE_USER_TYPE_SUCCESS';
export const UPDATE_USER_TYPE_FAILURE = 'UPDATE_USER_TYPE_FAILURE';
export const UPDATE_USER_TYPE_RESET = 'UPDATE_USER_TYPE_RESET';

export const GET_SEARCH_RESULTS_INITIATED = 'GET_SEARCH_RESULTS_INITIATED';
export const GET_SEARCH_RESULTS_SUCCESS = 'GET_SEARCH_RESULTS_SUCCESS';
export const GET_SEARCH_RESULTS_FAILURE = 'GET_SEARCH_RESULTS_FAILURE';
export const GET_SEARCH_RESULTS_RESET = 'GET_SEARCH_RESULTS_RESET';


export const GET_SEARCH_RESULTS_BY_CATEGORY_INITIATED = 'GET_SEARCH_RESULTS_BY_CATEGORY_INITIATED';
export const GET_SEARCH_RESULTS_BY_CATEGORY_SUCCESS = 'GET_SEARCH_RESULTS_BY_CATEGORY_SUCCESS';
export const GET_SEARCH_RESULTS_BY_CATEGORY_FAILURE = 'GET_SEARCH_RESULTS_BY_CATEGORY_FAILURE';
export const GET_SEARCH_RESULTS_BY_CATEGORY_RESET = 'GET_SEARCH_RESULTS_BY_CATEGORY_RESET';

export const GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_INITIATED = 'GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_INITIATED';
export const GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_SUCCESS = 'GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_SUCCESS';
export const GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_FAILURE = 'GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_FAILURE';
export const GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_RESET = 'GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_RESET';


export const GET_PROFILE_BY_ID_INITIATED = 'GET_PROFILE_BY_ID_INITIATED';
export const GET_PROFILE_BY_ID_SUCCESS = 'GET_PROFILE_BY_ID_SUCCESS';
export const GET_PROFILE_BY_ID_FAILURE = 'GET_PROFILE_BY_ID_FAILURE';
export const GET_PROFILE_BY_ID_RESET = 'GET_PROFILE_BY_ID_RESET';


export const GET_COMMENTS_BY_ID_INITIATED = 'GET_COMMENTS_BY_ID_INITIATED';
export const GET_COMMENTS_BY_ID_SUCCESS = 'GET_COMMENTS_BY_ID_SUCCESS';
export const GET_COMMENTS_BY_ID_FAILURE = 'GET_COMMENTS_BY_ID_FAILURE';
export const GET_COMMENTS_BY_ID_RESET = 'GET_COMMENTS_BY_ID_RESET';


export const GET_FEATURED_COACHES_INITIATED = 'GET_FEATURED_COACHES_INITIATED';
export const GET_FEATURED_COACHES_SUCCESS = 'GET_FEATURED_COACHES_SUCCESS';
export const GET_FEATURED_COACHES_FAILURE = 'GET_FEATURED_COACHES_FAILURE';
export const GET_FEATURED_COACHES_RESET = 'GET_FEATURED_COACHES_RESET';


export const POST_COMMENT_INITIATED = 'POST_COMMENT_INITIATED';
export const POST_COMMENT_SUCCESS = 'POST_COMMENT_SUCCESS';
export const POST_COMMENT_FAILURE = 'POST_COMMENT_FAILURE';
export const POST_COMMENT_RESET = 'POST_COMMENT_RESET';

export const DELETE_COMMENT_INITIATED = 'DELETE_COMMENT_INITIATED';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE';
export const DELETE_COMMENT_RESET = 'DELETE_COMMENT_RESET';

export const GET_USER_COMMENTS_INITIATED = 'GET_USER_COMMENTS_INITIATED';
export const GET_USER_COMMENTS_SUCCESS = 'GET_USER_COMMENTS_SUCCESS';
export const GET_USER_COMMENTS_FAILURE = 'GET_USER_COMMENTS_FAILURE';
export const GET_USER_COMMENTS_RESET = 'GET_USER_COMMENTS_RESET';


export const CONTACT_US_INITIATED = 'CONTACT_US_INITIATED';
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
export const CONTACT_US_FAILURE = 'CONTACT_US_FAILURE';
export const CONTACT_US_RESET = 'CONTACT_US_RESET';

export const CONTACT_ME_DIRECTLY_INITIATED = 'CONTACT_ME_DIRECTLY_INITIATED';
export const CONTACT_ME_DIRECTLY_SUCCESS = 'CONTACT_ME_DIRECTLY_SUCCESS';
export const CONTACT_ME_DIRECTLY_FAILURE = 'CONTACT_ME_DIRECTLY_FAILURE';
export const CONTACT_ME_DIRECTLY_RESET = 'CONTACT_ME_DIRECTLY_RESET';

export const GET_BANNER_TYPE_INITIATED = 'GET_BANNER_TYPE_INITIATED';
export const GET_BANNER_TYPE_SUCCESS = 'GET_BANNER_TYPE_SUCCESS';
export const GET_BANNER_TYPE_FAILURE = 'GET_BANNER_TYPE_FAILURE';
export const GET_BANNER_TYPE_RESET = 'GET_BANNER_TYPE_RESET';

export const SET_BANNER_TYPE_INITIATED = 'SET_BANNER_TYPE_INITIATED';
export const SET_BANNER_TYPE_SUCCESS = 'SET_BANNER_TYPE_SUCCESS';
export const SET_BANNER_TYPE_FAILURE = 'SET_BANNER_TYPE_FAILURE';
export const SET_BANNER_TYPE_RESET = 'SET_BANNER_TYPE_RESET';


export const UPDATE_COACH_PROFILE_BY_ADMIN_INITIATED = 'UPDATE_COACH_PROFILE_BY_ADMIN_INITIATED';
export const UPDATE_COACH_PROFILE_BY_ADMIN_SUCCESS = 'UPDATE_COACH_PROFILE_BY_ADMIN_SUCCESS';
export const UPDATE_COACH_PROFILE_BY_ADMIN_FAILURE = 'UPDATE_COACH_PROFILE_BY_ADMIN_FAILURE';
export const UPDATE_COACH_PROFILE_BY_ADMIN_RESET = 'UPDATE_COACH_PROFILE_BY_ADMIN_RESET';

export const GET_CATEGORIES_BY_PLAN_TYPE_INITIATED = 'GET_CATEGORIES_BY_PLAN_TYPE_INITIATED';
export const GET_CATEGORIES_BY_PLAN_TYPE_SUCCESS = 'GET_CATEGORIES_BY_PLAN_TYPE_SUCCESS';
export const GET_CATEGORIES_BY_PLAN_TYPE_FAILURE = 'GET_CATEGORIES_BY_PLAN_TYPE_FAILURE';
export const GET_CATEGORIES_BY_PLAN_TYPE_RESET = 'GET_CATEGORIES_BY_PLAN_TYPE_RESET';

export const SUBSCRIPTION_REQUEST_INITIATED = 'SUBSCRIPTION_REQUEST_INITIATED';
export const SUBSCRIPTION_REQUEST_SUCCESS = 'SUBSCRIPTION_REQUEST_SUCCESS';
export const SUBSCRIPTION_REQUEST_FAILURE = 'SUBSCRIPTION_REQUEST_FAILURE';
export const SUBSCRIPTION_REQUEST_RESET = 'SUBSCRIPTION_REQUEST_RESET';

export const LINK_PROFILE_WITH_USER_INITIATED = 'LINK_PROFILE_WITH_USER_INITIATED';
export const LINK_PROFILE_WITH_USER_SUCCESS = 'LINK_PROFILE_WITH_USER_SUCCESS';
export const LINK_PROFILE_WITH_USER_FAILURE = 'LINK_PROFILE_WITH_USER_FAILURE';
export const LINK_PROFILE_WITH_USER_RESET = 'LINK_PROFILE_WITH_USER_RESET';




export const SET_NAVBAR_CUSTOM_CSS = 'SET_NAVBAR_CUSTOM_CSS';