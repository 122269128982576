import React from "react";
import { connect } from "react-redux";
import { updateUserProfileInitiated, getUserProfileInitiated, setNavbarCustomCss } from '../actions/actions'
import ProfilePicture from '../components/ProfilePicture';
import { message, Spin, Upload, Icon,Tooltip,List,Popover,Switch,Select } from "antd";
import AdvertisementCard from "../components/AdvertisementCard";
import Linkify from 'linkify-it';

import 'antd/dist/antd.css';
import '../assets/css/ProfileForm.css';
import AuthorizationProvider from '../AuthorizationProvider/authorizationProvider';
import api from '../api';
import { Auth } from 'aws-amplify';
import {
  messageToAddWebsiteLink,
  messageToAddFacebookLink,
  messageToAddLinkedInLink,
  messageToAddFacebookBusinessLink,
  messageToAddStoryVideoLink,
  messageToAddTrainingVideoLink,
  messageToReceiveEmails,
  cfLinkToUpgrade,
  cfLinkToUpgradeLevel3,
  cfLinkToUpgradeLevel4,
  cfLinkToUpgradeLevel5,
 
  
} from '../constants/cf_levelupgrade_links';
import { ColumnWidthOutlined,
  DeleteOutlined ,
  HighlightOutlined ,
  RiseOutlined ,
  CheckOutlined,
  CheckCircleOutlined ,
  CloseCircleOutlined,
  StarOutlined,

 } from '@ant-design/icons'



var linkify = Linkify();

const { Option } = Select;

const mapStateToProps = state => {
  return state;
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateUserProfile: (state) => dispatch(updateUserProfileInitiated(state)),
    getUserProfile: () => dispatch(getUserProfileInitiated()),
    setNavbarCustomCss: (data) => dispatch(setNavbarCustomCss(data))
  }
}
class ProfileForm extends React.Component {
  constructor() {
    super();
    this.state = {
      isMounted:false,
      _id: '',
      zip: '',
      lastName: '',
      website: '',
      city: '',
      isOneOnOne:false,
      fbProfilePage: "",
      fullName: '',
      //linkedIn: '',
      aboutMe: '',
      categoryNames: [],
      firstName: '',
      isFreeTraining:false,
      isGroupCoaching: false,
      phone: "",
      location:'',
      videoLink: "",
      state: '',
      photoLink: "",
      //fbBusinessPage: "",
      email: '',
      address: '',
      photo: '',
      trainingVideoLink:"",
      trainingVideoLinks:[],
      yourStoryLink:"",
      yourStoryTitle:"",
      storyVideoLinks:[],
      categoryOptionsArray:[],
      cfLinkToUpgradeLevel3:cfLinkToUpgradeLevel3,
      cfLinkToUpgradeLevel4:cfLinkToUpgradeLevel4,
      cfLinkToUpgradeLevel5:cfLinkToUpgradeLevel5,
      cfLinkToUpgrade:cfLinkToUpgrade,
      oldPassword:"",
      newPassword:"",
      confirmNewPassword:"",
    } 
  }
  componentDidMount=async()=> {

    try{
    this.authorizationProvider=new AuthorizationProvider(this.props.user.userProfile.level);
    this.props.setNavbarCustomCss({
      navbarMode:'lightmode'
    })
    let userProfile = this.props.user.userProfile;

    let resallCategories=await api.getAllCategories_Plain();
    let allCategories=resallCategories.data;
    this.setState(userProfile);

    var redirectToLevel3Upgrade=cfLinkToUpgradeLevel3;

    if(this.props.user.userProfile.affiliateId){
      redirectToLevel3Upgrade=redirectToLevel3Upgrade+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
    }
    else{
      redirectToLevel3Upgrade=redirectToLevel3Upgrade+"?email="+this.props.user.userProfile.email+"";
    }

    var redirectToLevel4Upgrade=cfLinkToUpgradeLevel4;

    if(this.props.user.userProfile.affiliateId){
      redirectToLevel4Upgrade=redirectToLevel4Upgrade+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
    }
    else{
      redirectToLevel4Upgrade=redirectToLevel4Upgrade+"?email="+this.props.user.userProfile.email+"";
    }

    var redirectToLevel5Upgrade=cfLinkToUpgradeLevel5;

    if(this.props.user.userProfile.affiliateId){
      redirectToLevel5Upgrade=redirectToLevel5Upgrade+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
    }
    else{
      redirectToLevel5Upgrade=redirectToLevel5Upgrade+"?email="+this.props.user.userProfile.email+"";
    }


    var redirectToLevel6Upgrade=cfLinkToUpgrade;
    var currentUserLevel=this.props.user.userProfile.level;
    var currentUserEmail=this.props.user.userProfile.email;
    var currentUserAddOnLevel=this.props.user.userProfile.addOnLevel;

    if(this.props.user.userProfile.affiliateId && currentUserAddOnLevel){
      redirectToLevel6Upgrade = cfLinkToUpgrade+"?email="+currentUserEmail+"&prev-level="+currentUserLevel+"&affiliateId="+this.props.user.userProfile.affiliateId+"&add-on-prev-level="+currentUserAddOnLevel+"";
    }
    else if (this.props.user.userProfile.affiliateId){
      redirectToLevel6Upgrade = cfLinkToUpgrade+"?email="+currentUserEmail+"&prev-level="+currentUserLevel+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
    }
    else if(currentUserAddOnLevel){
      redirectToLevel6Upgrade = cfLinkToUpgrade+"?email="+currentUserEmail+"&prev-level="+currentUserLevel+"&add-on-prev-level="+currentUserAddOnLevel+"";
    }
    else{
      redirectToLevel6Upgrade = cfLinkToUpgrade+"?email="+currentUserEmail+"&prev-level="+currentUserLevel+"";
    }
    this.setState({isMounted:true,
      categoryOptionsArray:allCategories,
      cfLinkToUpgradeLevel3:redirectToLevel3Upgrade,
      cfLinkToUpgradeLevel4:redirectToLevel4Upgrade,
      cfLinkToUpgradeLevel5:redirectToLevel5Upgrade,
      cfLinkToUpgradeLevel6:redirectToLevel6Upgrade,
    });
    }
    catch(e){
      console.log(e)
    }
  }
  componentDidUpdate(prevProps,prevState){
    
    if(prevProps!=this.props){
      if (this.props.user.updateUserProfileOperationCompleted){
       
          if(this.props.user.updateUserProfileOperationStatus=="Completed_Success"){
            this.setState({messageLevel:"success",
            message:"Profile updated successfully."
            },
            () => this.Notify());
            //infinte loop happens if getUserProfile is called here .. need to investigate that 
           // this.props.getUserProfile();
          }
          else if(this.props.user.updateUserProfileOperationStatus=="Completed_Failed"){
            this.setState({messageLevel:"error",
            message:"Failed to update profile."
            },
            () => this.Notify());
          }
        }
    }
  }
  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }
  beforeUpload = async file => {
    var self = this;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return false;
    }

    this.getBase64(file, base64String => {
      this.setState({ photo:base64String });
    });
    return false;
  };
  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  handleCategorySelect = (categories) => {
      if (categories.length <= 3) {
      this.setState({categoryNames: categories})
    } else {
      this.setState({messageLevel:"error",
      message:"You can only select up to 3 features."
      },
      () => this.Notify());
    }
  }
  handleOnSubmit = (event) => {
    event.preventDefault();
    let links = linkify.match(this.state.aboutMe);
    if (links && links.length) {

      this.setState({messageLevel:"success",
      message:"You cannot have links in the About me section."
      },
      () => this.Notify());
     
      return
    }
    let website = this.state.website;
    if (this.state.website) {
      let websiteLink = linkify.match(this.state.website);
      if (websiteLink && websiteLink[0]) {
        website = websiteLink[0].url
      } else {
        this.setState({messageLevel:"error",
        message:"Enter a valid website link."
        },
        () => this.Notify());
       
        return
      }
    }
    // let fbBusinessPage = this.state.fbBusinessPage;
    // if (this.state.fbBusinessPage) {
    //   let fbBusinessPageLink = linkify.match(this.state.fbBusinessPage);
    //   if (fbBusinessPageLink && fbBusinessPageLink[0]) {
    //     fbBusinessPage = fbBusinessPageLink[0].url
    //   } else {
    //     this.setState({messageLevel:"error",
    //     message:"Enter a valid facebook business profile link."
    //     },
    //     () => this.Notify());
       
    //     return
    //   }
    // }
    let fbProfilePage = this.state.fbProfilePage;
    if (this.state.fbProfilePage) {
      let fbProfilePageLink = linkify.match(this.state.fbProfilePage);
      if (fbProfilePageLink && fbProfilePageLink[0]) {
        fbProfilePage = fbProfilePageLink[0].url
      } else {
        this.setState({messageLevel:"error",
        message:"Enter a valid social media link."
        },
        () => this.Notify());
       
        return
      }
    }
    // let linkedIn = this.state.linkedIn;
    // if (this.state.linkedIn) {
    //   let linkedInLink = linkify.match(this.state.linkedIn);
    //   if (linkedInLink && linkedInLink[0]) {
    //     linkedIn = linkedInLink[0].url
    //   } else {
    //     this.setState({messageLevel:"error",
    //     message:"Enter a valid linkedin profile link."
    //     },
    //     () => this.Notify());
       
    //     return
    //   }
    // }
    let firstName=this.state.firstName? this.state.firstName:"";
    let lastName= this.state.lastName?this.state.lastName:"";
    let fullName = firstName +" "+ lastName;

   

    var trainingVideoLinksRefactored=[];
    var storyVideoLinksRefactored=[];
  
      
      //  this.state.trainingVideoLinks.forEach(link=>{
         
       
      //   // if((link+"").indexOf("youtu.be/")>0){

      //   //   var baseAddress="https://www.youtube.com/watch?v=";
      //   //   var videoId=(link+"").split("youtu.be/")[1];
      //   //   var newLink=baseAddress+videoId+"";
          
      //   //   trainingVideoLinksRefactored.push(newLink);
      //   // }
      //   // else{

      //     var newLink=(link+"").split('&')[0]+"";
      //     trainingVideoLinksRefactored.push(newLink);
      //  // }
        
      //  });

      //  this.state.storyVideoLinks.forEach(link=>{
      //   // if((link+"").indexOf("youtu.be/")>0){
      //   //   var baseAddress="https://www.youtube.com/watch?v=";
      //   //   var videoId=(link+"").split("youtu.be/")[1];
      //   //   var newLink=baseAddress+videoId+"";
      //   //   storyVideoLinksRefactored.push(newLink);
      //   // }
      //   // else{
      //     var newLink=(link+"").split('&')[0]+"";
      //     storyVideoLinksRefactored.push(newLink);
      //   //}
        
      //  });

       if(this.state.trainingVideoLinks.length>0){

        trainingVideoLinksRefactored=this.state.trainingVideoLinks.map((obj)=>{
  
          if(obj.indexOf("youtu.be/")>0)
          return "https://www.youtube.com/watch?v="+obj.split("youtu.be/")[1];
          return obj.split('&')[0];
  
        })
      }
      if(this.state.storyVideoLinks.length>0){
  
         storyVideoLinksRefactored=this.state.storyVideoLinks.map((obj)=>{

          if(obj.Link.indexOf("youtu.be/")>0)
          return {Title:obj.Title,Link: "https://www.youtube.com/watch?v="+obj.Link.split("youtu.be/")[1]};
          return {Title:obj.Title,Link:obj.Link.split('&')[0]};
  
        })
      }

       console.log("storyVideoLinksRefactored");
       console.log(trainingVideoLinksRefactored);


   
    this.setState({ fullName,website,fbProfilePage,trainingVideoLinks:trainingVideoLinksRefactored,storyVideoLinks:storyVideoLinksRefactored }, () => {
      this.props.updateUserProfile(this.state);
    });
    // this.setState({fullName, website,fbProfilePage}, () => {
    //   this.props.updateUserProfile(this.state)
    // })
  }
  handleChangeImage = (event) => {
    console.log("Uploading");
    const self = this;
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onload = function(upload) {
        self.setState({
            photo: upload.target.result
        });
    };
    reader.readAsDataURL(file);
  }
  deleteTrainingVideoLink=(evt)=>{
    var filteredAry = this.state.trainingVideoLinks.filter(e => e !== evt.currentTarget.dataset.link)
    this.setState({trainingVideoLinks:filteredAry});
  }
  deleteYourStoryVideoLink=(evt)=>{
    var filteredAry = this.state.storyVideoLinks.filter(e => e.Title !== evt.currentTarget.dataset.title)
    this.setState({storyVideoLinks:filteredAry});
  }
  
  addTrainingVideoLink=()=>{
    if(this.state.trainingVideoLinks.length<5){
      if(this.state.trainingVideoLink.length>0){
        var filteredAry = this.state.trainingVideoLinks;
        if(filteredAry.indexOf(this.state.trainingVideoLink)<0){
        filteredAry.push(this.state.trainingVideoLink);
        this.setState({trainingVideoLinks:filteredAry,trainingVideoLink:""});
        }
        else{
          this.setState({messageLevel:"error",
          message:"You have already added this link."
          },
          () => this.Notify());
        }
      }
    }
    else{
      this.setState({messageLevel:"error",
      message:"You can add only up to 5 training videos."
      },
      () => this.Notify());
    }

  }
    
  addYourStoryVideoLink=()=>{
    
    if(this.state.storyVideoLinks.length<3){
      if(this.state.yourStoryLink.length>0 &&  this.state.yourStoryTitle.length>0){
        var filteredAry = this.state.storyVideoLinks;
        if(filteredAry.find(x=>x.Title==this.state.yourStoryTitle)){
          

          this.setState({messageLevel:"error",
          message:"You have already added this Title."
          },
          () => this.Notify());
      
        }
        else if(filteredAry.find(x=> x.Link==this.state.yourStoryLink)){
          this.setState({messageLevel:"error",
          message:"You have already added this Link."
          },
          () => this.Notify());
        }
        else{
        
          filteredAry.push({"Title":this.state.yourStoryTitle,"Link":this.state.yourStoryLink});
          this.setState({storyVideoLinks:filteredAry,yourStoryLink:"",yourStoryTitle:""});
        }
      }
    }
    else{
      this.setState({messageLevel:"error",
      message:"You can add only up to 3 story videos."
      },
      () => this.Notify());
    }
  }
  spinner=()=>{
		return(
		<div className="row">
				<div className="col-xl-12" style={{textAlign:"center",position:"absolute",top:"20px",zIndex:"1000"}}>

				<Spin size="large"></Spin>

			</div>
		</div>)
  }
  Notify = () => {
    if (this.state.messageLevel == "success") {
      message.success(this.state.message);
    } else if (this.state.messageLevel == "error") {
      message.error(this.state.message);
    }
  };

  getPopoverContent=(action)=>{

    let redirectLink="";
    if(action=="website"){
      // if(this.props.user.userProfile.affiliateId){
      //   redirectLink=cfLinkToUpgradeLevel3+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
      // }
      // else{
      //   redirectLink=cfLinkToUpgradeLevel3+"?email="+this.props.user.userProfile.email+"";
      // }
      return(
        <div style={{textAlign:"center"}}>
        <p>{messageToAddWebsiteLink}</p>
      
        <a href={this.state.cfLinkToUpgradeLevel3}>Click to Upgrade</a>
        
      </div>)
    }
    if(action=="facebook"){
      // if(this.props.user.userProfile.affiliateId){
      //   redirectLink=cfLinkToUpgradeLevel3+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
      // }
      // else{
      //   redirectLink=cfLinkToUpgradeLevel3+"?email="+this.props.user.userProfile.email+"";
      // }
      return(
        <div style={{textAlign:"center"}}>
        <p>{messageToAddFacebookLink}</p>
        
        <a href={this.state.cfLinkToUpgradeLevel3}>Click to Upgrade</a>
      </div>)
    }
    if(action=="linkedin"){
      // if(this.props.user.userProfile.affiliateId){
      //   redirectLink=cfLinkToUpgradeLevel3+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
      // }
      // else{
      //   redirectLink=cfLinkToUpgradeLevel3+"?email="+this.props.user.userProfile.email+"";
      // }
      return(
        <div style={{textAlign:"center"}}>
        <p>{messageToAddLinkedInLink}</p>
        
        <a  href={this.state.cfLinkToUpgradeLevel3}>Click to Upgrade</a>
      </div>)
    }
    if(action=="facebookbusiness"){
      // if(this.props.user.userProfile.affiliateId){
      //   redirectLink=cfLinkToUpgradeLevel3+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
      // }
      // else{
      //   redirectLink=cfLinkToUpgradeLevel3+"?email="+this.props.user.userProfile.email+"";
      // }
      return(
        <div style={{textAlign:"center"}}>
        <p>{messageToAddFacebookBusinessLink}</p>
        
        <a href={this.state.cfLinkToUpgradeLevel3}>Click to Upgrade</a>
      </div>)
    }
    if(action=="storyvideos"){
      // if(this.props.user.userProfile.affiliateId){
      //   redirectLink=cfLinkToUpgradeLevel3+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
      // }
      // else{
      //   redirectLink=cfLinkToUpgradeLevel3+"?email="+this.props.user.userProfile.email+"";
      // }
      return(
        <div style={{textAlign:"center"}}>
        <p>{messageToAddStoryVideoLink}</p>
        
        <a href={this.state.cfLinkToUpgradeLevel3}>Click to Upgrade</a>
      </div>)
    }
    else if(action=="trainingvideos"){
      // if(this.props.user.userProfile.affiliateId){
      //   redirectLink=cfLinkToUpgradeLevel4+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
      // }
      // else{
      //   redirectLink=cfLinkToUpgradeLevel4+"?email="+this.props.user.userProfile.email+"";
      // }
      return(
        <div style={{textAlign:"center"}}>
        <p>{messageToAddTrainingVideoLink}</p>
        
        <a href={this.state.cfLinkToUpgradeLevel4}>Click to Upgrade</a>
      </div>)
    }
  }

  setIsFreeTraining=(checked)=>{
    this.setState({isFreeTraining:checked});
  }
  setIsGroupCoaching=(checked)=>{
    this.setState({isGroupCoaching:checked});
  }
  setIsOneOnOne=(checked)=>{
    this.setState({isOneOnOne:checked});
  }
  ChangePassword=async(e)=>{

    e.preventDefault();
    if(this.state.newPassword.length>=8 && this.state.confirmNewPassword.length>=8){
        if(this.state.newPassword==this.state.confirmNewPassword){
            this.setState({disableButton:true,isLoading:true});
                let user=await Auth.currentAuthenticatedUser();
                await Auth.changePassword(user, this.state.oldPassword,this.state.newPassword).then(res=>{
                    this.setState({
                        disableButton:false,
                        isLoading:false,
                        message:"Password updated successfully.",
                        messageLevel: "success"
                    }, () => this.Notify());
                }).catch(err=>{
                    this.setState({
                        disableButton:false,
                        isLoading:false,
                        message:err.message,
                        messageLevel: "error"
                    }, () => this.Notify());
                })
        }
        else{
            message.error("new password and confirm new password mismatch.")
        }
    }
    else{
        message.error("password lenght must be atleast 8 characters")
    }      
}
  render() {
    let categoryOptions = this.state.categoryOptionsArray.map((item, i) => (
      <Option value={item} label={item} key={i}>
        {item}
      </Option>
    ));
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      this.state.isMounted&&
      <div className="col-xl-12">
         {this.props.user.updateUserProfileOperationStatus=="InProcess"
          && this.spinner()}
        {this.state.isLoading  && this.spinner()}
        <section id="main-section" style={{ minHeight: window.innerHeight-150}}>
       
          
          <div className="row" style={{width:"100%"}}>   
          <div className="col-xl-12">
              <AdvertisementCard/>
          </div>     
          <div className="col-xl-1"></div>
          <div className="col-xl-10" style={{backgroundColor:"#f8f9fa"}}> 
            
              <div className="row" style={{paddingTop:"30px",paddingBottom:"30px"}}>
                
                <div className="col-xl-12">
                  <div className="row" style={{margin:"0px"}}>
                  <form onSubmit={this.handleOnSubmit} style={{width:"100%"}}>
                <div className="row">
                
                  <div className="col-xl-12" style={{paddingTop:"15px"}}>
                      <div  className="row">
                        <div className="col-xl-12">
                          <span className="profileFormHeadings">General Profile</span>
                        
                        </div>
                      
                      </div>
                  </div>
                  <div className="col-xl-12" style={{paddingTop:"15px"}}>
                    <div  className="row Level2Features">
                      <div className="col-xl-2">
                      <label htmlFor="ProfilePicture">Profile Picture</label>
                      <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            beforeUpload={this.beforeUpload}
                          >
                            {(this.state.photo && this.state.photo)!==""|| (this.state.photoLink && this.state.photoLink !=="") ? (
                              <img
                                src={this.state.photo?this.state.photo:this.state.photoLink}
                                alt="avatar"
                                style={{ width: "100%" }}
                              />
                            ) : (
                              uploadButton
                            )}
                          </Upload>
                      </div>
                      <div className="col-xl-10">
                        <div className="row">
                          <div className="col-xl-6">
                            <label htmlFor="firstName">First Name</label>
                            <input className="form-control" id="firstName" name="firstName" type="text" onChange={this.handleChange} value={this.state.firstName}/>
                          </div>
                          <div className="col-xl-6">
                            <label htmlFor="lastName">Last Name</label>
                            <input className="form-control" id="lastName" name="lastName" type="text" onChange={this.handleChange} value={this.state.lastName}/>
                          </div>
                        </div>
                        <div className="row" style={{paddingTop:"10px"}}>
                        <div className="col-xl-6">
                        <label htmlFor="inputLocation">Location</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputLocation"
                          name="location"
                          placeholder=""
                          onChange={this.handleChange}
                          value={this.state.location}
                        />
                      </div>
                        
                          <div className="col-xl-6">
                            <label htmlFor="categoryNames">Categories</label>
                              <Select
                                className="form-control"
                                id="categoryNames"
                                mode="multiple"
                                style={{ width: '100%' }}
                                size="large"
                                placeholder="Please select any three categories"
                                onChange={this.handleCategorySelect}
                                value={this.state.categoryNames && Array.isArray(this.state.categoryNames)?this.state.categoryNames:[] }
                              >
                                {categoryOptions}
                              </Select>
                          
                          </div>
                        </div>
                      </div>
                      
                      <div className="col-xl-12" style={{paddingTop:"0px"}}>
                        <div className="row">
                      <div className="col-xl-4">
                        <label htmlFor="inputCity">City</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputCity"
                          name="city"
                          onChange={this.handleChange}
                          value={this.state.city}
                        />
                      </div>
                      <div className="col-xl-4">
                        <label htmlFor="inputState">State</label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputState"
                            name="state"
                            onChange={this.handleChange}
                            value={this.state.state}
                          />
                      </div>
                      <div className="col-xl-2">
                        <label htmlFor="inputZip">Zip</label>
                        <input type="text" className="form-control" id="inputZip" name="zip" onChange={this.handleChange} value={this.state.zip}/>
                      </div>
                      <div className="col-xl-2">
                        <label htmlFor="inputPhone">Phone</label>
                        <input type="text" className="form-control" id="inputPhone" name="phone" onChange={this.handleChange} value={this.state.phone}/>
                      </div>
                    </div>
                    </div>
                      <div className="col-xl-12" style={{paddingTop:"15px"}}>
                    <div className="row">
                      <div className="col-xl-6" style={{paddingTop:"5px"}}>
                        <label htmlFor="aboutMe">About Yourself</label>
                        <textarea className="form-control" id="aboutMe" name="aboutMe" type="text" onChange={this.handleChange} value={this.state.aboutMe}/>
                      </div>
                      <div className="col-xl-6" >
                          <div className="row">
                          <div className="col-xl-4" style={{paddingTop:"5px"}}>
                            <label style={{display:"block"}}>Free Coaching?</label>
                            <Switch onChange={this.setIsFreeTraining} checkedChildren="Yes" unCheckedChildren="No" checked={this.state.isFreeTraining}  />
                          </div>
                      
                        
                          <div className="col-xl-4" style={{paddingTop:"5px"}}>
                            <label style={{display:"block"}}>Group Coaching?</label>
                            <Switch onChange={this.setIsGroupCoaching} checkedChildren="Yes" unCheckedChildren="No" checked={this.state.isGroupCoaching}  />
                          </div>
                      
                      
                          <div className="col-xl-4" style={{paddingTop:"5px"}}>
                            <label style={{display:"block"}}>One-On-One Coaching?</label>
                            <Switch onChange={this.setIsOneOnOne} checkedChildren="Yes" unCheckedChildren="No" checked={this.state.isOneOnOne}  />
                          </div>
                          </div>
                        </div>
                    </div>
                  </div>
                    </div>
                  </div>

                  <div className="col-xl-12" style={{paddingTop:"15px"}}>
                    <div  className="row">
                      <div className="col-xl-12">
                        <span className="profileFormHeadings">Level 3 Features</span>
                       
                      </div>
                      <div className="col-xl-12">
                      
                        <span>You can add one website link, one social media link, 3 story videos and delete unwanted comments by upgrading your plan to Level 3</span>
                        {this.authorizationProvider.userCanAddStoryVideos?
                        <span style={{float:"right", fontWeight:"500"}}>You are subscribed to this plan <CheckCircleOutlined style={{color:"green",fontSize:"20px"}}/></span>:
                        <span style={{float:"right", fontWeight:"500"}}>You are not subscribed to this plan <CloseCircleOutlined style={{color:"red",fontSize:"20px"}}/><a href={this.state.cfLinkToUpgradeLevel3} style={{paddingLeft:"5px"}}>Click Here</a> to upgrade</span>
                        }
                      </div>
                    </div>
                 </div>
                  <div className="col-xl-12" style={{paddingTop:"15px"}}>
                    <div  className="row Level3Features">
                        
                        <div className="col-xl-2" style={{paddingTop:"5px"}}>
                        <label htmlFor="website">Website</label>
                        {this.authorizationProvider.userCanAddWebsiteLink?
                        <input className={this.authorizationProvider.userCanAddWebsiteLink?"form-control":"authoriztionFailed form-control"} id="website" name="website" type="text" onChange={this.handleChange} value={this.state.website} disabled={!this.authorizationProvider.userCanAddWebsiteLink}/>
                        :
                        <Popover  content={this.getPopoverContent("website")} title="Upgrade your subscription level">
                          <input className={this.authorizationProvider.userCanAddWebsiteLink?"form-control":"authoriztionFailed form-control"} id="website" name="website" type="text" onChange={this.handleChange} value={this.state.website} disabled={!this.authorizationProvider.userCanAddWebsiteLink}/>
                        </Popover>
                        // <Tooltip title={this.authorizationProvider.upgradeToLevelThreeMessageString}>
                        
                        // </Tooltip>
                        }
                        </div>
                        <div className="col-xl-2" style={{paddingTop:"5px"}}>
                          <label htmlFor="fbProfilePage">Social Media Account</label>
                          {this.authorizationProvider.userCanAddSocialMediaLinks?
                            <input className={this.authorizationProvider.userCanAddSocialMediaLinks?"form-control":"authoriztionFailed form-control"}  id="fbProfilePage" name="fbProfilePage" type="text" onChange={this.handleChange} value={this.state.fbProfilePage} disabled={!this.authorizationProvider.userCanAddSocialMediaLinks}/>
                            :<Popover  content={this.getPopoverContent("facebook")} title="Upgrade your subscription level">
                              <input className={this.authorizationProvider.userCanAddSocialMediaLinks?"form-control":"authoriztionFailed form-control"}  id="fbProfilePage" name="fbProfilePage" type="text" onChange={this.handleChange} value={this.state.fbProfilePage} disabled={!this.authorizationProvider.userCanAddSocialMediaLinks}/>
                            </Popover>
                            // :<Tooltip title={this.authorizationProvider.upgradeToLevelThreeMessageString}>
                            // <input className={this.authorizationProvider.userCanAddSocialMediaLinks?"form-control":"authoriztionFailed form-control"}  id="fbProfilePage" name="fbProfilePage" type="text" onChange={this.handleChange} value={this.state.fbProfilePage} disabled={!this.authorizationProvider.userCanAddSocialMediaLinks}/>
                            // </Tooltip>
                          }
                        </div>
                        <div className="col-xl-8" style={{paddingTop:"5px"}}>
                          <div className="row">
                            <div className="col-xl-5">
                              <label htmlFor="videoLink">Level 3 Story Video Title (Up to three)</label>
                              {this.authorizationProvider.userCanAddStoryVideos?
                                <input  className={this.authorizationProvider.userCanAddStoryVideos?"form-control":"authoriztionFailed form-control"} id="yourStoryTitle" name="yourStoryTitle" type="text" onChange={this.handleChange} value={this.state.yourStoryTitle} disabled={!this.authorizationProvider.userCanAddStoryVideos}/>
                                :<Popover  content={this.getPopoverContent("storyvideos")} title="Upgrade your subscription level">
                                  <input className={this.authorizationProvider.userCanAddStoryVideos?"form-control":"authoriztionFailed form-control"} id="yourStoryTitle" name="yourStoryTitle" type="text" onChange={this.handleChange} value={this.state.yourStoryTitle} disabled={!this.authorizationProvider.userCanAddStoryVideos}/>
                                </Popover>
                                /* :<Tooltip title={this.authorizationProvider.upgradeToLevelThreeMessageString}>
                                <input className={this.authorizationProvider.userCanAddStoryVideos?"form-control":"authoriztionFailed form-control"} id="yourStoryTitle" name="yourStoryTitle" type="text" onChange={this.handleChange} value={this.state.yourStoryTitle} disabled={!this.authorizationProvider.userCanAddStoryVideos}/>
                                </Tooltip> */}
                            </div>
                            <div className="col-xl-5">
                              <label htmlFor="videoLink">Level 3 Story Video Link</label>
                              {this.authorizationProvider.userCanAddStoryVideos?
                                <input  className={this.authorizationProvider.userCanAddStoryVideos?"form-control":"authoriztionFailed form-control"} id="yourStoryLink" name="yourStoryLink" type="text" onChange={this.handleChange} value={this.state.yourStoryLink} disabled={!this.authorizationProvider.userCanAddStoryVideos}/>
                                :<Popover  content={this.getPopoverContent("storyvideos")} title="Upgrade your subscription level">
                                  <input className={this.authorizationProvider.userCanAddStoryVideos?"form-control":"authoriztionFailed form-control"} id="yourStoryLink" name="yourStoryLink" type="text" onChange={this.handleChange} value={this.state.yourStoryLink} disabled={!this.authorizationProvider.userCanAddStoryVideos}/>
                                  </Popover>
                                //  :<Tooltip title={this.authorizationProvider.upgradeToLevelThreeMessageString}>
                                //  <input className={this.authorizationProvider.userCanAddStoryVideos?"form-control":"authoriztionFailed form-control"} id="yourStoryLink" name="yourStoryLink" type="text" onChange={this.handleChange} value={this.state.yourStoryLink} disabled={!this.authorizationProvider.userCanAddStoryVideos}/>
                                //  </Tooltip>
                                }
                            </div>
                            <div className="col-xl-2" style={{paddingTop:"20px"}}>
                              <button  type="button"  className="btn btnAddTrainingVideo" onClick={this.addYourStoryVideoLink}>
                                <Icon  type="plus" style={{fontSize:"30px",color:"white",cursor:"pointer"}} ></Icon>
                              </button>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-xl-12">
                            <List
                                dataSource={this.state.storyVideoLinks && Array.isArray(this.state.storyVideoLinks)?this.state.storyVideoLinks:[] }
                                renderItem={item => (
                                <List.Item>
                                  <span className="featuresSpan" style={{width:"45%"}}>{item.Title}</span>
                                  <span className="featuresSpan" style={{width:"45%"}}>{item.Link}</span>
                                
                                  <Icon data-title={item.Title}  data-link={item.Link} onClick={this.deleteYourStoryVideoLink} type="delete" style={{width:"10%", fontSize:"16px",color:"darkred",cursor:"pointer"}} ></Icon>
                            
                              </List.Item> 
                            )}
                            />
                            </div>
                            </div>
                
                          </div>
                       
                    </div>
                  </div>
         
                  <div className="col-xl-12" style={{paddingTop:"15px"}}>
                    <div  className="row">
                      <div className="col-xl-12">
                        <span className="profileFormHeadings">Level 4 Features</span>
                       
                      </div>
                      <div className="col-xl-12">
                      
                        <span>You can add up to 5 training videos to the carousel by upgrading your plan to Level 4</span>
                        {this.authorizationProvider.userCanAddTrainingVideos?
                        <span style={{float:"right", fontWeight:"500"}}>You are subscribed to this plan <CheckCircleOutlined style={{color:"green",fontSize:"20px"}}/></span>:
                        <span style={{float:"right", fontWeight:"500"}}>You are not subscribed to this plan <CloseCircleOutlined style={{color:"red",fontSize:"20px"}}/><a href={this.state.cfLinkToUpgradeLevel4} style={{paddingLeft:"5px"}}>Click Here</a> to upgrade</span>
                        }
                      </div>
                    </div>
                 </div>

                  <div className="col-xl-12" style={{paddingTop:"15px"}}>
                      <div  className="row Level4Features">
                      <div className="col-xl-4">
                        <label htmlFor="website"><StarOutlined style={{color:"green" ,fontSize:"20px",paddingRight:"5px"}}/>Level 4 Training Video Carousel (Up to five) </label>
                        <label htmlFor="website"><StarOutlined style={{color:"green" ,fontSize:"20px",paddingRight:"5px"}}/>First link uploaded will appear last in the carousel </label>
                      </div>
                      <div className="col-xl-8">
                        
                      <div className="row">
                        <div className="col-xl-10">
                          {this.authorizationProvider.userCanAddTrainingVideos?
                            <input  className={this.authorizationProvider.userCanAddTrainingVideos?"form-control":"authoriztionFailed form-control"} id="trainingVideoLink" name="trainingVideoLink" type="text" onChange={this.handleChange} value={this.state.trainingVideoLink} disabled={!this.authorizationProvider.userCanAddTrainingVideos}/>
                            :<Popover  content={this.getPopoverContent("trainingvideos")} title="Upgrade your subscription level">
                              <input  className={this.authorizationProvider.userCanAddTrainingVideos?"form-control":"authoriztionFailed form-control"} id="trainingVideoLink" name="trainingVideoLink" type="text" onChange={this.handleChange} value={this.state.trainingVideoLink} disabled={!this.authorizationProvider.userCanAddTrainingVideos}/>
                            </Popover>
                            // :<Tooltip title={this.authorizationProvider.upgradeToLevelFourMessageString}>
                            // <input  className={this.authorizationProvider.userCanAddTrainingVideos?"form-control":"authoriztionFailed form-control"} id="trainingVideoLink" name="trainingVideoLink" type="text" onChange={this.handleChange} value={this.state.trainingVideoLink} disabled={!this.authorizationProvider.userCanAddTrainingVideos}/>
                              
                            // </Tooltip>
                          }
                        </div>
                        <div className="col-xl-2"> 
                          <button type="button"  className="btn btnAddTrainingVideo" onClick={this.addTrainingVideoLink}>
                            <Icon  type="plus" style={{fontSize:"30px",color:"white",cursor:"pointer"}} ></Icon>
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-12">
                          <List
                           dataSource={this.state.trainingVideoLinks && Array.isArray(this.state.trainingVideoLinks)?this.state.trainingVideoLinks:[] }
                           
                            renderItem={item => (
                            <List.Item>
                              <span className="featuresSpan">{item}</span>
                              <Icon data-link={item} onClick={this.deleteTrainingVideoLink} type="delete" style={{fontSize:"16px",color:"darkred",cursor:"pointer"}} ></Icon>
                            </List.Item> 
                            )}
                            />
                        </div>
                      </div>
                    </div>
                  
                  </div>   

                </div>
                
                
                  
                <div className="col-xl-6" style={{paddingTop:"25px"}}>
                    <div  className="row">
                      <div className="col-xl-12">
                        <span className="profileFormHeadings">Level 5 Features</span>
                        {this.authorizationProvider.userCanReceiveEmailDirectly?
                        <span style={{float:"right", fontWeight:"500"}}>You are subscribed to this plan <CheckCircleOutlined style={{color:"green",fontSize:"20px"}}/></span>:
                        <span style={{float:"right", fontWeight:"500"}}>You are not subscribed to this plan <CloseCircleOutlined style={{color:"red",fontSize:"20px"}}/><a href={this.state.cfLinkToUpgradeLevel5} style={{paddingLeft:"5px"}}>Click Here</a> to upgrade</span>
                        }
                       
                      </div>
                  
                    </div>
                    
                    <div  className="row Level5Features">
                      <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                        
                        <span style={{fontWeight:"500"}}>
                        Upgrade your plan to Level 5 and enjoy the below features
                        </span>

                      </div>
                      <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                        <StarOutlined style={{color:"green",fontSize:"20px",paddingRight:"5px"}}></StarOutlined>
                        <span>Receive direct messages from people viewing your profile</span>
                      </div>
                      <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                        <StarOutlined style={{color:"green",fontSize:"20px",paddingRight:"5px"}}></StarOutlined>
                        <span>RUSH Affiliate Status - up to 60% commissions <a href="https://coachranking.clickfunnels.com/cr-affiliate-setup-l3-pg/898dd72e866">Click here</a> to set up your affiliate account</span>
                      </div>

                    </div>
                 </div>

                 <div className="col-xl-6" style={{paddingTop:"25px"}}>
                    <div  className="row">
                      <div className="col-xl-12">
                        <span className="profileFormHeadings">Level 6 Features</span>
                        {this.props.user.userProfile.addOnLevel?
                        <span style={{float:"right", fontWeight:"500"}}>You are subscribed to this plan <CheckCircleOutlined style={{color:"green",fontSize:"20px"}}/></span>:
                       
                        <span style={{float:"right", fontWeight:"500"}}>You are not subscribed to this plan <CloseCircleOutlined style={{color:"red",fontSize:"20px"}}/><a href={this.state.cfLinkToUpgradeLevel6} style={{paddingLeft:"5px"}}>Click Here</a> to upgrade</span>
                        }
                       
                      </div>
                  
                    </div>
                    
                    <div  className="row Level5Features">
                      <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                        
                        <span style={{fontWeight:"500"}}>
                          Upgrade your plan to Level 6 and enjoy the below features
                        </span>

                      </div>
                      <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                        <StarOutlined style={{color:"green",fontSize:"20px",paddingRight:"5px"}}></StarOutlined>
                        <span>Upgrade your plan to include a Sponsored Leaderboard advertising position</span>
                      </div>
                      <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                        <StarOutlined style={{color:"green",fontSize:"20px",paddingRight:"5px"}}></StarOutlined>
                        <span>Advertise your profile in the #1, #2 or #3 position of your favorite category niche (Seniority determines position)</span>
                      </div>
                    </div>
                 </div>
               
               </div>         
                <div className="row" style={{paddingTop:"10px"}}>
                <div className="col-xl-12">
                <button type="submit"  className="btn btnSaveProfile">
                  Save
                </button>
                </div></div>
              </form>
              <form onSubmit={this.ChangePassword} style={{width:"100%"}}>
                <div className="row">
                <div className="col-xl-12" style={{paddingTop:"15px"}}>
                      <div  className="row">
                        <div className="col-xl-12">
                          <span className="profileFormHeadings">Change Password</span>
                        
                        </div>
                      
                      </div>
                  </div>
                <div className="col-xl-12" style={{paddingTop:"15px"}}>
                    <div  className="row ChangePassword">
                    
                          <div className="col-xl-3">
                              <span className="propertyFormLable"> Old Password  <span className="required-text">*</span></span>
                              <div>
                                  <input type="password" name="oldPassword" className="form-control propertyFormControl" required="required"  value={this.state.oldPassword} onChange={this.handleChange} />
                              </div>
                          </div> 
                      
                          <div className="col-xl-3">
                              <span className="propertyFormLable">  New Password  <span className="required-text">*</span></span>
                              <div>
                              <input type="password" name="newPassword" className="form-control propertyFormControl" required="required" value={this.state.newPassword} onChange={this.handleChange} />
                              </div>
                          </div>
                      
                          <div className="col-xl-3">
                              <span className="propertyFormLable">  Confirm New Password  <span className="required-text">*</span></span>
                              <div>
                              <input type="password" name="confirmNewPassword" className="form-control propertyFormControl" required="required" value={this.state.confirmNewPassword} onChange={this.handleChange} />
                              </div>
                          </div> 
                      
                          
                          <div className="col-xl-3" style={{ textAlign: "right" }}>
                              <button type="submit" disabled={this.state.disableButton} className="btn btnSaveProfile" >APPLY </button>
                          </div>
                        
                    </div>
                  </div>
                 </div>
                 </form>
                  </div>
                </div>
              </div>
          </div>
          <div className="col-xl-1"></div>
          <div className="col-xl-12">
              <AdvertisementCard/>
            </div> 
          </div>   
        </section>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
