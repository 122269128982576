import {
  GET_BANNER_TYPE_INITIATED,
  GET_BANNER_TYPE_SUCCESS,
  GET_BANNER_TYPE_FAILURE,
  GET_BANNER_TYPE_RESET,
  SET_BANNER_TYPE_INITIATED,
  SET_BANNER_TYPE_SUCCESS,
  SET_BANNER_TYPE_FAILURE,
  SET_BANNER_TYPE_RESET,
  UPDATE_COACH_PROFILE_BY_ADMIN_INITIATED,
  UPDATE_COACH_PROFILE_BY_ADMIN_SUCCESS,
  UPDATE_COACH_PROFILE_BY_ADMIN_FAILURE,
  UPDATE_COACH_PROFILE_BY_ADMIN_RESET,

  LINK_PROFILE_WITH_USER_INITIATED,
  LINK_PROFILE_WITH_USER_SUCCESS,
  LINK_PROFILE_WITH_USER_FAILURE,
  LINK_PROFILE_WITH_USER_RESET,
} from "../constants/constants";

const initialState = {


  getBannerTypeOperationCompleted: false,
  getBannerTypeOperationStatus: "Not_Started",
  getBannerTypeResult:{},

  setBannerTypeOperationCompleted: false,
  setBannerTypeOperationStatus: "Not_Started",
  setBannerTypeResult:{},

  updateCoachProfileByAdminOperationCompleted: false,
  updateCoachProfileByAdminOperationStatus: "Not_Started",
  updateCoachProfileByAdminResult:{},

 
  linkProfileWithUserOperationCompleted: false,
  linkProfileWithUserOperationStatus: "Not_Started",
  linkProfileWithUserResult: {}
};

const adminReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_BANNER_TYPE_INITIATED:
      return {
        ...state,
        getBannerTypeOperationCompleted: false,
        getBannerTypeOperationStatus: "InProcess"
      };
    case GET_BANNER_TYPE_SUCCESS:
 
      return {
        ...state,
        getBannerTypeResult: action.payload,
        getBannerTypeOperationCompleted: true,
        getBannerTypeOperationStatus: "Completed_Success"
      };
    case GET_BANNER_TYPE_FAILURE:
      return {
        ...state,
        getBannerTypeResult: action.payload,
        getBannerTypeOperationCompleted: true,
        getBannerTypeOperationStatus: "Completed_Failed"
      };
    case GET_BANNER_TYPE_RESET:
      return {
        ...state,
        getBannerTypeOperationCompleted: false,
        getBannerTypeOperationStatus: "Not_Started"
      };
    case SET_BANNER_TYPE_INITIATED:
      return {
        ...state,
        setBannerTypeOperationCompleted: false,
        setBannerTypeOperationStatus: "InProcess"
      };
    case SET_BANNER_TYPE_SUCCESS:
      return {
        ...state,
       
        setBannerTypeResult: action.payload,
        setBannerTypeOperationCompleted: true,
        setBannerTypeOperationStatus: "Completed_Success"
      };
    case SET_BANNER_TYPE_FAILURE:
      return {
        ...state,
        setBannerTypeResult: action.payload,
        setBannerTypeOperationCompleted: true,
        setBannerTypeOperationStatus: "Completed_Failed"
      };
    case SET_BANNER_TYPE_RESET:
      return {
        ...state,
        setBannerTypeOperationCompleted: false,
        setBannerTypeOperationStatus: "Not_Started"
      };
    case UPDATE_COACH_PROFILE_BY_ADMIN_INITIATED:
      return {
        ...state,
        updateCoachProfileByAdminOperationCompleted: false,
        updateCoachProfileByAdminOperationStatus: "InProcess"
      };
    case UPDATE_COACH_PROFILE_BY_ADMIN_SUCCESS:
      return {
        ...state,
        updateCoachProfileByAdminResult: action.payload,
        updateCoachProfileByAdminOperationCompleted: true,
        updateCoachProfileByAdminOperationStatus: "Completed_Success"
      };
    case UPDATE_COACH_PROFILE_BY_ADMIN_FAILURE:
      return {
        ...state,
        updateCoachProfileByAdminResult: action.payload,
        updateCoachProfileByAdminOperationCompleted: true,
        updateCoachProfileByAdminOperationStatus: "Completed_Failed"
      };
    case UPDATE_COACH_PROFILE_BY_ADMIN_RESET:
      return {
        ...state,
        updateCoachProfileByAdminOperationCompleted: false,
        updateCoachProfileByAdminOperationStatus: "Not_Started"
      };

    case LINK_PROFILE_WITH_USER_INITIATED:
      return {
        ...state,
        linkProfileWithUserOperationCompleted: false,
        linkProfileWithUserOperationStatus: "InProcess"
      };
    case LINK_PROFILE_WITH_USER_SUCCESS:
      return {
        ...state,
        linkProfileWithUserResult: action.payload,
        linkProfileWithUserOperationCompleted: true,
        linkProfileWithUserOperationStatus: "Completed_Success"
      };
    case LINK_PROFILE_WITH_USER_FAILURE:
      return {
        ...state,
        linkProfileWithUserResult: action.payload,
        linkProfileWithUserOperationCompleted: true,
        linkProfileWithUserOperationStatus: "Completed_Failed"
      };
    case LINK_PROFILE_WITH_USER_RESET:
      return {
        ...state,
        linkProfileWithUserOperationCompleted: false,
        linkProfileWithUserOperationStatus: "Not_Started"
      };
    default:
      return state;
  }
};

export default adminReducer;
