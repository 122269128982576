import React from 'react';
import { connect } from 'react-redux';
import SearchInput from "../components/SearchInput"
import { getBannerTypeInitiated, setBannerTypeInitiated, setNavbarCustomCss } from '../actions/actions'
import {levelRestrictions} from '../constants/level-restrictions'
import { Switch } from 'antd';
import '../assets/css/AdminPanel.css';
import { message, Spin, Upload, Icon } from "antd";

const mapStateToProps = state => {
  return state
}
const mapDispatchToProps = (dispatch) => {
  return {
    getBannerType: () => dispatch(getBannerTypeInitiated()),
    setBannerType: (pref) => dispatch(setBannerTypeInitiated(pref)),
    setNavbarCustomCss: (data) => dispatch(setNavbarCustomCss(data))
  }
}
class AdminPanel extends React.Component {
  constructor() {
    super()
    this.state = {
      refreshBanner:false,
    }
  }
  componentDidMount() {
    this.props.setNavbarCustomCss({
      navbarMode:'lightmode'
    })
    
  
  }
  componentDidUpdate(prevProps) {
    if(prevProps!=this.props){
      if (this.props.admin.setBannerTypeOperationCompleted){
          if(this.props.admin.setBannerTypeOperationStatus=="Completed_Success"){
            this.setState({messageLevel:"success",
            message:"Banner prefrence updated successfully.",
            refreshBanner:true,
            },
            () => this.Notify());
          }
          else if(this.props.admin.setBannerTypeOperationStatus=="Completed_Failed"){
            this.setState({messageLevel:"error",
            message:"Failed to update banner prefrence."
            },
            () => this.Notify());
          }
        }
        if(this.state.refreshBanner){
          this.setState({refreshBanner:false},()=>this.props.getBannerType());
          
        }
    }
   
  }
  setBannerPreference = (checked) => {

    this.props.setBannerType(checked);
  }
  spinner=()=>{
		return(
		<div className="row">
				<div className="col-xl-12" style={{textAlign:"center",position:"absolute",top:"20px",zIndex:"1000"}}>

				<Spin size="large"></Spin>

			</div>
		</div>)
  }
  Notify = () => {
    if (this.state.messageLevel == "success") {
      message.success(this.state.message);
    } else if (this.state.messageLevel == "error") {
      message.error(this.state.message);
    }
  };

  render() {
    if (this.props.user.userProfile.level >= levelRestrictions.IS_ADMIN) {
      return (
        <div className="col-xl-12">
           {this.props.admin.setBannerTypeOperationStatus=="InProcess"||
           this.props.admin.getBannerTypeOperationStatus=="InProcess"
          && this.spinner()}
          <section   style={{ minHeight: window.innerHeight-155 }}>
          <div className="row" style={{width:"100%",backgroundColor:"#f3f5f9",margin:"0px",marginTop:"100px"}}>
            <div className="col-xl-2"></div>
            <div className="col-xl-8">
            <div className="row">
              <div className="col-xl-10" style={{display:"flex",height:"100px",justifyContent:"center",alignItems:"center"}}>
                <SearchInput history={this.props.history} bottom="0%" width="100%" path='search' />
              </div>
              <div className="col-xl-2" style={{display:"flex",height:"100px",justifyContent:"center",alignItems:"center"}}>
                <Switch onChange={this.setBannerPreference} checkedChildren="Google Ads" unCheckedChildren="Custom Ads" defaultChecked={this.props.admin.getBannerTypeResult.isGoogleBanner}  />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
              </div>
            </div>
            </div>
            <div className="col-xl-2"></div>
            </div>
          </section>
        </div>
      )
    } else {
      return (
        <div className="container">
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "90vh" }}>
              <p>You are not authenticated to view this page.</p>
            </div>
          </div>
      )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel);