import { takeEvery, takeLatest, put, call, all, fork } from 'redux-saga/effects';
import 
{ GET_SEARCH_RESULTS_INITIATED,
  GET_SEARCH_RESULTS_BY_CATEGORY_INITIATED,
  GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_INITIATED,
  GET_PROFILE_BY_ID_INITIATED,
  GET_FEATURED_COACHES_INITIATED,
  GET_CATEGORIES_BY_PLAN_TYPE_INITIATED } from '../constants/constants';
import api from '../api';
// import {push} from 'react-router-redux';
import 
{ 
  getSearchResultsSuccess,
  getSearchResultsFailure,
  getSearchResultsReset,
  getProfileByIdSuccess,
  getProfileByIdFailure,
  getProfileByIdReset,
  getSearchResultsByCategorySuccess,
  getSearchResultsByCategoryFailure,
  getSearchResultsByCategoryReset,
  getSearchResultsByCategoryFeaturedUsersSuccess,
  getSearchResultsByCategoryFeaturedUsersFailure,
  getSearchResultsByCategoryFeaturedUsersReset,
  getFeaturedCoachesSuccess,
  getFeaturedCoachesFailure,
  getFeaturedCoachesReset,
  getCategoriesByPlanTypeSuccess,
  getCategoriesByPlanTypeFailure,
  getCategoriesByPlanTypeReset
} from '../actions/actions';

function* handleGetSearchResultsInitiated(action) {
  try {
    const res = yield call(api.getSearchResults, action.payload);
    yield put(getSearchResultsSuccess(res.data));
    yield put(getSearchResultsReset(null));
  } catch (error) {
    console.log(error);
    yield put(getSearchResultsFailure(error));
    yield put(getSearchResultsReset(null));
  }
}

function* handleGetFeaturedCoachesInitiated(action) {
  try {
    const res = yield call(api.getFeaturedCoachesByCategory, action.payload)
    const data = {};
    data[action.payload] = res.data.data
    yield put(getFeaturedCoachesSuccess(data));
    yield put(getFeaturedCoachesReset(null));
  } catch (error) {
    yield put(getFeaturedCoachesFailure(error));
    yield put(getFeaturedCoachesReset(null));
  }
}

function* handleGetSearchResultsByCategoryInitiated(action) {
  try {   
    const res = yield call(api.getSearchResultsByCategory, action.payload);
    yield put(getSearchResultsByCategorySuccess(res.data));
    yield put(getSearchResultsByCategoryReset(null));
  } catch (error) {
    console.log(error);
    yield put(getSearchResultsByCategoryFailure(error));
    yield put(getSearchResultsByCategoryReset(null));
  }
}
function* handleGetSearchResultsByCategoryFeaturedUsersInitiated(action) {
  try {   
    const res = yield call(api.searchByCategoryFeaturedUsers, action.payload);
    yield put(getSearchResultsByCategoryFeaturedUsersSuccess(res.data));
    yield put(getSearchResultsByCategoryFeaturedUsersReset(null));
  } catch (error) {
    console.log(error);
    yield put(getSearchResultsByCategoryFeaturedUsersFailure(error));
    yield put(getSearchResultsByCategoryFeaturedUsersReset(null));
  }
}

function* handleGetProfileByIdInitiated(action) {
  try {
    const res = yield call(api.getProfileById, action.payload);
    yield put(getProfileByIdSuccess(res.data));
    yield put(getProfileByIdReset(null));
  } catch (error) {
    console.log(error);
    yield put(getProfileByIdFailure(error));
    yield put(getProfileByIdReset(null));
  }
}
function* handleGetCategoriesByPlanTypeInitiated(action) {
  try {
    const res = yield call(api.getCategoriesByPlanType, action.payload);
    yield put(getCategoriesByPlanTypeSuccess(res.data));
    yield put(getCategoriesByPlanTypeReset(null));
  } catch (error) {
    console.log(error);
    yield put(getCategoriesByPlanTypeFailure(error));
    yield put(getCategoriesByPlanTypeReset(null));
  }
}

function* watchGetSeachResultsInitiated() {
  yield takeLatest(GET_SEARCH_RESULTS_INITIATED, handleGetSearchResultsInitiated)
}

function* watchGetFeturedCoachesInitiated() {
  yield takeEvery(GET_FEATURED_COACHES_INITIATED, handleGetFeaturedCoachesInitiated)
}

function* watchGetSearchResultsByCategoryInitiated() {
  yield takeLatest(GET_SEARCH_RESULTS_BY_CATEGORY_INITIATED, handleGetSearchResultsByCategoryInitiated)
}
function* watchGetSearchResultsByCategoryFeaturedUsersInitiated() {
  yield takeLatest(GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_INITIATED, handleGetSearchResultsByCategoryFeaturedUsersInitiated)
}


function* watchGetProfileByIdInitiated() {
  yield takeLatest(GET_PROFILE_BY_ID_INITIATED, handleGetProfileByIdInitiated)
}
function* watchGetCategoriesByPlanTypeInitiated() {
  yield takeLatest(GET_CATEGORIES_BY_PLAN_TYPE_INITIATED, handleGetCategoriesByPlanTypeInitiated)
}

export default function* searchSagas() {
  yield all([fork(watchGetSeachResultsInitiated), fork(watchGetProfileByIdInitiated), fork(watchGetSearchResultsByCategoryInitiated),fork(watchGetSearchResultsByCategoryFeaturedUsersInitiated) ,fork(watchGetFeturedCoachesInitiated),fork(watchGetCategoriesByPlanTypeInitiated)])
}