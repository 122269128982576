import React from 'react';
import { connect } from 'react-redux';
import { updateUserTypeInitiated, setNavbarCustomCss,getUserProfileInitiated } from '../actions/actions'
import "../assets/css/SelectAccountType.css"
import { message, Spin, Upload, Icon } from "antd";
import api from '../api';

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(getUserProfileInitiated()),
    setNavbarCustomCss: (data) => dispatch(setNavbarCustomCss(data))
  }
}

class Thankyou extends React.Component {

  componentDidMount() {
    this.props.setNavbarCustomCss({
      navbarMode:'lightmode'
    });
    this.props.getUserProfile();
  }

  spinner=()=>{
		return(
		<div className="row">
			<div className="col-xl-12" style={{textAlign:"center",position:"absolute",top:"20px",zIndex:"1000"}}>

				<Spin size="large"></Spin>

			</div>
		</div>)
  }
  goToProfileSettings=()=>{
    this.props.history.push("/profile");
  }
  Notify = () => {
    if (this.state.messageLevel == "success") {
      message.success(this.state.message);
    } else if (this.state.messageLevel == "error") {
      message.error(this.state.message);
    }
  };

  render() {
    return (
      <div className="col-xl-12">
        
        <section className="selectAccountTypeMainSection" style={{ minHeight: window.innerHeight-150}}>
        <div className="row" style={{width:"100%"}}>
          <div className="col-xl-2">
          </div>
          <div className="col-xl-8" style={{backgroundColor:"white",paddingTop:"50px",paddingBottom:"50px"}}>
            <div className="row">
              <div className="col-xl-12" style={{textAlign:"center"}}>
                  <span className="ThankyouForJoiningUs">Thank you for joining us. Please update your profile by clicking this button.</span>
                  <button
                    onClick={this.goToProfileSettings}
                    className="btn btnProfileSettings"
                  >
                    Profile Settings
                  </button>
              </div>
            </div>
            <div className="row" style={{paddingTop:"30px"}}>
              <div className="col-xl-12">
                <div className="ThankyouMainSection">  </div>
              </div>
            </div>
          
          </div>
          <div className="col-xl-2">
          </div>
        </div>
        </section>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Thankyou);