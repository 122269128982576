import React from 'react';
import { connect } from 'react-redux';
import api from '../api';
import { setNavbarCustomCss,getUserProfileInitiated } from '../actions/actions'
import { message, Spin,Modal, Switch, Select,Button } from "antd";
import {
  cfLinkToUpgradeLevel3,
  cfLinkToUpgradeLevel4,
  cfLinkToUpgradeLevel5,
  cfLinkToUpgradeLevel6Alt,
  cfLinkToUpgradeLevel6Standard,
  cfLinkToUpgradeLevel6Premium
} from '../constants/cf_levelupgrade_links';
const { Option } = Select;

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNavbarCustomCss: data => dispatch(setNavbarCustomCss(data)),
    getUserProfile: () => dispatch(getUserProfileInitiated()),
  }
}
const addOnsOptionsArray=[{name:"level6alt",value:"level6alt"},
{name:"level6premium",value:"level6premium"},
{name:"level6standard",value:"level6standard"},
]
let addOnsOptions = addOnsOptionsArray.map((item, i) => (
  <Option value={item.value} label={item.name} key={i}>
    {item.name}
  </Option>
));

class Subscription extends React.Component {
  constructor() {
    super();
    this.state = {
        isLoading:false,
        nicheOptionsArray:[],
        categoriesByPlanType:[],
        fourMonthSubscription:false,
        featuredCategory:null,
        nicheSelectModalPopupVisible:false
    }
  }

  componentDidMount=async()=> {
    this.props.setNavbarCustomCss({
      navbarMode:'darkmode'
    });
    if(this.props.match.params.level=="level2"){
      try{
        this.setState({isLoading:true,disableButton:true});  
      let res=await api.subscriptionRequest({"level":this.props.match.params.level});

      //this.props.getUserProfile();
      //let refreshUserProfile=await api.getUserProfile();
      this.setState({
        isLoading:false,
        disableButton:false,
        messageLevel:"success",
        message:"You have successfully upgraded your plan"
        },
      () => this.Notify());
      this.props.history.push('/');
      }
      catch(e)
      {
        console.log(e);
        this.setState({
          isLoading:false,
          disableButton:false,
          messageLevel:"error",
          message:"Failed to upgrade your plan."
          },
        () => this.Notify());
      }
    }
    else{
      let res=await api.getCategoriesByPlanType();
      let categoriesByPlanType=res.data;
      let nicheOfPlanArray=categoriesByPlanType[this.props.match.params.level];

      let Level6UpgradeTitle="Standard Level 6 Advertising Upgrade";
      if(this.props.match.params.level=="level6alt"){
        Level6UpgradeTitle="Alternate Level 6 Advertising Upgrade";
      }
      else if(this.props.match.params.level=="level6premium"){
        Level6UpgradeTitle="Premium Level 6 Advertising Upgrade";
      }
      else if(this.props.match.params.level=="level6standard"){
        Level6UpgradeTitle="Standard Level 6 Advertising Upgrade";
      }
      this.setState({nicheOptionsArray:nicheOfPlanArray,nicheSelectModalPopupVisible:true,Level6UpgradeTitle:Level6UpgradeTitle});
      
    }
  }
  handleNicheSelect=(nicheSelected)=>{
    this.setState({ featuredCategory: nicheSelected });
  }
  setFourMonthSubscriptionFlag=(checked)=>{
    this.setState({ fourMonthSubscription: checked });
  }
  closeModalPopup= () => {
    this.setState({nicheSelectModalPopupVisible:false});
  };
  subscriptionRequestSaveIfNicheAvailable=async()=>{
    try
    {
      this.setState({isLoadingModalPopup:true,disableButton:true});
      let res=await api.subscriptionRequest({"featuredCategory":this.state.featuredCategory,"fourMonthSubscription":this.state.fourMonthSubscription,"addOnLevel":this.props.match.params.level})
     

      if (res.data.subscriptionSuccessful){

        let LinkToUpgrade=cfLinkToUpgradeLevel6Alt;
        if(this.props.match.params.level=="level6alt"){
          LinkToUpgrade=cfLinkToUpgradeLevel6Alt
        }
        else if(this.props.match.params.level=="level6premium"){
          LinkToUpgrade=cfLinkToUpgradeLevel6Premium
        }
        else if(this.props.match.params.level=="level6standard"){
          LinkToUpgrade=cfLinkToUpgradeLevel6Standard
        }
        if(this.props.user.userProfile.affiliateId)
        window.location.href = LinkToUpgrade+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
        else
        window.location.href = LinkToUpgrade+"?email="+this.props.user.userProfile.email+"";
      }
      else{
        this.setState({
          isLoadingModalPopup:false,
          disableButton:false,
          messageLevel:"error",
          message:res.data.message
        },
        () => this.Notify());
          //niche occupied
      }
    }
    catch(err){
      this.setState({
        isLoadingModalPopup:false,
        disableButton:false,
        messageLevel:"error",
        message:"An error occured while upgrading your plan",
      },
      () => this.Notify());
    }
  }
  // componentDidUpdate(prevProps,prevState){
    
  //   if(prevProps!=this.props){
  //     if (this.props.user.getUserProfileOperationCompleted){
       
  //         if(this.props.user.getUserProfileOperationStatus=="Completed_Success"){
  //           this.setState({messageLevel:"success",
  //           message:"You have successfully upgraded your plan."
  //           },
  //           () => this.Notify());
  //           this.props.history.push('/');
  //         }
  //         else if(this.props.user.getUserProfileOperationStatus=="Completed_Failed"){
  //           this.setState({messageLevel:"error",
  //           message:"Failed to upgrade your plan."
  //           },
  //           () => this.Notify());
  //         }
  //       }
  //   }
  // }
  spinner=()=>{
		return(
		<div className="row">
			<div className="col-xl-12" style={{textAlign:"center",position:"absolute",top:"20px",zIndex:"1000"}}>

				<Spin size="large"></Spin>

			</div>
		</div>)
  }
  spinnerModalPopup = () => {
    return (
      <div style={{ position: "absolute", left: "50%", top: "10px" }}>
        <Spin size="large"></Spin>
      </div>
    );
  };
  Notify = () => {
    if (this.state.messageLevel == "success") {
      message.success(this.state.message);
    } else if (this.state.messageLevel == "error") {
      message.error(this.state.message);
    }
  };
  render() {
    let nicheOptions = this.state.nicheOptionsArray.map((item, i) => (
      <Option value={item} label={item} key={i}>
        {item}
      </Option>
    ));
    return (
      <div className="col-xl-12">
        <Modal
          maskClosable={false}
          title={this.state.Level6UpgradeTitle}
          onOk={this.subscriptionRequestSaveIfNicheAvailable}
          onCancel={this.closeModalPopup}
          visible={this.state.nicheSelectModalPopupVisible}
          footer={[
            <Button
              key="submit"
              className="btnLinkThisProfile"
              onClick={this.subscriptionRequestSaveIfNicheAvailable}
            >
            Upgrade
            </Button>
          ]}
        >
          <div className="row">
            <div className="col-xl-12" style={{ paddingBottom: "10px" }}>
                <span className="enterYourEmail">Select your Niche</span>
              </div>
            <div className="col-xl-12" style={{ paddingBottom: "10px" }}>
              <Select
                id="ddlNiche"
                style={{ width: "100%" }}
                size="large"
                placeholder="Please select a niche"
                onChange={this.handleNicheSelect}
                value={this.state.featuredCategory}
              >
                {nicheOptions}
              </Select>
            </div>
            <div className="col-xl-12" style={{ paddingBottom: "10px",display:"none" }}>
              <span className="enterYourEmail">Four Month Subscription?</span>
              <Switch onChange={this.setFourMonthSubscriptionFlag} checkedChildren="Yes" unCheckedChildren="No"  checked={this.state.fourMonthSubscription}  />
            </div>
          </div>
        </Modal>
        
        {this.state.isLoading && this.spinner()}
        {this.state.isLoadingModalPopup  && this.spinnerModalPopup()}
          <div className="row searchPageLandingSection"  style={{ minHeight: window.innerHeight-30,maxHeight: window.innerHeight-30}}>
          </div>
      </div>
    )
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(Subscription);