import React from 'react';
import { connect } from 'react-redux';
import '../assets/css/TagPill.css'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const mapStateToProps = state => {
  return state
}

class TagPill extends React.Component {

  render() {
    return (
      <li className={`pill ${this.props.tag.selected ? 'selected' : ''}`}>
        <span>{this.props.tag.name}</span> {this.props.tag.selected ? <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#0B3790'}} inverse={true} /> : <span></span>}
      </li>
    )
  }
}

export default connect(mapStateToProps)(TagPill);