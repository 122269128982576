import React from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import "../assets/css/Plans.css";
import PlanCard from "./PlanCard";
import { message, Spin, Modal, Button,Switch,Select } from "antd";
import {  setNavbarCustomCss,getCategoriesByPlanTypeInitiated,subscriptionRequestInitiated } from '../actions/actions';
import { Auth, Hub } from 'aws-amplify';

const stripe = window.Stripe("pk_test_qJVZlT77TiJUAyxf7iKLUYVg00hp93AYfn");
const { Option } = Select;
const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNavbarCustomCss: (data) => dispatch(setNavbarCustomCss(data)),
    getCategoriesByPlanTypeInitiated: (data) => dispatch(getCategoriesByPlanTypeInitiated(data)),
    subscriptionRequestInitiated: (data) => dispatch(subscriptionRequestInitiated(data)),
  }
}

class PlanSelector extends React.Component {
  constructor() {
    super();
    this.state = {
      planId:"",
      stripePlanId:0,
      PlanSpecificationModalPopupVisible:false,
      fourMonthSubscription:true,
      selectedFeatures:[],
      standardPlans: [
        {
          planId:"level2",
          planName: "Level 2",
          planCharges: "Free",
          prefix: <span></span>,
          planDesc: <p className="p-2 planDesc text-center">free to all coaches to create a profile page or claim an existing profile alrteady in the database</p>,
          planFeatures: ['Allows them to add / change a picture(auto resize)', 'Create a bio (limit to three paragraphs or 250 words – NO LINKS ALLOWED IN BIO)', 'Select 2 category niches', 'Auto size and crop all photos to be clear and small in file size for optimal load speed', 'Allow those with Level 2 profiles to archive or hide their profile in their backend area', 'Have a way to verify that the real user has access to the right account by verifying their account, email or social media login'],
          stripePlan: ""
        },
        {
          planId:"level3",
          planName: "Level 3",
          planCharges: "$97",
          prefix: <span className="prefix">at</span>,
          planDesc: <p className="p-2 planDesc text-center">per year that autorenews every 12 months until user canceles the subscription</p>,
          planFeatures: ['Allows a level 2 user to add (in the right sidebar of their profile page)', 'One website link', 'One social media link', 'One video link from their backend to be displayed (in a 400w x 225h – responsive per device)', 'With user control so they can change links anytime from their backend area'],
          stripePlan: "plan_GGL94bZIxeNEbd"
        },
        {
          planId:"level4",
          planName: "Level 4",
          planCharges: "$297",
          prefix: <span className="prefix">at</span>,
          planDesc: <p className="p-2 planDesc text-center">per year that autorenews every 12 months until user canceles the subscription</p>,
          planFeatures: ['Make this profile upgrade a stand alone upgrade and as an upsell option after coaches upgrade to user level 3', 'Allows activation of the “Contact Me Directly” pop up modal feature', 'Once a Coach gets this upgrade, level 1-5 users will be able to click the “Contact Me Directly” button and send the level 4 coach a direct message through email or text from anywhere their profile is displayed', 'Location of button on profile is in previous photos'],
          stripePlan: "plan_GGLTvnArxR0Xqm"
        }
      ],
      advertisingPlans: [
        {
          planId:"level5alt",
          planName: "ALT NICHES",
          planCharges: "$250",
          prefix: <span className="prefix">at</span>,
          planDesc: <p className="p-2 planDesc text-center">30 Day Listings reduced from <span className="oldPrice">$500</span> to <span className="newPrice">$250</span> for the first three months</p>,
         // planFeatures: ["Ads Agency, Affiliate Marketing Coach, Beauty Coach, Business Coach, Business Consultant, Dating Coach, eCommerce Coach, Entrepreneur Coach, Family Coach, Fitness Coach, Health Coach, Internet Marketing Coach, Keynote Speaker, Life Coach, Love Coach, Marketing Coach, Marriage Coach, Meditation Coach, Money Coach, Parenting Coach, Performance Coach, Relationship Coach, Sales Coach, Self-Defense Coach, Social Media Coach, Speaking Coach, Spiritual Coach, Transformational Coach, Wealth Coach, Weight Loss Coach, Wellness Coach, plus a few more as we update"],
         planFeatures:[],
         stripePlan: "plan_GHYXKb8Cw3qzW4"
        },
        {
          planId:"level5standard",
          planName: "STANDARD NICHES",
          planCharges: "$400",
          prefix: <span className="prefix">at</span>,
          planDesc: <p className="p-2 planDesc text-center">30 Day Listings reduced from <span className="oldPrice">$800</span> to <span className="newPrice">$400</span> for the first three months</p>,
         // planFeatures: ["Accupuncturist, Add/Addhd Coach, Adoption Agency, Astrologist, Autism Coach, Boxing Coach, Career Coach, Christian Coach, Confidence Coach, Cryprocurrency Coach, Diabetes Coach, Divorce Coach, Employment Headhunter, Executive Coach, Facebook Coach, Finance Coach, Financial Advisor, Golf Coach, Guitar Coach, Hypnotherapist, Instagram Coach, Insurance Salesperson, LBGT Coach, Leadership Coach, LinkedIn Coach, MatchMaking Service, Mediator, Men's Coach, Network Marketing Coach (MLM), NLP Practitioner, Nutritionist, Personal Trainer, Prepper (Survival) Coach, Quit Smoking Coach, Retirement Coach, Retreats, Sex Coach, Sleep Coach, Sobriety Coach, Team Coach, Teenager Coach, Timeshare Coach, Trading Coach, Travel Agent, Vocals Coach, Women's Coach, Writing Coach, plus a few more as we update"],
         planFeatures:[], 
         stripePlan: "plan_GHYX9sb7teDBxh"
        },
        {
          planId:"level5premium",
          planName: "PREMIUM NICHES",
          planCharges: "$600",
          prefix: <span className="prefix">at</span>,
          planDesc: <p className="p-2 planDesc text-center">30 Day Listings reduced from <span className="oldPrice">$1,100</span> to <span className="newPrice">$600</span> for the first three months</p>,
          //planFeatures: ["Acting Coach, Aerialist Coach, Angel Investor, Ayurveda Coach, Baseball Coach (Clinic), Basketball Coach (Clinic), Betting Coach, Bowling Coach (Clinic), Cannabis Coach, Cheerleading Coach, Climbing Coach, CPA (Certified Personal Accountant), Dance Coach, DJ Coach, Dog Breeder, Dog Trainer, Drums Coach, Fashion Consultant, Football Coach (Clinic), Gymnastics Coach (Clinic), Hockey Coach (Clinic), Horse Trainer, Ice Skating Coach, Interior Designer, Kabbalah Coach, Loan Officer Coach, Magic Coach, Martial Arts Instructor, Masseur, MMA, (Mixed Martial Arts), Modeling Agency, Numerologist, Pageant Coach, Parkinson's Coach, Photographer, Piano Coach, Pilates Coach, Real Estate Coach, Reiki Practitioner, Soccer Coach (Clinic), Softball Coach (Clinic), Surfing Coach (Clinic), Swimming Coach (Clinic), Tai Chi Instructor, Tarot Coach, Tennis Coach (Clinic), Volleyball Coach (Clinic), Website Developer, Wrestling Coach (Clinic), Yoga Coach, plus a few more as we update"],
          planFeatures:[],
          stripePlan: "plan_GHYWVlK1VkFb2A"
        }
      ],
      isAdvertising: false
    };
  }
  checkout = async plan => {
    await Auth.currentAuthenticatedUser({ bypassCache: true })
    .then(user => {
      stripe.redirectToCheckout({
        clientReferenceId: user.username,
        items: [{ plan: plan, quantity: 1 }],
        successUrl:"https://www.authorityalert.com/success?session_id={CHECKOUT_SESSION_ID}",
        cancelUrl: "https://www.authorityalert.com/fail",
        customerEmail:this.props.user.userProfile.email,
        // successUrl: 'http://localhost:3000/success?session_id={CHECKOUT_SESSION_ID}',
        // cancelUrl: 'http://localhost:3000/fail'
      })
      .then(result => {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        console.log(result);
      })
      .catch(err => {
        console.log(err);
       
      });
      
    });
   
  };
  componentDidMount() {
    this.props.setNavbarCustomCss({
      navLinkColor:"#254D9F",
      navBackground:"white",
      btnColor: '#555555',
      btnBorder: '#555555'
    });
    this.props.getCategoriesByPlanTypeInitiated("");

  } 
  componentDidUpdate(prevProps,prevState){
    if(prevProps!=this.props ){
      if (this.props.subscription.subscriptionRequestOperationCompleted){
        if(this.props.subscription.subscriptionRequestOperationStatus=="Completed_Success"){
          
          if(this.props.subscription.subscriptionResult.redirectToCheckout==true){
            this.setState({PlanSpecificationModalPopupVisible:false});
            this.checkout(this.state.stripePlanId);
          }
          else if(this.props.subscription.subscriptionResult.subscriptionSuccessful==true){
            this.setState({level:"success",
            message:this.props.subscription.subscriptionResult.message
            },
            () => this.Notify());
          }
          else if(this.props.subscription.subscriptionResult.subscriptionSuccessful==false){
            this.setState({level:"error",
            message:this.props.subscription.subscriptionResult.message
            },
            () => this.Notify());
          }
        }
        else if(this.props.subscription.subscriptionRequestOperationStatus=="Completed_Failed"){
          this.setState({level:"error",
          message:"An error occured while executing your request.Please try again.",
          },() => this.Notify());
        }
        
      }
    }
  }

  onClickStandardPlan = () => {
    this.setState({ isAdvertising: false });
  };
  onClickAdvertisingPlan = () => {
    this.setState({ isAdvertising: true });
  };
  renderStandardPlans() {
    return (
          this.state.standardPlans.map((plan,i) => {
            return <div className="col-xl-4" key={i} >
              <PlanCard data={plan} getStartedPlanSubscription={this.getStartedPlanSubscription}></PlanCard>
            </div>;
          })
    );
  }
  renderAdvertisingPlans() {
    return (
          this.state.advertisingPlans.map((plan,i) => {
            
            if(plan.planId== "level5standard"){
              plan.planFeatures=this.props.search.categoriesByPlanType.standard?this.props.search.categoriesByPlanType.standard:[];
            }
            else if(plan.planId== "level5premium"){
              plan.planFeatures=this.props.search.categoriesByPlanType.premium?this.props.search.categoriesByPlanType.premium:[];
            }
            else if(plan.planId== "level5alt"){
              plan.planFeatures=this.props.search.categoriesByPlanType.alternative?this.props.search.categoriesByPlanType.alternative:[];
            }
            return <div className="col-xl-4" key={i}>
              <PlanCard data={plan} getStartedPlanSubscription={this.getStartedPlanSubscription}></PlanCard>
            </div>;
          })
    );
  }
  getStartedPlanSubscription=(planId)=>{
    if(planId=="level2" || planId=="level3"|| planId=="level4"){
        var plan=this.state.standardPlans.find(x=>x.planId==planId);
        var stripePlanId=plan.stripePlan;
        this.props.subscriptionRequestInitiated({"plan":planId});
        this.setState({stripePlanId:stripePlanId});
    }
    else{
      var plan=this.state.advertisingPlans.find(x=>x.planId==planId);
      var stripePlanId=plan.stripePlan;
      let planFeatures =[];
      if(planId== "level5standard"){
        planFeatures = this.props.search.categoriesByPlanType.standard.map((item, i) => <Option value={item} label={item} key={i}>{item}</Option>)
      }
      else if(planId== "level5premium"){
        planFeatures = this.props.search.categoriesByPlanType.premium.map((item, i) => <Option value={item} label={item} key={i}>{item}</Option>)
      }
      else if(planId== "level5alt"){
        planFeatures = this.props.search.categoriesByPlanType.alternative.map((item, i) => <Option value={item} label={item} key={i}>{item}</Option>)
      }
      this.setState({PlanSpecificationModalPopupVisible:true,stripePlanId:stripePlanId,planFeatures:planFeatures,planId:planId});
    }
  }
  onPlanSpecificationOk=()=>{
    this.props.subscriptionRequestInitiated({"categories":this.state.selectedFeatures,"fourMonthSubscription":this.state.fourMonthSubscription,"plan":this.state.planId})
  }
  onPlanSpecificationCancel=()=>{
    this.setState({PlanSpecificationModalPopupVisible:false});
  }
  handleFeatureSelect = (selectedFeatures) => {
      if (selectedFeatures.length <= 3) {
      this.setState({selectedFeatures: selectedFeatures})
    } else { 
      this.setState({level:"error",
      message:"You can only select upto 3 features."
      },
      () => this.Notify());
    }
  }
  setFourMonthSubscriptionFlag=(checked)=>{
      this.setState({fourMonthSubscription:checked});
  }
  spinner=()=>{
		return(
		<div className="row">
				<div className="col-xl-12" style={{textAlign:"center",position:"absolute",top:"20px",zIndex:"1000"}}>
				<Spin size="large"></Spin>
			</div>
		</div>)
  }
  spinnerModalPopup = () => {
    return (
      <div style={{ position: "absolute", left: "50%", top: "10px" }}>
        <Spin size="large"></Spin>
      </div>
    );
  };
    Notify = () => {
    if (this.state.level == "success") {
      message.success(this.state.message);
    } else if (this.state.level == "error") {
      message.error(this.state.message);
    }
  };
  render() {
    return (
      <div className="col-xl-12">
         {this.props.subscription.subscriptionRequestOperationStatus=="InProcess" && this.spinner()}
          <Modal
            title="Plan Subscription"
            maskClosable={false}
            onOk={this.onPlanSpecificationOk}
            onCancel={this.onPlanSpecificationCancel}
            visible={this.state.PlanSpecificationModalPopupVisible}
            footer={[
              <Button
                key="submit"
                className="btnSubscribeForPlan"
                onClick={this.onPlanSpecificationOk}
              >
                Subscribe for Plan
              </Button>
            ]}
          >
            {this.props.subscription.subscriptionRequestOperationStatus=="InProcess" && this.spinnerModalPopup()}
            <div className="row">
              <div className="col-xl-12">
                <span className="FourMonthSubscription">Four Month Subscrption?</span>
                <Switch onChange={this.setFourMonthSubscriptionFlag} checkedChildren="Yes" unCheckedChildren="No"  defaultChecked={true}  />
              </div>
              <div className="col-xl-12" style={{ paddingTop: "10px" }}>
                <Select
                  id="categoryNames"
                  mode="multiple"
                  style={{ width: '100%' }}
                  size="large"
                  placeholder="Please select any three categories"
                  onChange={this.handleFeatureSelect}
                  value={this.state.selectedFeatures}
                >
                  {this.state.planFeatures}
                </Select>
              </div>
            </div>
          </Modal> 
        
         <section id="main-section" style={{ minHeight: window.innerHeight-150}}>
          <div className="row">
             <div className="col-xl-1"></div>
             <div className="col-xl-10">
              <div className="row"> 
                <div className="col-xl-12" style={{textAlign:"center",paddingBottom:"20px"}}>
                    <span className="ChooseOneLeaderBoardAdvertisingPackageBelow">
                    Choose One Leader Board Advertising Package Below...
                  </span>
                </div>
                <div className="col-xl-12" style={{textAlign:"center",paddingBottom:"20px"}}>
                  <button onClick={this.onClickStandardPlan} className={this.state.isAdvertising ?'btnPlansNotSelected btn':'btn btnPlansSelected'}>Standard Plans </button>
                  <button onClick={this.onClickAdvertisingPlan} className={this.state.isAdvertising ?'btnPlansSelected btn':'btn btnPlansNotSelected'}> Advertising Plans </button>
                </div>
                <div className="col-xl-12" style={{backgroundColor:"white"}}>
                  <div className="row">
                    {!this.state.isAdvertising
                      ? this.renderStandardPlans()
                      : this.renderAdvertisingPlans()}
                    </div>
                </div>
              </div>
             </div>
             <div className="col-xl-1"></div>
          </div>
        
        
        
        </section>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanSelector);
