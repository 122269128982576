import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './Home';
import SignUp from './SignUp';
import SignIn from './SignIn';
import Verify from './Verify';
import Thanks from './Thanks';
import ProfileForm from './ProfileForm';
import Plans from './PlansUpgrade';
import CoachCard from '../components/CoachCard';
import PlanSelector from './PlansSelector';
import Checkout from './CheckoutForm';
import UserProfile from './UserProfile';
import SearchPage from './SearchPage';
import CoachProfile from './CoachProfile';
import CategorySearchPage from './CategorySearchPage';
import UpgradeSuccess from './UpgradeSuccess';
import UpgradeFail from './UpgradeFail';
import SelectAccountType from './SelectAccountType'
import VerifyEmail from './VerifyEmail';
import AdminPanel from './AdminPanel';
import ProfileFormAdmin from './ProfileFormAdmin';
import ClaimProfile from './ClaimProfile';

function Main() {
  return (
    
      <Switch>
        <Route exact path='/' component={Home}/>
        <Route path='/signup' component={SignUp}/>
        <Route path='/signin' component={SignIn}/>
        <Route path='/verify' component={Verify}/>
        <Route path='/claimprofile' component={ClaimProfile}/>
        <Route path='/profileForm' component={ProfileForm}/>
        <Route path='/profile' component={UserProfile}/>
        <Route path="/thanks" component={Thanks}/>
        <Route path="/plans" component={PlanSelector}/>
        <Route path='/checkout' component={Checkout}/>
        <Route path='/success' component={UpgradeSuccess}/>
        <Route path='/fail' component={UpgradeFail}/>
        <Route path='/search/:keyword' component={SearchPage}/>
        <Route path='/coach/:id/edit' component={ProfileFormAdmin} />
        <Route path='/coach/:id' component={CoachProfile} />
        <Route path='/category/:category/:keyword' component={CategorySearchPage}/>
        <Route path='/category/:category' component={CategorySearchPage}/>
        <Route path='/account-type' component={SelectAccountType}/>
        <Route path='/verifyemail' component={VerifyEmail}/>
        <Route path='/adminpanel' component={AdminPanel}/>
      </Switch>
  
  );
}

export default Main;
