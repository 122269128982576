import { fork, all } from "redux-saga/effects";
import oauthSagas from "./oauthSagas";
import userSagas from "./userSagas";
import searchSagas from "./searchSagas";
import commentsSaga from "./commentsSaga";
import messageSagas from "./messageSagas";
import subscriptionSagas from "./subscriptionSagas"
import adminSagas from "./adminSagas";

export default function* rootSaga() {
    yield all([fork(oauthSagas), fork(userSagas), fork(searchSagas), fork(commentsSaga), fork(messageSagas), fork(adminSagas), fork(subscriptionSagas)]);
}