import {

  GET_SEARCH_RESULTS_INITIATED,
  GET_SEARCH_RESULTS_SUCCESS,
  GET_SEARCH_RESULTS_FAILURE,
  GET_SEARCH_RESULTS_RESET,

  GET_PROFILE_BY_ID_INITIATED,
  GET_PROFILE_BY_ID_SUCCESS,
  GET_PROFILE_BY_ID_FAILURE,
  GET_PROFILE_BY_ID_RESET,
  GET_SEARCH_RESULTS_BY_CATEGORY_INITIATED,
  GET_SEARCH_RESULTS_BY_CATEGORY_SUCCESS,
  GET_SEARCH_RESULTS_BY_CATEGORY_FAILURE,
  GET_SEARCH_RESULTS_BY_CATEGORY_RESET,

  GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_INITIATED,
  GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_SUCCESS,
  GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_FAILURE,
  GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_RESET,

  GET_FEATURED_COACHES_INITIATED,
  GET_FEATURED_COACHES_SUCCESS,
  GET_FEATURED_COACHES_FAILURE,
  GET_FEATURED_COACHES_RESET,

  GET_CATEGORIES_BY_PLAN_TYPE_INITIATED,
  GET_CATEGORIES_BY_PLAN_TYPE_SUCCESS,
  GET_CATEGORIES_BY_PLAN_TYPE_FAILURE,
  GET_CATEGORIES_BY_PLAN_TYPE_RESET,
} from "../constants/constants";

const initialState = {
  
  //////////////////////////////

  searchResultsLastPageReached: false,
  searchCategoryResultsLastPageReached: false,
  currentKeyword: '',

  getSearchResultsOperationCompleted : false,
  getSearchResultsOperationStatus:"Not_Started",
  searchResults: [],
  searchError:'',

  getSearchResultsByCategoryOperationCompleted : false,
  getSearchResultsByCategoryOperationStatus:"Not_Started",
  searchCategoryResults: [],
  searchCategoryError:'',

  getSearchCategoryFeaturedUsersOperationCompleted : false,
  getSearchCategoryFeaturedUsersOperationStatus:"Not_Started",
  searchCategoryFeaturedUsersResults: [],
  searchCategoryFeaturedUsersError:'',
  

  getFeaturedCoachesOperationCompleted : false,
  getFeaturedCoachesOperationStatus:"Not_Started",
  featuredCoachesHomepage: {},
  featuredCoachesError: '',

  getProfileByIdOperationCompleted : false,
  getProfileByIdOperationStatus:"Not_Started",
  coachProfile: {},
  coachProfileError: '',

  getCategoriesByPlanTypeOperationCompleted : false,
  getCategoriesByPlanTypeOperationStatus:"Not_Started",
  categoriesByPlanType: {},
  categoriesByPlanTypeError: '',


}

const searchReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_SEARCH_RESULTS_INITIATED:
      return {...state, getSearchResultsOperationCompleted: false, getSearchResultsOperationStatus: "InProcess", currentKeyword: action.payload};
    case GET_SEARCH_RESULTS_SUCCESS:
      let searchResults = []
      if (state.currentKeyword.from !== 0) {
        searchResults = [].concat(state.searchResults)
      }
      searchResults=searchResults.concat(action.payload.data)
      let searchResultsLastPageReached = false;
      if (action.payload.data.length === 0) {
        searchResultsLastPageReached = true
      }
      return {...state, searchResults: searchResults, getSearchResultsOperationCompleted: true, getSearchResultsOperationStatus: "Completed_Success", searchResultsLastPageReached};
    case GET_SEARCH_RESULTS_FAILURE:
      return {...state, searchError: action.payload, getSearchResultsOperationCompleted: true, getSearchResultsOperationStatus: "Completed_Failed"};
    case GET_SEARCH_RESULTS_RESET:
      return {...state,  getSearchResultsOperationCompleted: false, getSearchResultsOperationStatus: "Not_Started"};
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////  
    case GET_SEARCH_RESULTS_BY_CATEGORY_INITIATED:
      return {...state, getSearchResultsByCategoryOperationCompleted: false, getSearchResultsByCategoryOperationStatus: "InProcess", currentKeyword: action.payload};
    case GET_SEARCH_RESULTS_BY_CATEGORY_SUCCESS:
      let searchCategoryResults = []
      if (state.currentKeyword.from ) {

        
        searchCategoryResults = [].concat(state.searchCategoryResults)
      }
      searchCategoryResults = searchCategoryResults.concat(action.payload.data)
      let searchCategoryResultsLastPageReached = false;
      if (action.payload.data.length === 0) {
        searchCategoryResultsLastPageReached = true
      }
      return {...state, searchCategoryResults: searchCategoryResults, getSearchResultsByCategoryOperationCompleted: true, getSearchResultsByCategoryOperationStatus: "Completed_Success", searchCategoryResultsLastPageReached}
    case GET_SEARCH_RESULTS_BY_CATEGORY_FAILURE:
      return {...state, searchCategoryError: action.payload, getSearchResultsByCategoryOperationCompleted: true, getSearchResultsByCategoryOperationStatus: "Completed_Failed"};
    case GET_SEARCH_RESULTS_BY_CATEGORY_RESET:
      return {...state,  getSearchResultsByCategoryOperationCompleted: false, getSearchResultsByCategoryOperationStatus: "Not_Started"};
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_INITIATED:
      return {...state, getSearchCategoryFeaturedUsersOperationCompleted: false, getSearchCategoryFeaturedUsersOperationStatus: "InProcess", currentKeyword: action.payload};
    case GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_SUCCESS:

      return {...state, searchCategoryFeaturedUsersResults: action.payload.data, getSearchCategoryFeaturedUsersOperationCompleted: true, getSearchCategoryFeaturedUsersOperationStatus: "Completed_Success"}
    case GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_FAILURE:
      return {...state, searchCategoryFeaturedUsersError: action.payload, getSearchCategoryFeaturedUsersOperationCompleted: true, getSearchCategoryFeaturedUsersOperationStatus: "Completed_Failed"};
    case GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_RESET:
      return {...state,  getSearchCategoryFeaturedUsersOperationCompleted: false, getSearchCategoryFeaturedUsersOperationStatus: "Not_Started"};
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case GET_PROFILE_BY_ID_INITIATED:
      return {...state, getProfileByIdOperationCompleted: false,getProfileByIdOperationStatus:"InProcess"};
    case GET_PROFILE_BY_ID_SUCCESS:

    return {...state, coachProfile: action.payload.data, getProfileByIdOperationCompleted: true, getProfileByIdOperationStatus: "Completed_Success"};
   
      case GET_PROFILE_BY_ID_FAILURE:
      return {...state, coachProfileError: action.payload, getProfileByIdOperationCompleted: true,getProfileByIdOperationStatus:"Completed_Failed"};
    case GET_PROFILE_BY_ID_RESET:
      return {...state,  getProfileByIdOperationCompleted: false,getProfileByIdOperationStatus:"Not_Started"};
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case GET_FEATURED_COACHES_INITIATED:
      return {...state, getFeaturedCoachesOperationCompleted: false,getFeaturedCoachesOperationStatus:"InProcess"};
    case GET_FEATURED_COACHES_SUCCESS:
      let featuredCoachesHomepage = state.featuredCoachesHomepage;
      const newFeaturedCoachesHomepage = action.payload;
      featuredCoachesHomepage = {...featuredCoachesHomepage, ...newFeaturedCoachesHomepage};
      return {...state, featuredCoachesHomepage: featuredCoachesHomepage, getFeaturedCoachesOperationCompleted: true, getFeaturedCoachesOperationStatus: "Completed_Success"};
    case GET_FEATURED_COACHES_FAILURE:
      return {...state, featuredCoachesError: action.payload, getFeaturedCoachesOperationCompleted: true,getFeaturedCoachesOperationStatus:"Completed_Failed"};
    case GET_FEATURED_COACHES_RESET:
      return {...state,  getFeaturedCoachesOperationCompleted: false,getFeaturedCoachesOperationStatus:"Not_Started"};
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case GET_CATEGORIES_BY_PLAN_TYPE_INITIATED:
      return {...state, getCategoriesByPlanTypeOperationCompleted: false,getCategoriesByPlanTypeOperationStatus:"InProcess"};
    case GET_CATEGORIES_BY_PLAN_TYPE_SUCCESS:   
      return {...state, categoriesByPlanType: action.payload, getCategoriesByPlanTypeOperationCompleted: true, getCategoriesByPlanTypeOperationStatus: "Completed_Success"};
    case GET_CATEGORIES_BY_PLAN_TYPE_FAILURE:
      return {...state, categoriesByPlanTypeError: action.payload, getCategoriesByPlanTypeOperationCompleted: true,getCategoriesByPlanTypeOperationStatus:"Completed_Failed"};
    case GET_CATEGORIES_BY_PLAN_TYPE_RESET:
      return {...state,  getCategoriesByPlanTypeOperationCompleted: false,getCategoriesByPlanTypeOperationStatus:"Not_Started"};
    default:
      return state;
  }
};

export default searchReducer;

