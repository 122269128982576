import React from "react";
import { connect } from "react-redux";
import {
  updateCoachProfileByAdminInitiated,
  getUserProfileInitiated,
  linkProfileWithUserInitiated,
  setNavbarCustomCss
} from "../actions/actions";
import { Auth } from 'aws-amplify';
import ProfilePicture from "../components/ProfilePicture";
import { message, Spin, Upload, Icon, Modal,Button,List ,Popconfirm,Switch} from "antd";
import Linkify from "linkify-it";
import { Select } from "antd";
import "antd/dist/antd.css";
import "../assets/css/ProfileForm.css";
import AuthorizationProvider from '../AuthorizationProvider/authorizationProvider';
import { ColumnWidthOutlined,
  DeleteOutlined ,
  HighlightOutlined ,
  RiseOutlined ,
  CheckOutlined,
  CheckCircleOutlined ,
  CloseCircleOutlined,
  StarOutlined,

 } from '@ant-design/icons'
 import api from '../api';

var linkify = Linkify();

const { Option } = Select;


const levelOptionsArray=[{name:"level1",value:"level1"},
{name:"Level 2",value:"level2"},
{name:"Level 3",value:"level3"},
{name:"Level 4",value:"level4"},
{name:"Level 5",value:"level5"}];

const addOnsOptionsArray=[
  {name:"No Addon Level",value:"0"},
  {name:"Level 6 Alt",value:"level6alt"},
  {name:"Level 6 Premium",value:"level6premium"},
  {name:"Level 6 Standard",value:"level6standard"},
];


let levelOptions = levelOptionsArray.map((item, i) => (
  <Option value={item.value} label={item.name} key={i}>
    {item.name}
  </Option>
));
let addOnsOptions = addOnsOptionsArray.map((item, i) => (
  <Option value={item.value} label={item.name} key={i}>
    {item.name}
  </Option>
));


const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => {
  return {
    updateCoachProfile: state =>
    dispatch(updateCoachProfileByAdminInitiated(state)),
    setNavbarCustomCss: data => dispatch(setNavbarCustomCss(data)),
    linkProfileWithUserInitiated: data =>
    dispatch(linkProfileWithUserInitiated(data)),
    
    // getUserProfile: () => dispatch(getUserProfileInitiated())
  };
};

class ProfileForm extends React.Component {
  constructor() {
    super();
    this.state = {
      _id: "",
      zip: "",
      lastName: "",
      website: "",
      city: "",
      level:"",
      addOnLevel:"0",
      featuredCategory:null,
      isOneOnOne: false,
      fbProfilePage: "",
      //rating: 0,
      fullName: "",
      linkedIn: "",
      aboutMe: "",
      categoryNames: [],
      firstName: "",
      isFreeTraining: false,
      isGroupCoaching: false,
      phone: "",
      location: "",
      videoLink: "",
      state: "",
      photoLink: "",
      fbBusinessPage: "",
      email: "",
      isCoach: true,
      isClaimed: false,
      address: "",
      photo: "",
      linkProfileModalPopupVisible:false,
      cognitoId:"",
       isOneOnOne: false,
       isGroupCoaching: false,
       isFreeTraining: false,
      // "isCoach": false,
      trainingVideoLink:"",
      trainingVideoLinks:[],
      yourStoryLink:"",
      yourStoryTitle:"",
      storyVideoLinks:[],
      adminActions:[{actionId:1,action:"Delete this profile",actionIcon:"delete"},{actionId:2,action:"Link this profile",actionIcon:"play"},{actionId:3,action:"Add notes to this profile",actionIcon:"delete"},{actionId:4,action:"Upgrade User Level",actionIcon:"upgrade"}],
      notes:"",
      categoryOptionsArray:[],
      nicheOptionsArray:[],
      categoriesByPlanType:[],
      fourMonthSubscription:false,
      isMounted:false,
      oldPassword:"",
      newPassword:"",
      confirmNewPassword:"",
    };
  }

  componentDidMount=async()=> {
    this.props.setNavbarCustomCss({
      navbarMode:'lightmode'
    });

    let userProfile = this.props.search.coachProfile;
    this.setState(userProfile);
    // if(this.props.search.coachProfile.storyVideoLinks  && Array.isArray( this.props.search.coachProfile.storyVideoLinks))
    // {

    // }
    // else{
    //   this.setState({storyVideoLinks:[]});
    // }
    

  
    this.authorizationProvider=new AuthorizationProvider(this.props.search.coachProfile.level);

    let resallCategories=await api.getAllCategories_Plain();
    let allCategories=resallCategories.data;

    

    let res=await api.getCategoriesByPlanType();
    let categoriesByPlanType=res.data;
    this.setState({categoryOptionsArray:allCategories,categoriesByPlanType:categoriesByPlanType});

    if(userProfile.addOnLevel){
      let nicheOfPlanArray=categoriesByPlanType[userProfile.addOnLevel];
      this.setState({nicheOptionsArray:nicheOfPlanArray});
    }

    this.setState({isMounted:true,});
    
    // try{
    //   let res=await api.getNotesOfProfile(this.props.search.coachProfile._id);
    //   this.setState({notes:res.data})

    // }
    // catch(err){
    //   console.log(err);
    // }
   
  }

  componentDidUpdate(prevProps) {
    if (prevProps != this.props) {
      if (this.props.admin.updateCoachProfileByAdminOperationCompleted) {
        if (
          this.props.admin.updateCoachProfileByAdminOperationStatus ==
          "Completed_Success"
        ) {
          this.setState(
            {
              messageLevel: "success",
              message: "Profile updated successfully."
            },
            () => this.Notify()
          );
        } else if (
          this.props.admin.updateCoachProfileByAdminOperationStatus ==
          "Completed_Failed"
        ) {
          this.setState(
            { messageLevel: "error", message: "Failed to update profile." },
            () => this.Notify()
          );
        }
      }
      if (this.props.admin.linkProfileWithUserOperationCompleted) {
        if (
          this.props.admin.linkProfileWithUserOperationStatus ==
          "Completed_Success"
        ) {
          this.setState(
            {
              messageLevel: "success",
              message: "Profile linked with specified user successfully."
            },
            () => this.Notify()
          );
        } else if (
          this.props.admin.linkProfileWithUserOperationStatus ==
          "Completed_Failed"
        ) {
          this.setState(
            { messageLevel: "error", message: "Failed to link profile with specified user." },
            () => this.Notify()
          );
        }
      }
    }
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  beforeUpload = async file => {
    var self = this;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return false;
    }

    this.getBase64(file, base64String => {
      this.setState({ photo: base64String });
    });
    return false;
  };
  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleCategorySelect = categories => {
    // if (this.state.categoryNames.length <= 2) {
    if (categories.length <= 3) {
      this.setState({ categoryNames: categories });
    } else {
      this.setState(
        {
          messageLevel: "error",
          message: "You can only select up to 2 categories"
        },
        () => this.Notify()
      );
      return;
     
    }
  };

  handleOnSubmit = event => {
    event.preventDefault();
    let links = linkify.match(this.state.aboutMe);
    if (links && links.length) {
      this.setState(
        {
          messageLevel: "success",
          message: "You cannot have links in the About me section."
        },
        () => this.Notify()
      );
      return;
    }
    let website = this.state.website;
    if (this.state.website) {
      let websiteLink = linkify.match(this.state.website);
      if (websiteLink && websiteLink[0]) {
        website = websiteLink[0].url;
      } else {
        this.setState(
          { messageLevel: "error", message: "Enter a valid website link." },
          () => this.Notify()
        );
        return;
      }
    }
    // let fbBusinessPage = this.state.fbBusinessPage;
    // if (this.state.fbBusinessPage) {
    //   let fbBusinessPageLink = linkify.match(this.state.fbBusinessPage);
    //   if (fbBusinessPageLink && fbBusinessPageLink[0]) {
    //     fbBusinessPage = fbBusinessPageLink[0].url
    //   } else {
    //     this.setState({messageLevel:"error",
    //     message:"Enter a valid facebook business profile link."
    //     },
    //     () => this.Notify());
       
    //     return
    //   }
    // }
    let fbProfilePage = this.state.fbProfilePage;
    if (this.state.fbProfilePage) {
      let fbProfilePageLink = linkify.match(this.state.fbProfilePage);
      if (fbProfilePageLink && fbProfilePageLink[0]) {
        fbProfilePage = fbProfilePageLink[0].url
      } else {
        this.setState({messageLevel:"error",
        message:"Enter a valid social media link."
        },
        () => this.Notify());
       
        return
      }
    }
    // let linkedIn = this.state.linkedIn;
    // if (this.state.linkedIn) {
    //   let linkedInLink = linkify.match(this.state.linkedIn);
    //   if (linkedInLink && linkedInLink[0]) {
    //     linkedIn = linkedInLink[0].url
    //   } else {
    //     this.setState({messageLevel:"error",
    //     message:"Enter a valid linkedin profile link."
    //     },
    //     () => this.Notify());
       
    //     return
    //   }
    // }
    // let fullName = this.state.fullName;
    // if (!this.state.fullName.length) {
    //   fullName = this.state.firstName + ' ' + this.state.lastName;
    // }

    let firstName=this.state.firstName? this.state.firstName:"";
    let lastName= this.state.lastName?this.state.lastName:"";

    
    let fullName = firstName +" "+ lastName;

    //let fullName = this.state.firstName?this.state.firstName:"" + ' ' + this.state.lastName?this.state.lastName:"";
    
    var trainingVideoLinksRefactored=[];
    var storyVideoLinksRefactored=[];
    if(this.state.trainingVideoLinks.length>0){

      trainingVideoLinksRefactored=this.state.trainingVideoLinks.map((obj)=>{

        if(obj.indexOf("youtu.be/")>0)
        return "https://www.youtube.com/watch?v="+obj.split("youtu.be/")[1];
        return obj.split('&')[0];

      })
    }
    if(this.state.storyVideoLinks.length>0){

       storyVideoLinksRefactored=this.state.storyVideoLinks.map((obj)=>{

        if(obj.Link.indexOf("youtu.be/")>0)
        return {Title:obj.Title,Link: "https://www.youtube.com/watch?v="+obj.Link.split("youtu.be/")[1]};
        return {Title:obj.Title,Link:obj.Link.split('&')[0]};

      })
    }
    this.setState({fullName, website,fbProfilePage,trainingVideoLinks:trainingVideoLinksRefactored,storyVideoLinks:storyVideoLinksRefactored }, () => {
      this.props.updateCoachProfile(this.state);
    });
  };
  submitLinkProfile=()=>{
    this.props.linkProfileWithUserInitiated({cognitoId:this.state.cognitoId,profileToClaimId:this.props.search.coachProfile._id})
  }
  submitAddNotes=async()=>{

    try
    {
      this.setState({isLoadingModalPopup:true,disableButton:true});
      let res=await api.addNotesInProfile(this.props.search.coachProfile._id,this.state.notes);
      console.log(res);
      this.setState({isLoadingModalPopup:false,disableButton:false})
      this.setState({
        addNotesModalPopupVisible:false,
        isLoadingModalPopup:false,
        disableButton:false,
        messageLevel:"success",
        message:"Notes updated successfully."
      },
      () => this.Notify());

    }
    catch(err){
      this.setState({
        isLoadingModalPopup:false,
        disableButton:false,
        messageLevel:"error",
        message:"Failed to add Notes."
      },
      () => this.Notify());
    }
  }
  submitDeleteUser=async()=>{
    try
    {
      this.setState({isLoading:true,disableButton:true});
      let res=await api.deleteProfile(this.props.search.coachProfile._id);
     
      this.setState({
        isLoading:false,
        disableButton:false,
        messageLevel:"success",
        message:"Profile deleted."
      },
      
      () =>this.Notify(),
         setTimeout(() => {
          this.props.history.push('/')
        }, 1000)
      
      );

    }
    catch(err){
     
      this.setState({
        isLoading:false,
        disableButton:false,
        messageLevel:"error",
        message:"Failed to delete profile."
      },
      () => this.Notify());
    }

    
  }
  handleChangeImage = event => {
    console.log("Uploading");
    const self = this;
    const reader = new FileReader();
    const file = event.target.files[0];

    reader.onload = function(upload) {
      self.setState({
        photo: upload.target.result
      });
    };
    reader.readAsDataURL(file);
  };
  deleteTrainingVideoLink=(evt)=>{
    var filteredAry = this.state.trainingVideoLinks.filter(e => e !== evt.currentTarget.dataset.link)
    this.setState({trainingVideoLinks:filteredAry});
  }
  deleteYourStoryVideoLink=(evt)=>{
    var filteredAry = this.state.storyVideoLinks.filter(e => e.Title !== evt.currentTarget.dataset.title)
    this.setState({storyVideoLinks:filteredAry});
  }
  
  addTrainingVideoLink=()=>{
    if(this.state.trainingVideoLinks.length<5){
      if(this.state.trainingVideoLink.length>0){
        var filteredAry = this.state.trainingVideoLinks;
        if(filteredAry.indexOf(this.state.trainingVideoLink)<0){
        filteredAry.push(this.state.trainingVideoLink);
        this.setState({trainingVideoLinks:filteredAry,trainingVideoLink:""});
        }
        else{
          this.setState({messageLevel:"error",
          message:"You have already added this link."
          },
          () => this.Notify());
        }
      }
    }
    else{
      this.setState({messageLevel:"error",
      message:"You can add only up to 5 training videos."
      },
      () => this.Notify());
    }

  }
    
  addYourStoryVideoLink=()=>{
    
    if(this.state.storyVideoLinks.length<3){
      if(this.state.yourStoryLink.length>0 &&  this.state.yourStoryTitle.length>0){
        var filteredAry = this.state.storyVideoLinks;
        if(filteredAry.find(x=>x.Title==this.state.yourStoryTitle)){
          

          this.setState({messageLevel:"error",
          message:"You have already added this Title."
          },
          () => this.Notify());
      
        }
        else if(filteredAry.find(x=> x.Link==this.state.yourStoryLink)){
          this.setState({messageLevel:"error",
          message:"You have already added this Link."
          },
          () => this.Notify());
        }
        else{
        
          filteredAry.push({"Title":this.state.yourStoryTitle,"Link":this.state.yourStoryLink});
          this.setState({storyVideoLinks:filteredAry,yourStoryLink:"",yourStoryTitle:""});
        }
      }
    }
    else{
      this.setState({messageLevel:"error",
      message:"You can add only up to 3 story videos."
      },
      () => this.Notify());
    }
  }

  handleAdminAction=(evt)=>{
   
    var actionId=evt.currentTarget.dataset.actionid
    if(actionId==1){
      this.openModalPopupToLinkProfile();
    }
    if(actionId==2){
      this.openModalPopupToLinkProfile();
    }
    if(actionId==3){
      this.openModalPopupToAddNotes();
    }
    if(actionId==4){
      this.openModalPopupToUpgradeLevel();
    }

  }
  spinner = () => {
    return (
      <div className="row">
        <div
          className="col-xl-12"
          style={{
            textAlign: "center",
            position: "absolute",
            top: "20px",
            zIndex: "1000"
          }}
        >
          <Spin size="large"></Spin>
        </div>
      </div>
    );
  };
  spinnerModalPopup = () => {
    return (
      <div style={{ position: "absolute", left: "50%", top: "10px" }}>
        <Spin size="large"></Spin>
      </div>
    );
  };
  Notify = () => {
    if (this.state.messageLevel == "success") {
      message.success(this.state.message);
    } else if (this.state.messageLevel == "error") {
      message.error(this.state.message);
    }
  };
  openModalPopupToLinkProfile = () => {
    if(!this.props.search.coachProfile.isClaimed){
      
      this.setState({linkProfileModalPopupVisible:true});
    }
    else{
      this.setState(
        { messageLevel: "error", message: "This profile is already linked." },
        () => this.Notify()
      );
    }
  }
  openModalPopupToAddNotes = () => {
        this.setState({addNotesModalPopupVisible:true});
  }
  openModalPopupToUpgradeLevel = () => {
    this.setState({upgradeLevelModalPopupVisible:true});
  }

  closeModalPopup= () => {
    this.setState({linkProfileModalPopupVisible:false,addNotesModalPopupVisible:false,upgradeLevelModalPopupVisible:false});
  };

  handleLevelSelect=(levelSelected)=>{
    this.setState({ level: levelSelected });
  }
  handleAddOnLevelSelect=(addOnSelected)=>{

    if(addOnSelected!=="0"){
    let categoriesByPlanType=this.state.categoriesByPlanType;
    let nicheOfPlanArray=categoriesByPlanType[addOnSelected];
    this.setState({addOnLevel: addOnSelected,nicheOptionsArray:nicheOfPlanArray,featuredCategory:nicheOfPlanArray[0]});
    }
    else{
      this.setState({nicheOptionsArray:[],featuredCategory:null,addOnLevel:"0"});
    }
  }
  handleNicheSelect=(nicheSelected)=>{

    this.setState({ featuredCategory: nicheSelected });
    
  }
  setFourMonthSubscriptionFlag=(checked)=>{

    this.setState({ fourMonthSubscription: checked });
    
  }
  
  submitUpgradeLevel=async()=>{

    try{

      this.setState({isLoadingModalPopup:true,disableButton:true});
      let addOnLevel=this.state.addOnLevel;
      if (this.state.addOnLevel=="0"){
        addOnLevel=null;
      }
      let res=await api.adminUpgradeUserLevel(this.state._id,this.state.level,addOnLevel,this.state.featuredCategory,this.state.fourMonthSubscription);

      console.log(res)
      if (res.data.subscriptionSuccessful){
        this.setState({
          isLoadingModalPopup:false,
          disableButton:false,
          messageLevel:"success",
          message:"Plan upgraded successfully."
        },
        () => this.Notify());
      }
      else{
        this.setState({
          isLoadingModalPopup:false,
          disableButton:false,
          messageLevel:"error",
          message:res.data.message
        },
        () => this.Notify());
      }

    }
    catch(err){
      this.setState({
        isLoadingModalPopup:false,
        disableButton:false,
        messageLevel:"error",
        message:"Failed to upgrade level."
      },
      () => this.Notify());
      console.log(err);
    }
    
  }
  setIsFreeTraining=(checked)=>{
    this.setState({isFreeTraining:checked});
  }
  setIsGroupCoaching=(checked)=>{
    this.setState({isGroupCoaching:checked});
  }
  setIsOneOnOne=(checked)=>{
    this.setState({isOneOnOne:checked});
  }

  ChangePassword=async(e)=>{

    e.preventDefault();
    if(this.state.newPassword.length>=8 && this.state.confirmNewPassword.length>=8){
        if(this.state.newPassword==this.state.confirmNewPassword){
            this.setState({disableButton:true,isLoading:true});
                let user=await Auth.currentAuthenticatedUser();
                await Auth.changePassword(user, this.state.oldPassword,this.state.newPassword).then(res=>{
                    this.setState({
                        disableButton:false,
                        isLoading:false,
                        message:"Password updated successfully.",
                        messageLevel: "success"
                    }, () => this.Notify());
                }).catch(err=>{
                    this.setState({
                        disableButton:false,
                        isLoading:false,
                        message:err.message,
                        messageLevel: "error"
                    }, () => this.Notify());
                })
        }
        else{
            message.error("new password and confirm new password mismatch.")
        }
    }
    else{
        message.error("password lenght must be atleast 8 characters")
    }      
}
  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    let nicheOptions = this.state.nicheOptionsArray.map((item, i) => (
      <Option value={item} label={item} key={i}>
        {item}
      </Option>
    ));
    let categoryOptions = this.state.categoryOptionsArray.map((item, i) => (
      <Option value={item} label={item} key={i}>
        {item}
      </Option>
    ));
   

    return (
      this.state.isMounted&&
      <div className="col-xl-12">
        {this.props.admin.updateCoachProfileByAdminOperationStatus =="InProcess" && this.spinner()}
        {this.props.admin.linkProfileOperationStatus == "InProcess" && this.spinnerModalPopup()}
        {this.state.isLoading  && this.spinner()}
        {this.state.isLoadingModalPopup  && this.spinnerModalPopup()}
        <Modal
          maskClosable={false}
          title="Link Profile"
          onOk={this.submitLinkProfile}
          onCancel={this.closeModalPopup}
          visible={this.state.linkProfileModalPopupVisible}
          footer={[
            <Button
              key="submit"
              className="btnLinkThisProfile"
              onClick={this.submitLinkProfile}
            >
            Link
            </Button>
          ]}
        >
          <div className="row">
            <div className="col-xl-12" style={{ paddingBottom: "10px" }}>
              <span className="enterYourEmail">Enter User Id</span>
            </div>
            <div className="col-xl-12" style={{ paddingBottom: "10px" }}>
              <input
                type="text"
                className="form-control"
                name="cognitoId"
                onChange={this.handleChange}
                required
              />
            </div>
          </div>
        </Modal>
        <Modal
          maskClosable={false}
          title="Notes"
          onOk={this.submitAddNotes}
          onCancel={this.closeModalPopup}
          visible={this.state.addNotesModalPopupVisible}
          footer={[
            <Button
              key="submit"
              className="btnLinkThisProfile"
              onClick={this.submitAddNotes}
            >
            Update Notes
            </Button>
          ]}
        >
          <div className="row">
            <div className="col-xl-12" style={{ paddingBottom: "10px" }}>
              <span className="enterYourEmail">Notes</span>
            </div>
            <div className="col-xl-12" style={{ paddingBottom: "10px" }}>
            <textarea
                rows="4" cols="50"
                className="form-control"
                name="notes"
                onChange={this.handleChange}
                value={this.state.notes}
                
              />
            </div>
          </div>
        </Modal>
        <Modal
          maskClosable={false}
          title="Upgrade Level"
          onOk={this.submitUpgradeLevel}
          onCancel={this.closeModalPopup}
          visible={this.state.upgradeLevelModalPopupVisible}
          footer={[
            <Button
              key="submit"
              className="btnLinkThisProfile"
              onClick={this.submitUpgradeLevel}
            >
            Upgrade Level
            </Button>
          ]}
        >
          <div className="row">
           
            <div className="col-xl-12" style={{ paddingBottom: "10px" }}>
              <span className="enterYourEmail">Primary Plan</span>
            </div>
            <div className="col-xl-12" style={{ paddingBottom: "10px" }}>
              <Select
                id="ddlLevels"
                style={{ width: "100%" }}
                size="large"
                value={this.state.level}
                onChange={this.handleLevelSelect}
               
              >
                {levelOptions}
              </Select>
            </div>
              <div className="col-xl-12" style={{ paddingBottom: "10px" }}>
                <span className="enterYourEmail">Advertising Plan</span>
              </div>
            <div className="col-xl-12" style={{ paddingBottom: "10px" }}>
              <Select
                id="ddlAddOns"
                style={{ width: "100%" }}
                size="large"
                placeholder="Please select an add on Level"
                onChange={this.handleAddOnLevelSelect}
                value={this.state.addOnLevel}
              >
                {addOnsOptions}
              </Select>
            </div>
            <div className="col-xl-12" style={{ paddingBottom: "10px" }}>
                <span className="enterYourEmail">Niche</span>
              </div>
            <div className="col-xl-12" style={{ paddingBottom: "10px" }}>
              
              <Select
                id="ddlNiche"
                style={{ width: "100%" }}
                size="large"
                placeholder="Please select a niche"
                onChange={this.handleNicheSelect}
                value={this.state.featuredCategory}
              >
                {nicheOptions}
              </Select>
            </div>
            <div className="col-xl-12" style={{ paddingBottom: "10px" }}>
            <span className="enterYourEmail">Four Month Subscription?</span>
            <Switch onChange={this.setFourMonthSubscriptionFlag} checkedChildren="Yes" unCheckedChildren="No"  checked={this.state.fourMonthSubscription}  />
            </div>
          </div>
        </Modal>
        <section
          id="main-section"
          style={{ minHeight: window.innerHeight - 150 }}
        >
          <div className="row" style={{ width: "100%" }}>
            <div className="col-xl-1"></div>
            <div className="col-xl-10" style={{ backgroundColor: "#f8f9fa" }}>
              <div
                className="row"
                style={{ paddingTop: "30px", paddingBottom: "30px" }}
              >
                <div className="col-xl-12">
                  <div className="row" style={{ margin: "0px" }}>
                    <form
                      onSubmit={this.handleOnSubmit}
                      style={{ width: "100%" }}
                    >
                      <div className="row" style={{paddingBottom:"20px"}}>
                      <div className="col-xl-4">
                        <span className="subscriptionLevel">Email: {this.state.email}</span>
                       
                      </div>
                      <div className="col-xl-4">
                        <span className="subscriptionLevel">Subscription Level: {this.state.level}</span>
                      </div>
                      </div>

                    
                      <div className="row">
                        <div className="col-xl-12" style={{paddingTop:"15px"}}>
                            <div  className="row">
                              <div className="col-xl-12">
                                <span className="profileFormHeadings">General Profile</span>
                              
                              </div>
                            
                            </div>
                        </div>
                        <div className="col-xl-12" style={{paddingTop:"15px"}}>
                          <div  className="row Level2Features">
                            <div className="col-xl-2">
                            <label htmlFor="ProfilePicture">Profile Picture</label>
                            <Upload
                                  name="avatar"
                                  listType="picture-card"
                                  className="avatar-uploader"
                                  showUploadList={false}
                                  beforeUpload={this.beforeUpload}
                                >
                                  {(this.state.photo && this.state.photo)!==""|| (this.state.photoLink && this.state.photoLink !=="") ? (
                                    <img
                                      src={this.state.photo?this.state.photo:this.state.photoLink}
                                      alt="avatar"
                                      style={{ width: "100%" }}
                                    />
                                  ) : (
                                    uploadButton
                                  )}
                                </Upload>
                            </div>
                            <div className="col-xl-10">
                              <div className="row">
                                <div className="col-xl-6">
                                  <label htmlFor="firstName">First Name</label>
                                  <input className="form-control" id="firstName" name="firstName" type="text" onChange={this.handleChange} value={this.state.firstName}/>
                                </div>
                                <div className="col-xl-6">
                                  <label htmlFor="lastName">Last Name</label>
                                  <input className="form-control" id="lastName" name="lastName" type="text" onChange={this.handleChange} value={this.state.lastName}/>
                                </div>
                              </div>
                              <div className="row" style={{paddingTop:"10px"}}>
                              <div className="col-xl-6">
                              <label htmlFor="inputLocation">Location</label>
                              <input
                                type="text"
                                className="form-control"
                                id="inputLocation"
                                name="location"
                                placeholder=""
                                onChange={this.handleChange}
                                value={this.state.location}
                              />
                            </div>
                              
                                <div className="col-xl-6">
                                  <label htmlFor="categoryNames">Categories</label>
                                    <Select
                                      className="form-control"
                                      id="categoryNames"
                                      mode="multiple"
                                      style={{ width: '100%' }}
                                      size="large"
                                      placeholder="Please select any three categories"
                                      onChange={this.handleCategorySelect}
                                      value={this.state.categoryNames && Array.isArray(this.state.categoryNames)?this.state.categoryNames:[] }
                                     
                                    >
                                      {categoryOptions}
                                    </Select>
                                
                                </div>
                              </div>
                            </div>
                            
                            <div className="col-xl-12" style={{paddingTop:"0px"}}>
                              <div className="row">
                            <div className="col-xl-4">
                              <label htmlFor="inputCity">City</label>
                              <input
                                type="text"
                                className="form-control"
                                id="inputCity"
                                name="city"
                                onChange={this.handleChange}
                                value={this.state.city}
                              />
                            </div>
                            <div className="col-xl-4">
                              <label htmlFor="inputState">State</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inputState"
                                  name="state"
                                  onChange={this.handleChange}
                                  value={this.state.state}
                                />
                            </div>
                            <div className="col-xl-2">
                              <label htmlFor="inputZip">Zip</label>
                              <input type="text" className="form-control" id="inputZip" name="zip" onChange={this.handleChange} value={this.state.zip}/>
                            </div>
                            <div className="col-xl-2">
                              <label htmlFor="inputPhone">Phone</label>
                              <input type="text" className="form-control" id="inputPhone" name="phone" onChange={this.handleChange} value={this.state.phone}/>
                            </div>
                          </div>
                          </div>
                            <div className="col-xl-12" style={{paddingTop:"15px"}}>
                          <div className="row">
                            <div className="col-xl-6" style={{paddingTop:"5px"}}>
                              <label htmlFor="aboutMe">About Yourself</label>
                              <textarea className="form-control" id="aboutMe" name="aboutMe" type="text" onChange={this.handleChange} value={this.state.aboutMe}/>
                            </div>
                            <div className="col-xl-6" >
                                <div className="row">
                                <div className="col-xl-4" style={{paddingTop:"5px"}}>
                                  <label style={{display:"block"}}>Free Coaching?</label>
                                  <Switch onChange={this.setIsFreeTraining} checkedChildren="Yes" unCheckedChildren="No" checked={this.state.isFreeTraining}  />
                                </div>
                            
                              
                                <div className="col-xl-4" style={{paddingTop:"5px"}}>
                                  <label style={{display:"block"}}>Group Coaching?</label>
                                  <Switch onChange={this.setIsGroupCoaching} checkedChildren="Yes" unCheckedChildren="No" checked={this.state.isGroupCoaching}  />
                                </div>
                            
                            
                                <div className="col-xl-4" style={{paddingTop:"5px"}}>
                                  <label style={{display:"block"}}>One-On-One Coaching?</label>
                                  <Switch onChange={this.setIsOneOnOne} checkedChildren="Yes" unCheckedChildren="No" checked={this.state.isOneOnOne}  />
                                </div>
                                </div>
                              </div>
                          </div>
                        </div>
                          </div>
                        </div>

                        <div className="col-xl-12" style={{paddingTop:"15px"}}>
                    <div  className="row">
                      <div className="col-xl-12">
                        <span className="profileFormHeadings">Level 3 Features</span>
                       
                      </div>
                      <div className="col-xl-12">
                      
                        <span>You can add one website link, one social media link, 3 story videos and delete unwanted comments by upgrading your plan to Level 3</span>
                        {this.authorizationProvider.userCanAddStoryVideos?
                        <span style={{float:"right", fontWeight:"500"}}>This user is subscribed to this plan <CheckCircleOutlined style={{color:"green",fontSize:"20px"}}/></span>:
                        <span style={{float:"right", fontWeight:"500"}}>This user is not subscribed to this plan <CloseCircleOutlined style={{color:"red",fontSize:"20px"}}/></span>
                        }
                      </div>
                    </div>
                 </div>
                  <div className="col-xl-12" style={{paddingTop:"15px"}}>
                    <div  className="row Level3Features">
                        
                        <div className="col-xl-2" style={{paddingTop:"5px"}}>
                        <label htmlFor="website">Website</label>
                          <input className="form-control" id="website" name="website" type="text" onChange={this.handleChange} value={this.state.website}/>
                        </div>
                        <div className="col-xl-2" style={{paddingTop:"5px"}}>
                          <label htmlFor="fbProfilePage">Social Media Account</label>
                        
                            <input className="form-control" id="fbProfilePage" name="fbProfilePage" type="text" onChange={this.handleChange} value={this.state.fbProfilePage}/>
                        
                        </div>
                        <div className="col-xl-8" style={{paddingTop:"5px"}}>
                          <div className="row">
                            <div className="col-xl-5">
                              <label htmlFor="videoLink">Level 3 Story Video Title (Up to three)</label>
                              
                                <input  className="form-control" id="yourStoryTitle" name="yourStoryTitle" type="text" onChange={this.handleChange} value={this.state.yourStoryTitle}/>
                           
                            </div>
                            <div className="col-xl-5">
                              <label htmlFor="videoLink">Level 3 Story Video Link</label>
                             
                                <input  className="form-control" id="yourStoryLink" name="yourStoryLink" type="text" onChange={this.handleChange} value={this.state.yourStoryLink} />
                        
                            </div>
                            <div className="col-xl-2" style={{paddingTop:"20px"}}>
                              <button  type="button"  className="btn btnAddTrainingVideo" onClick={this.addYourStoryVideoLink}>
                                <Icon  type="plus" style={{fontSize:"30px",color:"white",cursor:"pointer"}} ></Icon>
                              </button>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-xl-12">
                            <List
                                 dataSource={this.state.storyVideoLinks && Array.isArray(this.state.storyVideoLinks)?this.state.storyVideoLinks:[] }
                                renderItem={item => (
                                <List.Item>
                                  <span className="featuresSpan" style={{width:"45%"}}>{item.Title}</span>
                                  <span className="featuresSpan" style={{width:"45%"}}>{item.Link}</span>
                                
                                  <Icon data-title={item.Title}  data-link={item.Link} onClick={this.deleteYourStoryVideoLink} type="delete" style={{width:"10%", fontSize:"16px",color:"darkred",cursor:"pointer"}} ></Icon>
                            
                              </List.Item> 
                            )}
                            />
                            </div>
                            </div>
                
                          </div>
                       
                    </div>
                  </div>
         
                  <div className="col-xl-12" style={{paddingTop:"15px"}}>
                    <div  className="row">
                      <div className="col-xl-12">
                        <span className="profileFormHeadings">Level 4 Features</span>
                       
                      </div>
                      <div className="col-xl-12">
                      
                        <span>You can add up to 5 training videos to the carousel by upgrading your plan to Level 4</span>
                        {this.authorizationProvider.userCanAddTrainingVideos?
                        <span style={{float:"right", fontWeight:"500"}}>This user is subscribed to this plan <CheckCircleOutlined style={{color:"green",fontSize:"20px"}}/></span>:
                        <span style={{float:"right", fontWeight:"500"}}>This user is not subscribed to this plan <CloseCircleOutlined style={{color:"red",fontSize:"20px"}}/></span>
                        }
                      </div>
                    </div>
                 </div>

                  <div className="col-xl-12" style={{paddingTop:"15px"}}>
                      <div  className="row Level4Features">
                      <div className="col-xl-4">
                        <label htmlFor="website"><StarOutlined style={{color:"green" ,fontSize:"20px",paddingRight:"5px"}}/>Level 4 Training Video Carousel (Up to five) </label>
                        <label htmlFor="website"><StarOutlined style={{color:"green" ,fontSize:"20px",paddingRight:"5px"}}/>First link uploaded will appear last in the carousel </label>
                      </div>
                      <div className="col-xl-8">
                        
                      <div className="row">
                        <div className="col-xl-10">
                       
                            <input  className="form-control" id="trainingVideoLink" name="trainingVideoLink" type="text" onChange={this.handleChange} value={this.state.trainingVideoLink}/>
                           
                        </div>
                        <div className="col-xl-2"> 
                          <button type="button"  className="btn btnAddTrainingVideo" onClick={this.addTrainingVideoLink}>
                            <Icon  type="plus" style={{fontSize:"30px",color:"white",cursor:"pointer"}} ></Icon>
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-12">
                          <List
                            dataSource={this.state.trainingVideoLinks && Array.isArray(this.state.trainingVideoLinks)?this.state.trainingVideoLinks:[] }
                            renderItem={item => (
                            <List.Item>
                              <span className="featuresSpan">{item}</span>
                              <Icon data-link={item} onClick={this.deleteTrainingVideoLink} type="delete" style={{fontSize:"16px",color:"darkred",cursor:"pointer"}} ></Icon>
                            </List.Item> 
                            )}
                            />
                        </div>
                      </div>
                    </div>
                  
                  </div>   

                </div>
                
                
                  
        
                     </div>
                  
       
                  
                      <div className="row" style={{ paddingTop: "10px" }}>
                        <div className="col-xl-12">
                          <button type="submit" className="btn btnSaveProfile">
                            Save
                          </button>
                      
                        </div>
                      </div>
              
                    </form>
                    {/* <form  onSubmit={this.ChangePassword}>
                    <div className="row">
                        <div className="col-xl-12" style={{paddingTop:"15px"}}>
                            <div  className="row">
                              <div className="col-xl-12">
                                <span className="profileFormHeadings">Change Password</span>
                              
                              </div>
                            
                            </div>
                        </div>
                        <div className="col-xl-12" style={{paddingTop:"15px"}}>
                          <div  className="row ChangePassword">
                         
                                <div className="col-xl-3">
                                    <span className="propertyFormLable"> Old Password  <span className="required-text">*</span></span>
                                    <div>
                                        <input type="password" name="oldPassword" className="form-control propertyFormControl" required="required"  value={this.state.oldPassword} onChange={this.handleChange} />
                                    </div>
                                </div> 
                           
                                <div className="col-xl-3">
                                    <span className="propertyFormLable">  New Password  <span className="required-text">*</span></span>
                                    <div>
                                    <input type="password" name="newPassword" className="form-control propertyFormControl" required="required" value={this.state.newPassword} onChange={this.handleChange} />
                                    </div>
                                </div>
                           
                                <div className="col-xl-3">
                                    <span className="propertyFormLable">  Confirm New Password  <span className="required-text">*</span></span>
                                    <div>
                                    <input type="password" name="confirmNewPassword" className="form-control propertyFormControl" required="required" value={this.state.confirmNewPassword} onChange={this.handleChange} />
                                    </div>
                                </div> 
                            
                               
                                <div className="col-xl-3" style={{ textAlign: "right" }}>
                                    <button type="submit" disabled={this.state.disableButton} className="btn btnSaveProfile">APPLY </button>
                                </div>
                              
                          </div>
                        </div>
                      </div>
                      
                    </form>
                  */}
                  </div>
                </div>
              </div>
              <div
                className="row"
               
              >
                <div className="col-xl-12"> 
                        <span className="spanAdminSettings">Admin Settings</span>
                </div>
              </div>
              <div
                className="row"
                style={{ paddingTop: "30px", paddingBottom: "30px" }}
              >
                <div className="col-xl-6">
                  
                  <List
                      dataSource={this.state.adminActions}
                      renderItem={item => (
                      <List.Item>
                        <span className="featuresSpan" style={{width:"45%"}}>{item.action}</span>
                      
                        {item.actionId==1&&

                          <Popconfirm
                          title="Are you sure delete this profile?"
                          onConfirm={this.submitDeleteUser}

                          okText="Yes"
                          cancelText="No"
                          >
                            <DeleteOutlined   style={{width:"10%", fontSize:"16px",color:"darkred",cursor:"pointer"}}/>

                          </Popconfirm>
                         
                        }
                        {item.actionId==2&&
                          <ColumnWidthOutlined data-title={item.Title}  data-actionid={item.actionId} onClick={this.handleAdminAction}  style={{width:"10%", fontSize:"16px",color:"darkred",cursor:"pointer"}}/>
                        }
                        {item.actionId==3&&

                            <HighlightOutlined data-title={item.Title}  data-actionid={item.actionId} onClick={this.handleAdminAction}  style={{width:"10%", fontSize:"16px",color:"darkred",cursor:"pointer"}}/>
                        }
                         {item.actionId==4&&

                          <RiseOutlined data-title={item.Title}  data-actionid={item.actionId} onClick={this.handleAdminAction}  style={{width:"10%", fontSize:"16px",color:"darkred",cursor:"pointer"}}/>


                          }
                    {/* <Icon data-title={item.Title}  data-action={item.action} onClick={this.handleAdminAction} type={item.actionIcon} style={{width:"10%", fontSize:"16px",color:"darkred",cursor:"pointer"}} ></Icon> */}
                        
   
                    </List.Item> 
                  )}
                  />
                  
                  {/* <button
                    type="button"
                    onClick={this.openModalPopupToLinkProfile}
                    className="btn btnLinkThisProfile"
                  >
                    Link This Profile
                  </button> */}
                </div>
              </div>
            </div>
            <div className="col-xl-1"></div>
          </div>
        </section>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
