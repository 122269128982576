import { NEW_USER_SIGNED_UP,
  USER_AUTHENTICATION_INNITIATED,
  USER_INFO_RECEIVED,
  USER_TYPE_SELECTED,
  USER_SIGNUP_INITIATED,
  USER_SIGNED_UP_SUCCESS, 
  USER_SIGNED_UP_FAILURE,
  VERIFICATION_CODE_SUBMITED,
  VERIFICATION_CODE_SUCCESS,
  VERIFICATION_CODE_FAILURE,
  USER_SIGNIN_INITIATED,
  USER_SIGNED_IN_SUCCESS,
  USER_SIGNED_IN_FAILURE,
  USER_SIGNOUT, 
  GET_USER_PROFILE_INITIATED,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_RESET,
  UPDATE_USER_PROFILE_INITIATED,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
  UPDATE_USER_PROFILE_RESET,

  UPDATE_USER_TYPE_INITIATED,
  UPDATE_USER_TYPE_SUCCESS,
  UPDATE_USER_TYPE_FAILURE,
  UPDATE_USER_TYPE_RESET,

  GET_SEARCH_RESULTS_INITIATED,
  GET_SEARCH_RESULTS_SUCCESS,
  GET_SEARCH_RESULTS_FAILURE,
  GET_SEARCH_RESULTS_RESET,
  GET_PROFILE_BY_ID_INITIATED,
  GET_PROFILE_BY_ID_SUCCESS,
  GET_PROFILE_BY_ID_FAILURE,
  GET_PROFILE_BY_ID_RESET,
  GET_SEARCH_RESULTS_BY_CATEGORY_INITIATED,
  GET_SEARCH_RESULTS_BY_CATEGORY_SUCCESS,
  GET_SEARCH_RESULTS_BY_CATEGORY_FAILURE,
  GET_SEARCH_RESULTS_BY_CATEGORY_RESET,

  GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_INITIATED,
  GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_SUCCESS,
  GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_FAILURE,
  GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_RESET,
  
  GET_COMMENTS_BY_ID_INITIATED,
  GET_COMMENTS_BY_ID_SUCCESS,
  GET_COMMENTS_BY_ID_FAILURE,
  GET_COMMENTS_BY_ID_RESET,
  GET_FEATURED_COACHES_INITIATED,
  GET_FEATURED_COACHES_SUCCESS,
  GET_FEATURED_COACHES_FAILURE,
  GET_FEATURED_COACHES_RESET,

  POST_COMMENT_INITIATED,
  POST_COMMENT_SUCCESS,
  POST_COMMENT_FAILURE,
  POST_COMMENT_RESET,

  DELETE_COMMENT_INITIATED,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAILURE,
  DELETE_COMMENT_RESET,

  GET_USER_COMMENTS_INITIATED,
  GET_USER_COMMENTS_SUCCESS,
  GET_USER_COMMENTS_FAILURE,
  GET_USER_COMMENTS_RESET,

  CONTACT_US_INITIATED,
  CONTACT_US_SUCCESS,
  CONTACT_US_FAILURE,
  CONTACT_US_RESET,

  CONTACT_ME_DIRECTLY_INITIATED,
  CONTACT_ME_DIRECTLY_SUCCESS,
  CONTACT_ME_DIRECTLY_FAILURE,
  CONTACT_ME_DIRECTLY_RESET,

  GET_BANNER_TYPE_INITIATED,
  GET_BANNER_TYPE_SUCCESS,
  GET_BANNER_TYPE_FAILURE,
  GET_BANNER_TYPE_RESET,

  SET_BANNER_TYPE_INITIATED,
  SET_BANNER_TYPE_SUCCESS,
  SET_BANNER_TYPE_FAILURE,
  SET_BANNER_TYPE_RESET,

  UPDATE_COACH_PROFILE_BY_ADMIN_INITIATED,
  UPDATE_COACH_PROFILE_BY_ADMIN_SUCCESS,
  UPDATE_COACH_PROFILE_BY_ADMIN_FAILURE,
  UPDATE_COACH_PROFILE_BY_ADMIN_RESET,

  GET_CATEGORIES_BY_PLAN_TYPE_INITIATED,
  GET_CATEGORIES_BY_PLAN_TYPE_SUCCESS,
  GET_CATEGORIES_BY_PLAN_TYPE_FAILURE,
  GET_CATEGORIES_BY_PLAN_TYPE_RESET,
  
  SUBSCRIPTION_REQUEST_INITIATED,
  SUBSCRIPTION_REQUEST_SUCCESS,
  SUBSCRIPTION_REQUEST_FAILURE,
  SUBSCRIPTION_REQUEST_RESET,

  LINK_PROFILE_WITH_USER_INITIATED,
  LINK_PROFILE_WITH_USER_SUCCESS,
  LINK_PROFILE_WITH_USER_FAILURE,
  LINK_PROFILE_WITH_USER_RESET,
  SET_NAVBAR_CUSTOM_CSS
} from '../constants/constants';

export const addNewUser = (newUser) => ({
  type: NEW_USER_SIGNED_UP,
  payload: newUser
})  

export const authenticateUser = (payload) => ({
  type: USER_AUTHENTICATION_INNITIATED,
  payload: payload
})  
  
export const setUserInfo = (userInfo) => ({
  type: USER_INFO_RECEIVED,
  payload: userInfo
})  

export const selectUserType = (text) => ({
  type: USER_TYPE_SELECTED,
  payload: text
})

export const userSignUpInitiated = (email, password) => ({
  type: USER_SIGNUP_INITIATED,
  payload: {email, password}
})

export const userSignUpSuccess = (payload) => ({
  type: USER_SIGNED_UP_SUCCESS,
  payload: payload
})

export const userSignUpFailure = (payload) => ({
  type: USER_SIGNED_UP_FAILURE,
  payload: payload
})

export const verifyCode = (code, email) => ({
  type: VERIFICATION_CODE_SUBMITED,
  payload: {code, email}
})

export const verifyCodeSuccess = (payload) => ({
  type: VERIFICATION_CODE_SUCCESS,
  payload: payload
})

export const verifyCodeFailure = (payload) => ({
  type: VERIFICATION_CODE_FAILURE,
  payload: payload
})

export const userSignInInitiated = (email, password) => ({
  type: USER_SIGNIN_INITIATED,
  payload: {email, password}
})

export const userSignInSuccess = (payload) => ({
  type: USER_SIGNED_IN_SUCCESS,
  payload: payload
})

export const userSignInFailure = (payload) => ({
  type: USER_SIGNED_IN_FAILURE,
  payload: payload
})

export const userSignedOut = (payload) => ({
  type: USER_SIGNOUT,
  payload
})

export const getUserProfileInitiated = (payload) => ({
  type: GET_USER_PROFILE_INITIATED,
  payload
})

export const getUserProfileSuccess = (payload) => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload
})

export const getUserProfileFailure = (payload) => ({
  type: GET_USER_PROFILE_FAILURE,
  payload
})
export const getUserProfileReset = (payload) => ({
  type: GET_USER_PROFILE_RESET,
  payload
})

export const updateUserProfileInitiated = (payload) => ({
  type: UPDATE_USER_PROFILE_INITIATED,
  payload
})

export const updateUserProfileSuccess = (payload) => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  payload
})

export const updateUserProfileFailure = (payload) => ({
  type: UPDATE_USER_PROFILE_FAILURE,
  payload
})
export const updateUserProfileReset = (payload) => ({
  type: UPDATE_USER_PROFILE_RESET,
  payload
})

export const updateUserTypeInitiated = (payload) => ({
  type: UPDATE_USER_TYPE_INITIATED,
  payload
})

export const updateUserTypeSuccess = (payload) => ({
  type: UPDATE_USER_TYPE_SUCCESS,
  payload
})

export const updateUserTypeFailure = (payload) => ({
  type: UPDATE_USER_TYPE_FAILURE,
  payload
})
export const updateUserTypeReset = (payload) => ({
  type: UPDATE_USER_TYPE_RESET,
  payload
})

export const getSearchResultsInitiated = (payload) => ({
  type: GET_SEARCH_RESULTS_INITIATED,
  payload
})

export const getSearchResultsSuccess = (payload) => ({
  type: GET_SEARCH_RESULTS_SUCCESS,
  payload
})

export const getSearchResultsFailure = (payload) => ({
  type: GET_SEARCH_RESULTS_FAILURE,
  payload
})
export const getSearchResultsReset = (payload) => ({
  type: GET_SEARCH_RESULTS_RESET,
  payload
})

export const getSearchResultsByCategoryInitiated = (payload) => ({
  type: GET_SEARCH_RESULTS_BY_CATEGORY_INITIATED,
  payload
})

export const getSearchResultsByCategorySuccess = (payload) => ({
  type: GET_SEARCH_RESULTS_BY_CATEGORY_SUCCESS,
  payload
})

export const getSearchResultsByCategoryFailure = (payload) => ({
  type: GET_SEARCH_RESULTS_BY_CATEGORY_FAILURE,
  payload
})

export const getSearchResultsByCategoryReset = (payload) => ({
  type: GET_SEARCH_RESULTS_BY_CATEGORY_RESET,
  payload
})

export const getSearchResultsByCategoryFeaturedUsersInitiated = (payload) => ({
  type: GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_INITIATED,
  payload
})

export const getSearchResultsByCategoryFeaturedUsersSuccess = (payload) => ({
  type: GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_SUCCESS,
  payload
})

export const getSearchResultsByCategoryFeaturedUsersFailure = (payload) => ({
  type: GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_FAILURE,
  payload
})

export const getSearchResultsByCategoryFeaturedUsersReset = (payload) => ({
  type: GET_SEARCH_RESULTS_BY_CATEGORY_FEATURED_USERS_RESET,
  payload
})

export const getProfileByIdInitiated = (payload) => ({
  type: GET_PROFILE_BY_ID_INITIATED,
  payload
})

export const getProfileByIdSuccess = (payload) => ({
  type: GET_PROFILE_BY_ID_SUCCESS,
  payload
})

export const getProfileByIdFailure = (payload) => ({
  type: GET_PROFILE_BY_ID_FAILURE,
  payload
})
export const getProfileByIdReset = (payload) => ({
  type: GET_PROFILE_BY_ID_RESET,
  payload
})

export const getCommentsByIdInitiated = (payload) => ({
  type: GET_COMMENTS_BY_ID_INITIATED,
  payload
})

export const getCommentsByIdSuccess = (payload) => ({
  type: GET_COMMENTS_BY_ID_SUCCESS,
  payload
})

export const getCommentsByIdFailure = (payload) => ({
  type: GET_COMMENTS_BY_ID_FAILURE,
  payload
})
export const getCommentsByIdReset = (payload) => ({
  type: GET_COMMENTS_BY_ID_RESET,
  payload
})
export const getFeaturedCoachesInitiated = (payload) => ({
  type: GET_FEATURED_COACHES_INITIATED,
  payload
})

export const getFeaturedCoachesSuccess = (payload) => ({
  type: GET_FEATURED_COACHES_SUCCESS,
  payload
})

export const getFeaturedCoachesFailure = (payload) => ({
  type: GET_FEATURED_COACHES_FAILURE,
  payload
})
export const getFeaturedCoachesReset = (payload) => ({
  type: GET_FEATURED_COACHES_RESET,
  payload
})

export const postCommentInitiated = (payload) => ({
  type: POST_COMMENT_INITIATED,
  payload
})

export const postCommentSuccess = (payload) => ({
  type: POST_COMMENT_SUCCESS,
  payload
})

export const postCommentFailure = (payload) => ({
  type: POST_COMMENT_FAILURE,
  payload
})
export const postCommentReset = (payload) => ({
  type: POST_COMMENT_RESET,
  payload
})

export const deleteCommentInitiated = (payload) => ({
  type: DELETE_COMMENT_INITIATED,
  payload
})

export const deleteCommentSuccess = (payload) => ({
  type: DELETE_COMMENT_SUCCESS,
  payload
})

export const deleteCommentFailure = (payload) => ({
  type: DELETE_COMMENT_FAILURE,
  payload
})
export const deleteCommentReset = (payload) => ({
  type: DELETE_COMMENT_RESET,
  payload
})

export const getUserCommentsInitiated = (payload) => ({
  type: GET_USER_COMMENTS_INITIATED,
  payload
})

export const getUserCommentsSuccess = (payload) => ({
  type: GET_USER_COMMENTS_SUCCESS,
  payload
})

export const getUserCommentsFailure = (payload) => ({
  type: GET_USER_COMMENTS_FAILURE,
  payload
})

export const getUserCommentsReset = (payload) => ({
  type: GET_USER_COMMENTS_RESET,
  payload
})

export const postMessageInitiated = (payload) => ({
  type: CONTACT_US_INITIATED,
  payload
})

export const postMessageSuccess = (payload) => ({
  type: CONTACT_US_SUCCESS,
  payload
})

export const postMessageFailure = (payload) => ({
  type: CONTACT_US_FAILURE,
  payload
})
export const postMessageReset = (payload) => ({
  type: CONTACT_US_RESET,
  payload
})

export const postMessageToCoachInitiated = (payload) => ({
  type: CONTACT_ME_DIRECTLY_INITIATED,
  payload
})

export const postMessageToCoachSuccess = (payload) => ({
  type: CONTACT_ME_DIRECTLY_SUCCESS,
  payload
})

export const postMessageToCoachFailure = (payload) => ({
  type: CONTACT_ME_DIRECTLY_FAILURE,
  payload
})

export const postMessageToCoachReset = (payload) => ({
  type: CONTACT_ME_DIRECTLY_RESET,
  payload
})

export const getBannerTypeInitiated = (payload) => ({
  type: GET_BANNER_TYPE_INITIATED,
  payload
})

export const getBannerTypeSuccess = (payload) => ({
  type: GET_BANNER_TYPE_SUCCESS,
  payload
})

export const getBannerTypeFailure = (payload) => ({
  type: GET_BANNER_TYPE_FAILURE,
  payload
})
export const getBannerTypeReset = (payload) => ({
  type: GET_BANNER_TYPE_RESET,
  payload
})

export const setBannerTypeInitiated = (payload) => ({
  type: SET_BANNER_TYPE_INITIATED,
  payload
})

export const setBannerTypeSuccess = (payload) => ({
  type: SET_BANNER_TYPE_SUCCESS,
  payload
})

export const setBannerTypeFailure = (payload) => ({
  type: SET_BANNER_TYPE_FAILURE,
  payload
})
export const setBannerTypeReset = (payload) => ({
  type: SET_BANNER_TYPE_RESET,
  payload
})

export const updateCoachProfileByAdminInitiated = (payload) => ({
  type: UPDATE_COACH_PROFILE_BY_ADMIN_INITIATED,
  payload
})

export const updateCoachProfileByAdminSuccess = (payload) => ({
  type: UPDATE_COACH_PROFILE_BY_ADMIN_SUCCESS,
  payload
})

export const updateCoachProfileByAdminFailure = (payload) => ({
  type: UPDATE_COACH_PROFILE_BY_ADMIN_FAILURE,
  payload
})
export const updateCoachProfileByAdminReset = (payload) => ({
  type: UPDATE_COACH_PROFILE_BY_ADMIN_RESET,
  payload
})

export const getCategoriesByPlanTypeInitiated = (payload) => ({
  type: GET_CATEGORIES_BY_PLAN_TYPE_INITIATED,
  payload
})

export const getCategoriesByPlanTypeSuccess = (payload) => ({
  type: GET_CATEGORIES_BY_PLAN_TYPE_SUCCESS,
  payload
})

export const getCategoriesByPlanTypeFailure = (payload) => ({
  type: GET_CATEGORIES_BY_PLAN_TYPE_FAILURE,
  payload
})
export const getCategoriesByPlanTypeReset = (payload) => ({
  type: GET_CATEGORIES_BY_PLAN_TYPE_RESET,
  payload
})

export const subscriptionRequestInitiated = (payload) => ({
  type: SUBSCRIPTION_REQUEST_INITIATED,
  payload
})

export const subscriptionRequestSuccess = (payload) => ({
  type: SUBSCRIPTION_REQUEST_SUCCESS,
  payload
})

export const subscriptionRequestFailure = (payload) => ({
  type: SUBSCRIPTION_REQUEST_FAILURE,
  payload
})
export const subscriptionRequestReset = (payload) => ({
  type: SUBSCRIPTION_REQUEST_RESET,
  payload
})

export const linkProfileWithUserInitiated = (payload) => ({
  type: LINK_PROFILE_WITH_USER_INITIATED,
  payload
})

export const linkProfileWithUserSuccess = (payload) => ({
  type: LINK_PROFILE_WITH_USER_SUCCESS,
  payload
})

export const linkProfileWithUserFailure = (payload) => ({
  type: LINK_PROFILE_WITH_USER_FAILURE,
  payload
})
export const linkProfileWithUserReset = (payload) => ({
  type: LINK_PROFILE_WITH_USER_RESET,
  payload
})



export const setNavbarCustomCss = (payload) => ({
  type: SET_NAVBAR_CUSTOM_CSS,
  payload
})
