export const messageToAddWebsiteLink = 'Upgrade to Level3 to add website link';
export const messageToAddFacebookLink = 'Upgrade to Level3 to add facebook link';
export const messageToAddLinkedInLink = 'Upgrade to Level3 to add linkedin link';
export const messageToAddFacebookBusinessLink = 'Upgrade to Level3 to add facebook business link';
export const messageToAddStoryVideoLink = 'Upgrade to Level3 to add upto 3 story videos';
export const messageToAddTrainingVideoLink = 'Upgrade to Level4 to add upto 5 training videos';
export const messageToReceiveEmails = 'Upgrade to Level5 to to receive email directly';

 export const baseAddress='https://authorityalert.online'
// export const cfLinkToUpgrade = 'https://authorityalert.online/plans-and-packages';
// export const cfLinkToUpgradeLevel3 = 'https://authorityalert.online/level-3-cr-upgrade-secure-order-form';
// export const cfLinkToUpgradeLevel4 = 'https://authorityalert.online/level-4-cr-secure-order-form';
// export const cfLinkToUpgradeLevel5 = 'https://authorityalert.online/level-5-cr-secure-order-form';


// export const cfLinkToUpgradeLevel6 = 'https://authorityalert.online/level-6-upgrade-secure-order-form-premium';
// export const cfLinkToUpgradeLevel6Premium = 'https://authorityalert.online/level-6-upgrade-secure-order-form-premium';
// export const cfLinkToUpgradeLevel6Alt = 'https://authorityalert.online/level-6-alternative-upgrade-secure-order-form';
// export const cfLinkToUpgradeLevel6Standard = 'https://authorityalert.online/level-6-upgrade-secure-order-form-standard';

export const cfLinkToUpgrade = 'https://authorityalert.online/plans-and-packages-page';
export const cfLinkToUpgradeLevel3 = 'https://authorityalert.online/level-3-cr-upgrade-secure-order-form-pg';
export const cfLinkToUpgradeLevel4 = 'https://authorityalert.online/level-4-cr-secure-order-form-page';
export const cfLinkToUpgradeLevel5 = 'https://authorityalert.online/level-5-cr-secure-order-form-page';
export const cfLinkToUpgradeLevel6 = 'https://authorityalert.online/level-6-upgrade-secure-order-form-premium-pagev';
export const cfLinkToUpgradeLevel6Premium = 'https://authorityalert.online/level-6-upgrade-secure-order-form-premium-pagev';
export const cfLinkToUpgradeLevel6Alt = 'https://authorityalert.online/level-6-alternative-upgrade-secure-order-form-page';
export const cfLinkToUpgradeLevel6Standard = 'https://authorityalert.online/level-6-upgrade-secure-order-form-standard-page';