import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { connect } from 'react-redux';
import { Auth, Hub } from 'aws-amplify';

import React from 'react';
import { setUserInfo, getUserProfileInitiated, getBannerTypeInitiated } from '../actions/actions';

import Main from './Main';
import Navbar from '../components/Navbar';
import FootRibbon from '../components/FootRibbon';
import api from "../api/index";
import './App.css';

import { Switch, Route } from 'react-router-dom';
import Home from './Home';
import SignUp from './SignUp';
import SignIn from './SignIn';
import Verify from './Verify';
import Thanks from './Thanks';
import ProfileForm from './ProfileForm';
import PlansUpgrade from './PlansUpgrade';
import CoachCard from '../components/CoachCard';
import PlanSelector from './PlansSelector';
import Checkout from './CheckoutForm';
import UserProfile from './UserProfile';
import SearchPage from './SearchPage';
import CoachProfile from './CoachProfile';
import CategorySearchPage from './CategorySearchPage';
import UpgradeSuccess from './UpgradeSuccess';
import UpgradeFail from './UpgradeFail';
import SelectAccountType from './SelectAccountType';
import Thankyou from './Thankyou'
import VerifyEmail from './VerifyEmail';
import AdminPanel from './AdminPanel';
import ProfileFormAdmin from './ProfileFormAdmin';
import ClaimProfile from './ClaimProfile';
import Subscription from './Subscription';
import NoMatchPage from './NoMatchPage';




const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUserAuthentication: (userInfo) => dispatch(setUserInfo(userInfo)),
    getUserProfile: () => dispatch(getUserProfileInitiated()),
    getBannerType: () => dispatch(getBannerTypeInitiated())
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMounted:false,
      userLoggedIn:false,
    };
  }
  async componentDidMount() {

    console.log("app did mounted");
    let res=await api.getAllCategories_GroupedInMainCategory();
    this.setState({navItems:res.data});
    var self=this;
    //alert("componentDidMount")
    // Hub.listen('auth', (data) => {
    //   //alert("tttt")
    //   console.log("app")
    //   console.log(data);
    //   const { payload } = data
    //   if(payload.event=="cognitoHostedUI"){
    //     this.checkUser();
    //   }
    // });
    Hub.listen("auth", ({ payload: { event, data } }) => {
     
      console.log("app");
      console.log(data);
      console.log(event);
      switch (event) {
 
        case "customOAuthState":
        if(data=="signup"){
          console.log("user is signed up")
        }
        break;
        case "cognitoHostedUI":
            self.checkUser();
            document.location.href = '/'

        }
    });
    this.props.getBannerType();
    this.checkUser();
  }
  componentDidUpdate(prevProps,prevState){
    if(prevProps!==this.props ){
      if (this.props.user.getUserProfileOperationCompleted){

        if(this.props.user.getUserProfileOperationStatus=="Completed_Success")
          {
           
          this.setState({isMounted:true,userLoggedIn:true, errorOnGettingProfile:false});
          }
          else if(this.props.user.getUserProfileOperationStatus=="Completed_Failed")
          {
          
            this.setState({isMounted:true,userLoggedIn:true, errorOnGettingProfile:true});
          }
      }
    }
  }
  checkUser = async () => {
    console.log("check if user is logged in");
    try {
     //const currentSession = await Auth.currentAuthenticatedUser({bypassCache:true}) 
      //const currentSession = await Auth.currentAuthenticatedUser() 
      const currentSession =  await Auth.currentSession();
      if (currentSession) { 
        console.log("YES user is logged in");
        this.props.onUserAuthentication(currentSession);
        this.props.getUserProfile();
      }
    } catch (e) {
     
      console.log("error current session is null start")
      this.setState({isMounted:true,userLoggedIn:false});
       console.log(e);
       console.log("error current session is null end")
    }
  }
  render() {
    const { userLoggedIn } = this.state;
    
    return (
      this.state.isMounted &&
      <div className="container-fluid" style={{padding:"0px"}}>
         <div className="row">
            <div className="col-xl-1"></div>
            <div className="col-xl-10">
              <Navbar navItems={this.state.navItems}></Navbar>
            </div>
            <div className="col-xl-1"></div>
          </div>
        <div className="row">
        <Switch>
          <Route
            exact
            path="/"
            render={props => {
              return (
                <Home {...props} navItems={this.state.navItems}/>
              );
            }}
          />
     
          <Route path='/signup' 
               render={props => {
                if (userLoggedIn) return <Home  {...props} navItems={this.state.navItems}/>;
                else return <SignUp  {...props}/>;
              }}
          />
          <Route path='/signin' 
           render={props => {
           if (userLoggedIn)  return <Home  {...props} navItems={this.state.navItems}/>;
            else return <SignIn  {...props}/>;
          }}
          />
          <Route path='/verify' component={Verify}/>
          <Route path='/claimprofile' component={ClaimProfile}/>
          <Route path='/profileForm' component={ProfileForm}/>
          
          <Route path='/profile'
            render={props => {
              if (userLoggedIn) return <UserProfile  {...props}/>;
              else return <SignIn  {...props}/>;
            }}
          />
          <Route path="/thanks" component={Thanks}/>

          <Route path="/plansupgrade/:level"
           render={props => {
            return <PlansUpgrade  {...props}/>;
           
          }}
          />
          <Route path="/subscription/:level"
           render={props => {
            return <Subscription  {...props}/>;
           
          }}
          />
          <Route path="/plans"
           render={props => {
            if (userLoggedIn) return <PlanSelector  {...props}/>;
            else return <SignIn  {...props}/>;
          }}
          />
          <Route path='/checkout' component={Checkout}/>
          <Route path='/success' component={UpgradeSuccess}/>
          <Route path='/fail' component={UpgradeFail}/>
          <Route path='/search/:keyword' component={SearchPage}/>
          <Route path='/Thankyou/:keyword' component={Thankyou}/>
          
          <Route path='/coach/:id/edit'
           render={props => {
            if (userLoggedIn) return <ProfileFormAdmin  {...props}/>;
            else return <SignIn  {...props}/>;
          }}
           />
          <Route path='/coach/:name/:id' component={CoachProfile} />
          {/* <Route path='/category/:category/:keyword' component={CategorySearchPage}/> */}
          <Route path="/category/:category"
           render={props => {
            return <CategorySearchPage  {...props} navItems={this.state.navItems} />;
           
          }}
          />
          {/* <Route path='/category/:category' component={CategorySearchPage}/> */}
          <Route path='/account-type'
            render={props => {
              if (userLoggedIn) return <SelectAccountType  {...props}/>;
              else return <SignIn  {...props}/>;
            }}
            />
          <Route path='/verifyemail' component={VerifyEmail}/>
          <Route path='/adminpanel'
            render={props => {
              if (userLoggedIn) return <AdminPanel  {...props}/>;
              else return <SignIn  {...props}/>;
            }}
           />
          <Route component={NoMatchPage} />
        </Switch>
        

       
        </div>
        <div className="row footerRow">
        <div className="col-xl-1" ></div>
        <div className="col-xl-10" >
          <div className="row">

            <div className="col-xl-4" >
            <a className="footerSeparatorLinks" href="https://authorityalert.online/terms" target="_blank">Terms of Service  </a>
            <span style={{paddingLeft:"2px", paddingRight:"2px"}} className="FooterSeparator"> | </span>
            <a className="footerSeparatorLinks" href="https://authorityalert.online/privacy" target="_blank"> Privacy Policy  </a>
              <span style={{paddingLeft:"2px", paddingRight:"2px"}} className="FooterSeparator"> | </span>
            <a className="footerSeparatorLinks" href="https://authorityalert.online/earnings-disclaimer" target="_blank"> Earnings Disclaimer</a> 

            </div>
            <div className="col-xl-8">
              <span className="footerAddressSpan">
              AuthorityAlert Copyright 2021 - All Rights Reserved. Use of site constitutes agreement to terms. 1910 Thomes Ave. Cheyenne, WY 82001
              </span>
            </div>

          </div>
     
        </div>
        <div className="col-xl-1" ></div>
     
         
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
