import { takeEvery, put, call, all, fork } from 'redux-saga/effects';
import { GET_USER_PROFILE_INITIATED, UPDATE_USER_PROFILE_INITIATED,UPDATE_USER_TYPE_INITIATED } from '../constants/constants';
import api from '../api';
import { getUserProfileSuccess,
          getUserProfileFailure,
          getUserProfileReset,
          updateUserProfileSuccess,
          updateUserProfileFailure,
          updateUserProfileReset,
          updateUserTypeSuccess,
          updateUserTypeFailure,
          updateUserTypeReset

     } from '../actions/actions';

function* handleGetUserProfileInitiate(action) {
  try {
    const res = yield call(api.getUserProfile, action.payload);
    yield put(getUserProfileSuccess(res.data));
    console.log("user info received");
    console.log(res.data);
    yield put(getUserProfileReset(null));
  } catch (error) {
   
    console.log(error)
    yield put(getUserProfileFailure(error));
    yield put(getUserProfileReset(null));
  }
}

function* handleUpdateUserProfileInitiated(action) {
  try {
    const res = yield call(api.updateUserProfile, action.payload);
    yield put(updateUserProfileSuccess(res));
    yield put(updateUserProfileReset(null));
  } catch (error) {
    console.log(error);
    yield put(updateUserProfileFailure(error));
    yield put(updateUserProfileReset(null));
  }
}

function* handleUpdateUserTypeInitiated(action) {
  try {
    const res = yield call(api.updateUserType, action.payload);
    yield put(updateUserTypeSuccess(res));
    yield put(updateUserTypeReset(null));
  } catch (error) {
    console.log(error);
    yield put(updateUserTypeFailure(error));
    yield put(updateUserTypeReset(null));
  }
}
function* watchGetUserProfileInitiated() {
  yield takeEvery(GET_USER_PROFILE_INITIATED, handleGetUserProfileInitiate)
}

function* watchUpdateUserProfileInitiated() {
  yield takeEvery(UPDATE_USER_PROFILE_INITIATED, handleUpdateUserProfileInitiated)
}

function* watchUpdateUserTypeInitiated() {
  yield takeEvery(UPDATE_USER_TYPE_INITIATED, handleUpdateUserTypeInitiated)
}
export default function* userSagas() {
  yield all([fork(watchGetUserProfileInitiated),fork(watchUpdateUserProfileInitiated),fork(watchUpdateUserTypeInitiated)])
}