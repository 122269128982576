import React from 'react';
import { connect } from 'react-redux';
import { verifyCode } from '../actions/actions';

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (code, email) => dispatch(verifyCode(code, email))
  }
}

class Verify extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      code: ''
    }
  }

  componentDidMount() {
    this.setState({email: this.props.oauth.email});
  }

  componentDidUpdate() {
    console.log(this.props);
    if (this.props.oauth.email && this.props.oauth.code_verified) {
      this.props.history.push('/test');
    }
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  }

  handleCodeChange = (e) => {
    this.setState({ code: e.target.value });
  }

  onSubmit = ($event) => {
    $event.preventDefault();
    console.log(this.state.code, this.props.oauth.email);
    this.props.onSubmit(this.state.code, this.state.email);
  }

  render() {
    return (
      <div className="container">
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "90vh" }}>
          <form onSubmit={this.onSubmit}>
            <h5 className="roboto ">Enter the verification code sent to your email</h5>
            <div class="form-group">
              <label htmlFor="email">Email</label>
              <input type="text" className="form-control"
                id="email"
                value={this.state.email}
                onChange={this.handleEmailChange}
              />
              <label htmlFor="code">Code</label>
              <input type="text" className="form-control"
                id="inputCode"
                value={this.state.code}
                onChange={this.handleCodeChange}
              />
            </div>
            <button type="submit" class="btn btn-primary">Verify</button>
          </form>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Verify);