import { takeEvery, put, call, all, fork } from 'redux-saga/effects';
import { CONTACT_US_INITIATED, CONTACT_ME_DIRECTLY_INITIATED } from '../constants/constants';
import api from '../api';
import { postMessageSuccess,postMessageFailure, postMessageReset,  postMessageToCoachSuccess, postMessageToCoachFailure,postMessageToCoachReset } from '../actions/actions';

function* handleContactUsInitiated(action) {
  try {
    const res = yield call(api.postMessageToAdmin, action.payload);
    //console.log(res);
    yield put(postMessageSuccess(res.data));
    yield put(postMessageReset(null));
  } catch (error) {
    console.log(error);
    yield put(postMessageFailure(error));
    yield put(postMessageReset(null));
  }
}

function* handleContactMeDireclyInitiated(action) {
  try {
    const res = yield call(api.postMessageToCoach, action.payload);
    //console.log(res);
    yield put(postMessageToCoachSuccess());
    yield put(postMessageToCoachReset(null));
  } catch (error) {
    console.log(error);
    yield put(postMessageToCoachFailure(error));
    yield put(postMessageToCoachReset(null));
  }
}

function* watchContactUsInitiated() {
  yield takeEvery(CONTACT_US_INITIATED, handleContactUsInitiated)
}

function* watchContactMeDirectlyInitiated() {
  yield takeEvery(CONTACT_ME_DIRECTLY_INITIATED, handleContactMeDireclyInitiated)
}

export default function* messageSagas() {
  yield all([fork(watchContactUsInitiated),fork(watchContactMeDirectlyInitiated)])
}