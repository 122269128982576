import {
  GET_COMMENTS_BY_ID_INITIATED,
  GET_COMMENTS_BY_ID_SUCCESS,
  GET_COMMENTS_BY_ID_FAILURE,
  GET_COMMENTS_BY_ID_RESET,
  GET_USER_COMMENTS_INITIATED,
  GET_USER_COMMENTS_SUCCESS,
  GET_USER_COMMENTS_FAILURE,
  GET_USER_COMMENTS_RESET,
  POST_COMMENT_INITIATED,
  POST_COMMENT_SUCCESS,
  POST_COMMENT_FAILURE,
  POST_COMMENT_RESET,
  DELETE_COMMENT_INITIATED,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAILURE,
  DELETE_COMMENT_RESET,
} from "../constants/constants";

const initialState = {
 
  postCommentOperationCompleted:false,
  postCommentOperationStatus:"Not_Started",
  postCommetResult : {},

  deleteCommentOperationCompleted:false,
  deleteCommentOperationStatus:"Not_Started",
  deleteCommetResult : {},

  getCoachCommentsOperationCompleted : false,
  getCoachCommentsOperationStatus:"Not_Started",
  coachCommentsError: {},
  coachComments: [],

  getUserCommentsOperationCompleted : false,
  getUserCommentsOperationStatus:"Not_Started",
  userCommentsError: {},
  userComments:[],
}

const commentsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_COMMENTS_BY_ID_INITIATED:
      return {...state, getCoachCommentsOperationCompleted: false, getCoachCommentsOperationStatus: "InProcess"};
    case GET_COMMENTS_BY_ID_SUCCESS:
      console.log("comments");
      console.log(action.payload.data)
      return {...state, coachComments: action.payload.data, getCoachCommentsOperationCompleted: true, getCoachCommentsOperationStatus: "Completed_Success"};
    case GET_COMMENTS_BY_ID_FAILURE:
      return {...state, coachCommentsError: action.payload, getCoachCommentsOperationCompleted: true, getCoachCommentsOperationStatus: "Completed_Failed"};
      case GET_COMMENTS_BY_ID_RESET:
          return {...state,  getCoachCommentsOperationCompleted: false, getCoachCommentsOperationStatus: "Not_Started"};
    case GET_USER_COMMENTS_INITIATED:
      return {...state, getUserCommentsOperationCompleted: false, getUserCommentsOperationStatus: "InProcess"};
    case GET_USER_COMMENTS_SUCCESS:
      return {...state, userComments: action.payload.data, getUserCommentsOperationCompleted: true, getUserCommentsOperationStatus: "Completed_Success"};
    case GET_USER_COMMENTS_FAILURE:
        return {...state, userCommentsError: action.payload, getUserCommentsOperationCompleted: true, getUserCommentsOperationStatus: "Completed_Failed"};
    case GET_USER_COMMENTS_RESET:
        return {...state,  getUserCommentsOperationCompleted: false, getUserCommentsOperationStatus: "Not_Started"};
        
    case POST_COMMENT_INITIATED:
      return {...state, postCommentOperationCompleted: false, postCommentOperationStatus: "InProcess"};
    case POST_COMMENT_SUCCESS:
        return {...state,postCommetResult:action.payload,postCommentOperationCompleted:true,postCommentOperationStatus:"Completed_Success"};
    case POST_COMMENT_FAILURE:
      return {...state,postCommetResult:action.payload, postCommentOperationCompleted: true, postCommentOperationStatus: "Completed_Failed"};
    case POST_COMMENT_RESET:
      return {...state, postCommentOperationCompleted: false, postCommentOperationStatus: "Not_Started"};
    
    case DELETE_COMMENT_INITIATED:
      return {...state, deleteCommentOperationCompleted: false, deleteCommentOperationStatus: "InProcess"};
    case DELETE_COMMENT_SUCCESS:
      return {...state,deleteCommetResult:action.payload,deleteCommentOperationCompleted:true,deleteCommentOperationStatus:"Completed_Success"};
    case DELETE_COMMENT_FAILURE:
      return {...state,deleteCommetResult:action.payload, deleteCommentOperationCompleted: true, deleteCommentOperationStatus: "Completed_Failed"};
    case DELETE_COMMENT_RESET:
      return {...state, deleteCommentOperationCompleted: false, deleteCommentOperationStatus: "Not_Started"};
        
    default:
      return state;
  }
};

export default commentsReducer;

