import { takeEvery, put, call, all, fork } from "redux-saga/effects";
import {
  GET_BANNER_TYPE_INITIATED,
  SET_BANNER_TYPE_INITIATED,
  UPDATE_COACH_PROFILE_BY_ADMIN_INITIATED,
  LINK_PROFILE_WITH_USER_INITIATED
} from "../constants/constants";
import api from "../api";
import {
  getBannerTypeSuccess,
  getBannerTypeFailure,
  getBannerTypeReset,
  setBannerTypeSuccess,
  setBannerTypeFailure,
  setBannerTypeReset,
  updateCoachProfileByAdminSuccess,
  updateCoachProfileByAdminFailure,
  updateCoachProfileByAdminReset,
  linkProfileWithUserSuccess,
  linkProfileWithUserFailure,
  linkProfileWithUserReset,
} from "../actions/actions";

function* handleGetBannerTypeInitiated(action) {
  try {
    const res = yield call(api.getBannerPreference, action.payload);
    //console.log(res);
    yield put(getBannerTypeSuccess(res.data));
    yield put(getBannerTypeReset(null));
  } catch (error) {
    console.log(error);
    yield put(getBannerTypeFailure(error));
    yield put(getBannerTypeReset(null));
  }
}

function* handleSetBannerTypeInitiated(action) {
  try {
    const res = yield call(api.putBannerPreference, action.payload);
    //console.log(res);
    yield put(setBannerTypeSuccess(res.data));
    yield put(setBannerTypeReset(null));
    
  } catch (error) {
    console.log(error);
    yield put(setBannerTypeFailure(error));
    yield put(setBannerTypeReset(null));
  }
}

function* handleUpdatCoachProfileByAdminInitiated(action) {
  try {
    //console.log(action.payload)
    const res = yield call(api.updateCoachProfileByAdmin, action.payload);
    //console.log(res);
    yield put(updateCoachProfileByAdminSuccess(res.data));
    yield put(updateCoachProfileByAdminReset(null));
  } catch (error) {
    console.log(error);
    yield put(updateCoachProfileByAdminFailure(error.response));
    yield put(updateCoachProfileByAdminReset(null));
  }
}
function* handleLinkProfileWithUserInitiated(action) {
  try {
    //console.log(action.payload)
    const res = yield call(api.linkProfileWithUser, action.payload);
    //console.log(res);
    yield put(linkProfileWithUserSuccess(res.data));
    yield put(linkProfileWithUserReset(null));
  } catch (error) {
    console.log(error);
    yield put(linkProfileWithUserFailure(error.response));
    yield put(linkProfileWithUserReset(null));
  }
}

function* watchGetBannerTypeInitiated() {
  yield takeEvery(GET_BANNER_TYPE_INITIATED, handleGetBannerTypeInitiated);
}

function* watchSetBannerTypeInitiated() {
  yield takeEvery(SET_BANNER_TYPE_INITIATED, handleSetBannerTypeInitiated);
}

function* watchUpdatCoachProfileByAdminInitiated() {
  yield takeEvery(
    UPDATE_COACH_PROFILE_BY_ADMIN_INITIATED,
    handleUpdatCoachProfileByAdminInitiated
  );
}
function* watchLinkProfileWithUserInitiated() {
  yield takeEvery(
    LINK_PROFILE_WITH_USER_INITIATED,
    handleLinkProfileWithUserInitiated
  );
}

export default function* adminSagas() {
  yield all([
    fork(watchGetBannerTypeInitiated),
    fork(watchSetBannerTypeInitiated),
    fork(watchUpdatCoachProfileByAdminInitiated),
    fork(watchLinkProfileWithUserInitiated)
  ]);
}
