import axios from 'axios';
import { Auth, Hub } from 'aws-amplify';

export const signUpUserWithEmail = (payload) => {
  return axios('https://api.coachranking.com/signup', {
    method: 'PUT',
    data: {
      email: payload.email,
      password: payload.password
    }
  })
}

export const verifyCode = (payload) => {
  return axios('https://api.coachranking.com/confirmuser', {
    method: 'POST',
    data: {
      email: payload.email,
      code: payload.code
    }
  })
}

export const signInUserWithEmail = (payload) => {
  return axios('https://api.coachranking.com/signin', {
    method: 'POST',
    data: {
      email: payload.email,
      password: payload.password
    }
  })
}

class API {

  constructor(){
  }

  token = '';
  baseURL = process.env.NODE_ENV === 'development' ? 'https://api.coachranking.com' : 'https://api.coachranking.com';
  getSearchResults = async(keyword, from, size) => {
    
    return await axios(`${this.baseURL}/user/search/${keyword}`, {
      method: 'GET',
      params: {
        from,
        size
      }
    })
  }
  getSearchResultsByCategory = (category, from, size) => {
      return axios(`${this.baseURL}/user/category/${category}`, {
        method: 'GET',
        params: {
          from,
          size
        }
      })
  }
  searchByCategoryFeaturedUsers = (category) => {
    return axios(`${this.baseURL}/user/featured-category/${category}`, {
      method: 'GET',
    })
  }
  getCategoriesByPlanType= (data) => {
    return axios(`${this.baseURL}/category_types/`, {
      method: 'GET',
    })
  }
  getAllCategories_GroupedInMainCategory= (data) => {
    return axios(`${this.baseURL}/categories/`, {
      method: 'GET',
    })
  }
  getAllCategories_Plain= (data) => {
    return axios(`${this.baseURL}/categories?format=plain `, {
      method: 'GET',
    })
  }
  
  checkNicheAvailbility=(category)=>{
    return axios(`${this.baseURL}/plans/checkNicheAvailbility/${category}`, {
      method: 'GET',
    })
  }

  getProfileById = async(id) => {
    try{

      let Token=await Auth.currentSession();
      return axios(`${this.baseURL}/user/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `${(await Auth.currentSession()).accessToken.jwtToken}` 
        }
      })
    }
    catch(err){
      return axios(`${this.baseURL}/user/${id}`, {
        method: 'GET',
        headers: { 
        }
      })
    }
  }

  getUserProfile = async() => {
    return axios(`${this.baseURL}/profile`, {
      method: 'GET',
      headers: {
        Authorization: `${(await Auth.currentSession()).accessToken.jwtToken}` 
      }
    })
  }

  getComments = async(id) => {
    return axios(`${this.baseURL}/comment/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `${(await Auth.currentSession()).accessToken.jwtToken}` 
      }
    })
  }
  
  updateUserProfile = async({fullName, firstName, lastName, location, city, state, zip, website, fbProfilePage, aboutMe, phone, photo, categoryNames,trainingVideoLinks,storyVideoLinks,isOneOnOne,isFreeTraining,isGroupCoaching}) => {
    const data = {
      "fullName": fullName,
      "firstName": firstName,
      "lastName": lastName,
      "location": location,
      "city": city,
      "state": state,
      "zip": zip,
      "website": website,
      "fbProfilePage": fbProfilePage,
      "aboutMe": aboutMe,
      "phone": phone,
      "categoryNames": categoryNames,
      "photo": (photo !== '') ? photo : undefined,
      trainingVideoLinks:trainingVideoLinks,
      storyVideoLinks:storyVideoLinks,
      isGroupCoaching:isGroupCoaching,
      isFreeTraining:isFreeTraining,
      isOneOnOne:isOneOnOne
    } 
    return axios(`${this.baseURL}/profile`, {
      method: 'PUT',
      headers: {
        Authorization: `${(await Auth.currentSession()).accessToken.jwtToken}` 
      },
      data : data
    })
  }
  updateUserType=async(accountType, level,isCoach)=>{
    const data = {
      "accountType": accountType,
      "level": level,
      "isCoach":isCoach
    } 
    return axios(`${this.baseURL}/profile`, {
      method: 'PUT',
      headers: {
        Authorization: `${(await Auth.currentSession()).accessToken.jwtToken}` 
      },
      data : data
    })
  }
  updateUserAffiliateId=async(affiliateId)=>{
    const data = {
      "affiliateId": affiliateId,
    } 
    return axios(`${this.baseURL}/profile`, {
      method: 'PUT',
      headers: {
        Authorization: `${(await Auth.currentSession()).accessToken.jwtToken}` 
      },
      data : data
    })
  }
  postComment = async(data) => {
    return axios(`${this.baseURL}/comment`, {
      method: 'POST',
      headers: {
        Authorization: `${(await Auth.currentSession()).accessToken.jwtToken}` 
      },
      data : data
    })
  }
  deleteComment = async(id) => {
    return axios(`${this.baseURL}/comment/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `${(await Auth.currentSession()).accessToken.jwtToken}` 
      },
    })
  }

  postMessageToAdmin = async(data) => {
    return axios(`${this.baseURL}/contact`, {
      method: 'POST',
      headers: {
        Authorization: `${(await Auth.currentSession()).accessToken.jwtToken}` 
      },
      data: data
    })
  }
  postMessageToCoach = async(data) => {
    console.log(data);
    return axios(`${this.baseURL}/email`, {
      method: 'POST',
      headers: {
        Authorization: `${(await Auth.currentSession()).accessToken.jwtToken}` 
      },
      data: data
    })
  }
  getBannerPreference = async() => {
    return axios(`${this.baseURL}/admin/bannerpreference`, {
      headers: {
        Authorization: `${(await Auth.currentSession()).accessToken.jwtToken}` 
      }
    })
  }
  putBannerPreference = async(value) => {
    console.log(value);
    return axios(`${this.baseURL}/admin/bannerpreference`, {
      method: 'PUT',
      headers: {
        Authorization: `${(await Auth.currentSession()).accessToken.jwtToken}` 
      },
      data: {
        isGoogleBanner: value
      }
    })
  }
  updateCoachProfileByAdmin = async({_id, fullName, firstName, lastName, location, city, state, zip, website,  fbProfilePage, aboutMe, phone, photo, categoryNames,trainingVideoLinks,storyVideoLinks,isGroupCoaching,isFreeTraining,isOneOnOne}) => {
    const data = {
      "_id": _id,
      "fullName": fullName,
      "firstName": firstName,
      "lastName": lastName,
      "location": location,
      "city": city,
      "state": state,
      "zip": zip,
      "website": website,
      "fbProfilePage": fbProfilePage,
      "aboutMe": aboutMe,
      "phone": phone,
      "categoryNames": categoryNames,
      "photo": (photo !== '') ? photo : undefined,
      storyVideoLinks:storyVideoLinks,
      trainingVideoLinks:trainingVideoLinks,
      isGroupCoaching:isGroupCoaching,
      isFreeTraining:isFreeTraining,
      isOneOnOne:isOneOnOne
    } 
    return axios(`${this.baseURL}/admin/profile`, {
      method: 'PUT',
      headers: {
        Authorization: `${(await Auth.currentSession()).accessToken.jwtToken}` 
      },
      data : data
    })
  }
  adminUpgradeUserLevel=async(userId,level,addOnLevel,featuredCategory,fourMonthSubscription)=>{
    return axios(`${this.baseURL}/admin/userlevel`, {
      method: 'PUT',
      headers: {
        Authorization:`${(await Auth.currentSession()).accessToken.jwtToken}` 
      },
      data : {
        "userId":userId,
        "featuredCategory": featuredCategory,
        "level": level ,
        "addOnLevel":addOnLevel,
        "fourMonthSubscription": fourMonthSubscription
      }
    })
  }
  claimProfile = async(id) => {  
    return axios(`${this.baseURL}/user/mapping/${id}`, {
      method: 'POST',
      headers: {
        Authorization:`${(await Auth.currentSession()).accessToken.jwtToken}` 
      }
    })
  }
  subscriptionRequest = async(data) => {
    return axios(`${this.baseURL}/subscription`, {
      method: 'POST',
      headers: {
        Authorization:`${(await Auth.currentSession()).accessToken.jwtToken}` 
      },
      data : data
    })
  }
  linkProfileWithUser = async(data) => {
    return axios(`${this.baseURL}/admin/linkprofile`, {
      method: 'POST',
      headers: {
        Authorization:`${(await Auth.currentSession()).accessToken.jwtToken}` 
      },
      data : data
    })
  }
  deleteProfile = async(id) => {
    return axios(`${this.baseURL}/user/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization:`${(await Auth.currentSession()).accessToken.jwtToken}` 
      },
     
    })
  }
  addNotesInProfile = async(userId,notes) => {
    var data={"userId":userId,
    "notes":notes
    }
    return axios(`${this.baseURL}/notes`, {
      method: 'PUT',
      headers: {
        Authorization:`${(await Auth.currentSession()).accessToken.jwtToken}` 
      },
      data:data
    })
  }
  getNotesOfProfile = async(userId) => {
    return axios(`${this.baseURL}/notes/${userId}`, {
      method: 'GET',
      headers: {
        Authorization:`${(await Auth.currentSession()).accessToken.jwtToken}` 
      },
    })
  }
}

const api = new API();
export default api;