import React from "react";
import { connect } from "react-redux";
import "../assets/css/SearchPage.css";
import { setNavbarCustomCss } from '../actions/actions'
import api from '../api';

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNavbarCustomCss: data => dispatch(setNavbarCustomCss(data))
  }
}
class NoMatchPage extends React.Component {

  constructor() {
    super()
    this.state = {
     
    }
  }
  componentDidMount=async()=> {
   
    this.props.setNavbarCustomCss({
      navbarMode:'darkmode'
    });

   
  }

 
  render() {
   
 
    return (
      <div className="col-xl-12">
        <section  style={{minHeight: window.innerHeight}}>
            <div className="row searchPageLandingSection"  style={{ minHeight: window.innerHeight-30,maxHeight: window.innerHeight-30}}>
              <div className="col-xl-1"></div>
                <div className="col-xl-10" style={{display:"flex", minHeight: window.innerHeight/1.5,justifyContent:"center",alignItems:"center" }}>
                  <span className="HomeLandingSectionTitle">
                    "Error 404. Page Not Found"
                  </span>
                  
                </div>
                <div className="col-xl-1"></div>
            </div>
    
        </section>
 
    
      </div>
    );
    
  
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(NoMatchPage);
