import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CoachCardModalPopup from './CoachCardModalPopup';

class InfoPopup extends React.Component {
  render() {
    return (
      <div>
        <Modal size="lg" dialogClassName="modal-90w" show={this.props.show} onHide={this.props.handleClose} centered>
          <CoachCardModalPopup coach={this.props.profile}/>
        </Modal>
      </div>
    );
  }
}

export default InfoPopup;
