import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TagPill from "../components/TagPill";
import FeaturedProfileCard from "../components/FeaturedProfileCard";
import "../assets/css/CategorySection.css";
import AdvertisementCard from "../components/AdvertisementCard";
import api from "../api";

const mapStateToProps = state => {
  return state;
};

class CategorySection extends React.Component {
  constructor() {
    super();
    this.state = {
      CoachesResultSet: [],
      subCategories: []
    };
  }
  componentDidMount = async () => {
    var self = this;

    let res_FeaturedCoaches = await api.searchByCategoryFeaturedUsers(
      this.props.category.name2
    );
    let FeaturedCoaches = res_FeaturedCoaches.data.data;
    FeaturedCoaches = FeaturedCoaches.map(obj => ({ ...obj, featured: true }));
    var countFeaturedCoaches = FeaturedCoaches.length;
    var NonFeaturedCoachesToBeFetched = 8 - countFeaturedCoaches;

    let res_NonFeaturedCoaches = await api.getSearchResultsByCategory(
      this.props.category.name2,
      0,
      NonFeaturedCoachesToBeFetched
    );
    let NonFeaturedCoaches = res_NonFeaturedCoaches.data.data;

    var CoachesResultSet = [...FeaturedCoaches, ...NonFeaturedCoaches];
    var subCategoriesFiltered = [];

    var subCategories = this.props.navItems[this.props.category.name];

    if (subCategories.length > 50) {
      subCategories.map((x, i) => {
        if (x.length > 20) {
          subCategoriesFiltered.push(x.slice(0, x.lastIndexOf(" ")));
        } else if (x.length > 15) {
          subCategoriesFiltered.push(x);
        }
      });
    } else {
      subCategories.map((x, i) => {
        if (x.length > 20) {
          subCategoriesFiltered.push(x.slice(0, x.lastIndexOf(" ")));
        } else if (x.length > 10) {
          subCategoriesFiltered.push(x);
        }
      });
    }
    if (subCategoriesFiltered.length > 30)
      subCategoriesFiltered = subCategoriesFiltered.slice(0, 30);

    this.setState({
      CoachesResultSet: CoachesResultSet,
      subCategories: subCategoriesFiltered
    });
  };
  render() {
    return (
      <div className="row">
        <div className="col-xl-12" style={{ textAlign: "center" }}>
          <AdvertisementCard />
        </div>

        <section
          className={this.props.category.image}
          style={{ width: "100%" }}
        >
          <div className="row">
            <div className="col-xl-1"></div>
            <div className="col-xl-10">
              <div className="row" style={{ margin: "0px" }}>
                <div className="col-xl-5" style={{ textAlign: "center" }}>
                  <div className="tags-container">
                    <Link
                      to={{
                        pathname: `/category/${this.props.category.name2}`
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <p className="category-title">
                        {this.props.category.name} Category
                      </p>
                    </Link>
                  </div>
                  <div className="subCategoryContainer">
                    {this.state.subCategories.map((subCategory, i) => {
                      return (
                        <Link
                          to={{
                            pathname: `/category/${
                              this.props.navItems[this.props.category.name][
                                this.props.navItems[
                                  this.props.category.name
                                ].findIndex(element =>
                                  element.includes(subCategory)
                                )
                              ]
                            }`
                          }}
                          className="sub-category-link"
                          key={i}
                        >
                          <p className="sub-category-title">{subCategory}</p>
                        </Link>
                      );
                    })}
                  </div>
                </div>
                <div className="col-xl-7">
                  <div className="featured-card-container">
                    <ul className="featuredCarrdsList">
                      {this.state.CoachesResultSet &&
                      this.state.CoachesResultSet.length > 0
                        ? this.state.CoachesResultSet.map(profile => {
                            return (
                              <FeaturedProfileCard
                                profile={profile}
                                key={profile._id}
                              />
                            );
                          })
                        : "No Coaches Found In This Category"}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-1"></div>
          </div>
        </section>
      </div>
    );
  }
}

export default connect(mapStateToProps)(CategorySection);
