import React from 'react';
import '../assets/css/ProfilePicture.css';
// import { camel } from "case";

class ProfilePicture extends React.Component {
  constructor() {
    super();
    this.state = {
      isSelected: false
    };
  }

  render() {
   
    return (
        this.props.photoLink && this.props.photoLink !==""?
        <div className="profile-picture" style={{backgroundImage: `url(${this.props.photoLink})`}}>
        </div>
        :
        <div className="profile-picture profile-picture_noImage">
        </div>
    )
  }
}

export default ProfilePicture;