import { combineReducers } from 'redux';

import oauthReducer from './oauthReducer';
import userReducer from './userReducer';
import searchReducer from './searchReducer';
import commentsReducer from './commentsReducer';
import adminReducer from './adminReducer';
import messageReducer from './messageReducer';
import subscriptionReducer from './subscriptionReducer'

const rootReducer = combineReducers({
  oauth: oauthReducer,
  user: userReducer,
  search: searchReducer,
  comments: commentsReducer,
  admin: adminReducer,
  message:messageReducer,
  subscription:subscriptionReducer
})

export default rootReducer;