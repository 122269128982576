import React from 'react';
import { connect } from 'react-redux';
import { getUserProfileInitiated } from '../actions/actions'
import api from "../api/index";
import { Auth, Hub } from 'aws-amplify';

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClick: () => dispatch(getUserProfileInitiated())
  }
}

class Thanks extends React.Component {

  // componentDidMount = () => {
  //   Auth.currentSession()
  //     .then(data => {
  //       console.log(`data.accessToken.jwtToken: ${data.accessToken.jwtToken}`)
  //       api.setToken(data.accessToken.jwtToken)
  //     })
  //     .catch(err => console.log(err));
  // }


  render() {
    return (
      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "90vh" }}>
          <p>Thank you for signing up. Kindly confirm by clicking on the link sent to your email.</p>
          {/* <button onClick={this.props.onClick}>Get Profile</button> */}
          {/* <p>{JSON.stringify(this.props.oauth.newUser.user.username,null,2)}</p> */}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Thanks);