import React from "react";
import Rating from "react-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as farStar, faCheckCircle, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faStar as fasStar } from "@fortawesome/free-solid-svg-icons";
import {Link} from 'react-router-dom';
import { connect } from "react-redux";
import Badge from "./Badge";
import "../assets/css/CoachCard.css";
import { getProfileByIdInitiated } from "../actions/actions";
import ProfilePicture from '../components/ProfilePicture';
import AdvertisementCard from "../components/AdvertisementCard";
import Badges from '../constants/badges'
import { ColumnWidthOutlined,
  DeleteOutlined ,
  HighlightOutlined ,
  RiseOutlined ,
  CheckOutlined,
  CheckCircleOutlined ,
  CloseCircleOutlined,
  StarOutlined,

 } from '@ant-design/icons'


import InfiniteScroll from "react-infinite-scroller";

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
      getCoachProfile: (id) => dispatch(getProfileByIdInitiated(id))
  }
}

class CoachCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
   
    };
  }

  handleOnClick = (id) => {
    console.log(id);
    this.props.getCoachProfile(id);
  }

  DrawContent = () => {
    
    var self = this;
    var items = [];
    self.props.CoachesResultSet.map((s, i) => {

      
      var aboutMe=s.aboutMe;
      if(aboutMe && aboutMe.length>640){
        aboutMe=aboutMe.slice(0,640)+"...";
      }
      var allBadges=[
        { name: "Great Communicator", count: 0,imgName:"Communicator_img.png" },
        { name: "Very Intuitive", count: 0 ,imgName:"Intuitive_img.png"},
        { name: "Problem Solver", count: 0,imgName:"Problem Solver_img.png"},
        { name: "Very Smart", count: 0,imgName:"Smart_img.png"},
        { name: "A True Specialist", count: 0 ,imgName:"Specialist_img.png"},
        { name: "Always On Time", count: 0 ,imgName:"Time_img.png"},
        { name: "Has Vision", count: 0,imgName:"Vision_img.png"},
        { name: "Great Friend", count: 0,imgName:"Good_Friend_img.png"}]
      if(s.badges && Array.isArray(s.badges)){
        allBadges=allBadges.map((obj1) => {
          s.badges.map((obj2)=>{

            if(obj2.name === obj1.name){
              obj1.count=obj2.count;
              
            }
          });
          return obj1;
         
        }); 
      }
     
      var AdsCard="";
      if(i%3==0){
        AdsCard=<div className="row">
        <div className="col-xl-12">
           <AdvertisementCard/> 
          </div>
        </div>
      }

      items.push(
        <div key={i} className="col-xl-12" style={{paddingBottom:"10px"}}>
         {AdsCard}
       
        <div className={`row ${s.featured ? "featured" : ""}`}>
        <div className="col-xl-3 coachcard">
          <ProfilePicture photoLink={s.photoLink}></ProfilePicture>
        </div>
        <div className="col-xl-6" style={{backgroundColor: '#FFFFFF'}}>
          <div className="row">
            <div className="col-xl-12">
                <span className="CoachCardName">{s.fullName}
                {s.featured?
                  <button className="btn btnSponsored">Sponsored</button>:
                 <></>
                }
                </span>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
                <span className="CoachCardAboutMe">{aboutMe}</span>
            </div>
          </div>
          <div className="row" style={{padding:"10px 0px"}}>
            <div className="col-xl-12">
            Ratings
              <span className="star-block">
                <Rating
                  emptySymbol={
                    <FontAwesomeIcon
                      icon={farStar}
                      style={{ color: "#DF0000" }}
                      inverse={true}
                    />
                  }
                  fullSymbol={
                    <FontAwesomeIcon
                      icon={fasStar}
                      style={{ color: "#DF0000" }}
                      inverse={true}
                    />
                  }
                  fractions={2}
                  initialRating={s.rating}
                  readonly
                />{" "}
                {s.rating}
              </span>
          
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              {(allBadges && allBadges.length > 0) ? (<div>
              {allBadges.map((badge,i) => {
                return <Badge name={badge.name} imgName={badge.imgName} count={badge.count} width="50px" key={i} />;
              })}
            </div>) : (<div/>)}
            </div>
          </div>
    
        </div>
        <div className="col-xl-3" style={{backgroundColor: '#F3F5F9'}}>
          <div className="row" >
            <div className="col-xl-12">
                <span className="CoachOffers">Coach Offers</span>
            </div>
          </div>
          <div className="row" style={{paddingBottom:"10px"}}>
            <div className="col-xl-12">
            <span> {s.isOneOnOne ? <CheckCircleOutlined  style={{color:"#254d9f",fontSize:"20px"}}/> : <CloseCircleOutlined style={{color:"#254d9f",fontSize:"20px"}}/>}  One on One </span>
            </div>
          </div>
          <div className="row" style={{paddingBottom:"10px"}}>
            <div className="col-xl-12">
            <span> {s.isGroupCoaching ?  <CheckCircleOutlined  style={{color:"#254d9f",fontSize:"20px"}}/> :<CloseCircleOutlined style={{color:"#254d9f",fontSize:"20px"}}/>} Group Coaching</span>
            </div>
          </div>
          <div className="row" style={{paddingBottom:"10px"}}>
            <div className="col-xl-12">
              <span> {s.isFreeTraining ? <CheckCircleOutlined  style={{color:"#254d9f",fontSize:"20px"}}/> : <CloseCircleOutlined style={{color:"#254d9f",fontSize:"20px"}}/>} Free Training</span>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <Link to={{pathname: `/coach/${s.fullName?s.fullName.replace(" ","_"):"noname"}/${s._id}`}} className="btn btnLearnMore" onClick={()=> this.handleOnClick(s._id)}>
              Learn more
              </Link>
            </div>
         </div>
        </div>
      </div>
      </div>
  
      )

    })

    
    if (self.props.CoachesResultSet.length > 0) {
      
     
      return (
        <InfiniteScroll
          className="row"
          style={{ padding: "0px" }}
          pageStart={0}
          loadMore={this.props.loadMore}
          hasMore={this.props.hasMore}
          loader={
            <div className="lds-ellipsis" key={5}>
              <div key={4}></div>
              <div key={3}></div>
              <div key={2}></div>
              <div key={1}></div>
            </div>
          }
        >
          {items}
        </InfiniteScroll>
      );
    } else {
      return (
        <div className="empty_message col-xl-12">
          <div>No results found please try a different search.</div>
        </div>
      );
    }

  }
 

  render() {
    return <div className="listview">{this.DrawContent()}</div>;
    // const {
    //   _id,
    //   fullName,
    //   aboutMe,
    //   rating,
    //   photoLink,
    //   badges
    // } = this.props.coach;
    // var allBadges=[
    //   { name: "Communicator", count: 0 },
    //   { name: "Intuitive", count: 0 },
    //   { name: "Problem Solver", count: 0},
    //   { name: "Smart", count: 0},
    //   { name: "Specialist", count: 0 },
    //   { name: "Time", count: 0 },
    //   { name: "Vision", count: 0},
    //   { name: "Good Friend", count: 0},
    // ]
    // if(this.props.coach.badges){
    //   allBadges=allBadges.map(obj1 => this.props.coach.badges.find(obj2 => obj2.name === obj1.name) || obj1); 
    // }
    // const { isOneOnOne, isGroupCoaching, isFreeTraining } = this.state.mockData
    // return (
    //       <div className="row" style={{border: '1px solid #E3E9F3'}}>
    //         <div className="col-xl-3" style={{display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', backgroundColor: '#FFFFFF',padding:"40px"}}>
    //           <ProfilePicture photoLink={photoLink}></ProfilePicture>
    //         </div>
    //         <div className="col-xl-6" style={{backgroundColor: '#FFFFFF'}}>
    //           <div className="row">
    //             <div className="col-xl-12">
    //                 <span className="CoachCardName">{fullName}</span>
    //                 {this.props.featured?
    //                   <button className="btn btnSponsored">Sponsored</button>:
    //                  <></>
    //                 }
    //             </div>
    //           </div>
    //           <div className="row">
    //             <div className="col-xl-12">
    //                 <span className="CoachCardAboutMe">{aboutMe}</span>
    //             </div>
    //           </div>
    //           <div className="row" style={{padding:"10px 0px"}}>
    //             <div className="col-xl-12">
    //             Ratings
    //               <span className="star-block">
    //                 <Rating
    //                   emptySymbol={
    //                     <FontAwesomeIcon
    //                       icon={farStar}
    //                       style={{ color: "#DF0000" }}
    //                       inverse={true}
    //                     />
    //                   }
    //                   fullSymbol={
    //                     <FontAwesomeIcon
    //                       icon={fasStar}
    //                       style={{ color: "#DF0000" }}
    //                       inverse={true}
    //                     />
    //                   }
    //                   fractions={2}
    //                   initialRating={rating}
    //                   readonly
    //                 />{" "}
    //                 {rating}
    //               </span>
              
    //             </div>
    //           </div>
    //           <div className="row">
    //             <div className="col-xl-12">
    //               {(allBadges && allBadges.length > 0) ? (<div>
    //               {allBadges.map((badge,i) => {
    //                 return <Badge name={badge.name} count={badge.count} width="50px" key={i} />;
    //               })}
    //             </div>) : (<div/>)}
    //             </div>
    //           </div>
        
    //         </div>
    //         <div className="col-xl-3" style={{backgroundColor: '#F3F5F9'}}>
    //           <div className="row" >
    //             <div className="col-xl-12">
    //                 <span className="CoachOffers">Coach Offers</span>
    //             </div>
    //           </div>
    //           <div className="row">
    //             <div className="col-xl-12">
    //             <p> {isOneOnOne ? <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#0B3790' }} /> : <FontAwesomeIcon icon={faTimesCircle} style={{ color: '#0B3790' }} />}  One on One </p>
    //             </div>
    //           </div>
    //           <div className="row">
    //             <div className="col-xl-12">
    //             <p> {isGroupCoaching ? <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#0B3790' }} /> : <FontAwesomeIcon icon={faTimesCircle} style={{ color: '#0B3790' }} />} Group Coaching</p>
    //             </div>
    //           </div>
    //           <div className="row">
    //             <div className="col-xl-12">
    //               <p> {isFreeTraining ? <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#0B3790' }} /> : <FontAwesomeIcon icon={faTimesCircle} style={{ color: '#0B3790' }} />} Free Training</p>
    //             </div>
    //           </div>
    //           <div className="row">
    //             <div className="col-xl-12">
    //               <Link to={{pathname: `/coach/${_id}`}} className="btn btnLearnMore" onClick={()=> this.handleOnClick(_id)}>
    //               Learn more
    //               </Link>
    //             </div>
    //          </div>
    //         </div>
    //       </div>
      
    // );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(CoachCard);
