import { takeEvery, put, call, all, fork } from 'redux-saga/effects';
import { USER_SIGNUP_INITIATED, USER_SIGNIN_INITIATED, VERIFICATION_CODE_SUBMITED } from '../constants/constants';
import { signUpUserWithEmail, signInUserWithEmail, verifyCode } from '../api';
import { userSignUpFailure, userSignUpSuccess, userSignInSuccess, userSignInFailure, verifyCodeSuccess, verifyCodeFailure } from '../actions/actions';

function* handleUserSignedUp(action) {
  try {
    const res = yield call(signUpUserWithEmail, action.payload);
    console.log(res);
    yield put(userSignUpSuccess(res.data))
  } catch (error) {
    console.log(error);
    yield put(userSignUpFailure(error))
  }
}

function* handleVerifyCode(action) {
  try {
    const res = yield call(verifyCode, action.payload);
    console.log(res);
    yield put(verifyCodeSuccess(res.data));
  } catch (error) {
    console.log(error);
    yield put(verifyCodeFailure(error));
  }
}

function* handleUserSignedIn(action) {
  try {
    const res = yield call(signInUserWithEmail, action.payload);
    console.log(res);
    yield put(userSignInSuccess(res.data))
  } catch (error) {
    console.log(error);
    yield put(userSignInFailure(error))
  }
}

function* watchUserSignedUp() {
  yield takeEvery(USER_SIGNUP_INITIATED, handleUserSignedUp)
}

function* watchVerifycode() {
  yield takeEvery(VERIFICATION_CODE_SUBMITED, handleVerifyCode)
}

function* watchUserSignedIn() {
  yield takeEvery(USER_SIGNIN_INITIATED, handleUserSignedIn)
}

export default function* oauthSagas() {
  yield all([fork(watchUserSignedUp), fork(watchUserSignedIn), fork(watchVerifycode)])
}