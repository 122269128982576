import React from 'react';
import { connect } from 'react-redux';
import api from '../api';
import { getUserProfileInitiated, setNavbarCustomCss } from '../actions/actions'
import { message, Spin } from "antd";
import '../assets/css/claimprofile.css';

const mapStateToProps = state => {
  return state
}
const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(getUserProfileInitiated()),
    setNavbarCustomCss: (data) => dispatch(setNavbarCustomCss(data))
  }
}
class ClaimProfile extends React.Component {
  constructor() {
    super();
    this.state = {
      
      errorMessage: null,
      claimStatus:"InProcess",
      isLoading:true,
    }
  }
  componentDidMount() {
      this.props.setNavbarCustomCss({
        navbarMode:'lightmode'
      });
      let claimedId = localStorage.getItem('claimProfile');
      if(claimedId){
      api.claimProfile(claimedId)
        .then(data => {
          this.setState({isLoading:false,
          messageLevel:"success",
          message:"Congratulations. You have successfully claimed this profile",
          claimStatus: "ClaimSuccessful",
          
          },
          () => this.Notify());
          localStorage.removeItem('claimProfile');
          localStorage.removeItem('name');
        })
        .catch(error => {
          console.log("error");
          console.log(error);
          this.setState({isLoading:false,
          messageLevel:"error",
          message: "We regret the inconvenience. Your claim is not successful.",
          claimStatus:"ClaimFailed",
          errorMessage:error.response.data.message,
          },
          () => this.Notify());
          localStorage.removeItem('claimProfile');
          localStorage.removeItem('name');
        })
      }
      else{
        this.props.history.push('./')
      }
    
  }
  goToProfileSettings=()=>{
    this.props.history.push("/profile");
  }
  spinner=()=>{
		return(
		<div className="row">
			<div className="col-xl-12" style={{textAlign:"center",position:"absolute",top:"20px",zIndex:"1000"}}>

				<Spin size="large"></Spin>

			</div>
		</div>)
  }
  Notify = () => {
    if (this.state.messageLevel == "success") {
      message.success(this.state.message);
    } else if (this.state.messageLevel == "error") {
      message.error(this.state.message);
    }
  };
  render() {
    return (
      <div className="col-xl-12">
        {this.state.isLoading && this.spinner()}
        <section id="claimprofile-section" style={{ minHeight: window.innerHeight-150}}>
          <div className="row" style={{width:"100%"}}>
            <div className="col-xl-2"></div>
            <div className="col-xl-8">
            <div className="row" style={{backgroundColor:"white",paddingBottom:"0px",minHeight:"400px"}}>
              <div className="col-xl-6 claimProfileImageContainer">
            
              </div>
             
            {this.state.claimStatus=="InProcess" ?
            <div className="col-xl-6 paddingOnLargeScreenOnly" >
            
                <div className="row">
                  <div className="col-xl-12" style={{paddingBottom:"20px",textAlign:"center"}}>
                      <h5 className="ClaimProfileHeader">Claim Profile In Process ...</h5>
                  </div>
                  <div className="col-xl-12" style={{paddingBottom:"10px",textAlign:"center"}}>
                    <span className="ClaimProfileStatus">Your claim is being verified by our team. You will be notified once it is complete.</span>
                  </div>
                
                  
                </div>

              </div>
            : <></>
            }
          {this.state.claimStatus=="ClaimSuccessful" ?
          <div className="col-xl-6 paddingOnLargeScreenOnly">

            <div className="row">
                  <div className="col-xl-12" style={{paddingBottom:"20px",textAlign:"center"}}>
                      <h5 className="ClaimProfileHeader">Congratulation</h5>
                  </div>
                
                  <div className="col-xl-12" style={{paddingBottom:"10px",textAlign:"center"}}>
                    <p className="ClaimProfileStatus">You have successfully claimed your CoachRanking profile.</p>
                    <p className="ClaimProfileStatus">Click the view profile button below to edit your profile.</p>
                  </div>
                  <div className="col-xl-12" style={{paddingBottom:"10px",textAlign:"center"}}>
                      <img className="imgArrowDown"></img>
                  </div>
                 
                  <div className="col-xl-12" style={{textAlign:"center"}}>
                    <button
                      onClick={this.goToProfileSettings}
                      className="btn btnProfileSettings"
                    >
                      View Profile
                    </button>
                  </div>
                
                  
                </div>
              

          </div> : <></>
          }

          {this.state.claimStatus=="ClaimFailed" ?
          <div className="col-xl-6 paddingOnLargeScreenOnly">

            <div className="row">
                  <div className="col-xl-12" style={{paddingBottom:"20px",textAlign:"center"}}>
                      <h5 className="ClaimProfileHeader">Thanks For Your Patience</h5>
                  </div>
              
                  <div className="col-xl-12" style={{paddingBottom:"10px",textAlign:"center"}}>
                    <p className="ClaimProfileStatus" style={{color:"blue"}}>An administrator is manually approving your profile.</p>
                  </div>
                  <div className="col-xl-12" style={{paddingBottom:"10px",textAlign:"center"}}>
                    <p className="ClaimProfileStatus">You will receive an email from <a href="mailto:corey@coachranking.com">corey@coachranking.com</a> with details once your profile claim is approved.</p>
                  </div>
                  <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                    <p className="ClaimProfileStatus">1.) Whitelist the email above so it goes to your inbox and you can <a href="https://blog.coachranking.com/coaching/how-to-whitelist-our-email/" target="_blank">click this</a> link for directions on doing that.</p>
                  </div>
                  <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                    <p className="ClaimProfileStatus">2.) Make sure to check your SPAM folder.</p>
                  </div>
                  <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                    <p className="ClaimProfileStatus">3.) In the meanwhile, you can <a href="https://authorityalert.online/coaches-resource-page" target="_blank">visit this page here</a> to access your free resource page and Users Guide.</p>
                  </div>
                </div>
              

          </div> : <></>
          }

            </div>
            <div className="col-xl-2"></div>
          </div>
          </div>


        </section>
      </div>
    
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimProfile);