import { takeEvery, put, call, all, fork } from 'redux-saga/effects';
import { GET_COMMENTS_BY_ID_INITIATED, POST_COMMENT_INITIATED,DELETE_COMMENT_INITIATED, GET_USER_COMMENTS_INITIATED } from '../constants/constants';
import api from '../api';
import {
    getCommentsByIdFailure,
    getCommentsByIdSuccess,
    getCommentsByIdReset, 
    getUserCommentsSuccess,
    getUserCommentsFailure,
    getUserCommentsReset,
    postCommentSuccess, 
    postCommentFailure,
    postCommentReset,
    deleteCommentSuccess,
    deleteCommentFailure,
    deleteCommentReset
   } from '../actions/actions';

function* handleGetCommnetsInitiated(action) {
  try {
    const res = yield call(api.getComments, action.payload)
    //console.log(res);
    yield put(getCommentsByIdSuccess(res.data))
    yield put(getCommentsByIdReset(null))
  } catch (error) {
    console.log(error);
    yield put(getCommentsByIdFailure(error));
    yield put(getCommentsByIdReset(null))
  }
}

function* handleGetUserCommnetsInitiated(action) {
  try {
    const res = yield call(api.getComments, action.payload)
    //console.log(res);
    yield put(getUserCommentsSuccess(res.data))
    yield put(getUserCommentsReset(null))
  } catch (error) {
    console.log(error);
    yield put(getUserCommentsFailure(error));
    yield put(getUserCommentsReset(null))
  }
}

function* handlePostCommentInitiated(action) {
  try {
    const res = yield call(api.postComment, action.payload)
    //console.log(res);
    yield put(postCommentSuccess(action.payload))
    yield put(postCommentReset(null))
  } catch (error) {
    
    yield put(postCommentFailure(error.response));
    yield put(postCommentReset(null))

  }
}

function* handleDeleteCommentInitiated(action) {
  try {
    const res = yield call(api.deleteComment, action.payload)
    //console.log(res);
    yield put(deleteCommentSuccess(action.payload))
    yield put(deleteCommentReset(null))
  } catch (error) {
    
    yield put(deleteCommentFailure(error.response));
    yield put(deleteCommentReset(null))

  }
}

function* watchGetCommentsByIdInitiated() {
  yield takeEvery(GET_COMMENTS_BY_ID_INITIATED, handleGetCommnetsInitiated)
}

function* watchPostCommentInitiated() {
  yield takeEvery(POST_COMMENT_INITIATED, handlePostCommentInitiated)
}
function* watchDeleteCommentInitiated() {
  yield takeEvery(DELETE_COMMENT_INITIATED, handleDeleteCommentInitiated)
}

function* watchGetUserCommentsInitiated() {
  yield takeEvery(GET_USER_COMMENTS_INITIATED, handleGetUserCommnetsInitiated)
}

export default function* userSagas() {
  yield all([fork(watchGetCommentsByIdInitiated), fork(watchPostCommentInitiated), fork(watchDeleteCommentInitiated), fork(watchGetUserCommentsInitiated)])
}