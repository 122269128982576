import React from 'react';
import { connect } from 'react-redux';
import { updateUserTypeInitiated, setNavbarCustomCss,getUserProfileInitiated } from '../actions/actions'
import "../assets/css/SelectAccountType.css"
import { message, Spin, Upload, Icon } from "antd";
import api from '../api';

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
   
    updateUserType: (state) => dispatch(updateUserTypeInitiated(state)),
    getUserProfile: () => dispatch(getUserProfileInitiated()),
    setNavbarCustomCss: (data) => dispatch(setNavbarCustomCss(data))
  }
}

class SelectAccountType extends React.Component {

  componentDidMount() {
    this.props.setNavbarCustomCss({
      navbarMode:'lightmode'
    })
  }
  // componentDidUpdate(prevProps,prevState){
    
  //   if(prevProps!=this.props){
  //     if (this.props.user.getUserProfileOperationCompleted){
       
        
  //         if(this.props.user.getUserProfileOperationStatus=="Completed_Success"){
  //           alert(this.props.user.getUserProfileOperationStatus)
  //           this.setState({messageLevel:"success",
  //           message:"User type prefrence saved successfully."
  //           },
  //           () => this.Notify());
  //           this.props.history.push('/profile');
  //         }
  //         else if(this.props.user.getUserProfileOperationStatus=="Completed_Failed"){
  //           this.setState({messageLevel:"error",
  //           message:"Failed to save user type prefrence.."
  //           },
  //           () => this.Notify());
  //         }
  //       }
  //   }
  // }
  // componentDidUpdate (prevProps) {
  //   if(prevProps!=this.props){
  //     if (this.props.user.updateUserTypeOperationCompleted){
  //         if(this.props.user.updateUserTypeOperationStatus=="Completed_Success"){
  //           this.setState({level:"success",
  //           message:"User type prefrence saved successfully."
  //           },
  //           () => {this.Notify();
  //             this.props.history.push("/profile");
  //             window.location.reload();
  //           });
           
  //         }
  //         else if(this.props.user.updateUserTypeOperationStatus=="Completed_Failed"){
  //           this.setState({level:"error",
  //           message:"Failed to save user type prefrence."
  //           },
  //           () => this.Notify());
  //         }
  //       }
  //   }
    
  // }

  selectAccountUser = async() => {
    var accountType="User";
    var level="level1";
    var isCoach=false;
    try{
      let res=await api.updateUserType(accountType,level,isCoach);
      //await this.props.getUserProfile();
      //let refreshUserProfile=await api.getUserProfile();
      this.setState({messageLevel:"success",
      message:"User type prefrence saved successfully."
      },
      () => {this.Notify();
        this.props.history.push("/Thankyou/User");
        
      });
    }
    catch(e){

    }
  }
  selectAccountCoach = async() => {
    var accountType="Coach";
    var level="level2";
    var isCoach=true;
    try{
      let res=await api.updateUserType(accountType,level,isCoach);
      //await this.props.getUserProfile();
      //let refreshUserProfile=await api.getUserProfile();
      this.setState({messageLevel:"success",
      message:"User type prefrence saved successfully."
      },
      () => {this.Notify();
        this.props.history.push("/Thankyou/Coach");
        
      });
    }
    catch(e){

    }
  
  }
  spinner=()=>{
		return(
		<div className="row">
			<div className="col-xl-12" style={{textAlign:"center",position:"absolute",top:"20px",zIndex:"1000"}}>

				<Spin size="large"></Spin>

			</div>
		</div>)
  }
  Notify = () => {
    if (this.state.messageLevel == "success") {
      message.success(this.state.message);
    } else if (this.state.messageLevel == "error") {
      message.error(this.state.message);
    }
  };

  render() {
    return (
      <div className="col-xl-12">
         {this.props.user.updateUserProfileOperationStatus=="InProcess"
          && this.spinner()}
        <section className="selectAccountTypeMainSection" style={{ minHeight: window.innerHeight-150}}>
        <div className="row" style={{width:"100%"}}>
          <div className="col-xl-2">
          </div>
          <div className="col-xl-8" style={{backgroundColor:"white",paddingTop:"50px",paddingBottom:"50px"}}>
            <div className="row">
              <div className="col-xl-12" style={{textAlign:"center"}}>
                  <span className="WhatKindOfAccountYouWant">What kind of account do you want?</span>
              </div>
            </div>
            <div className="row" style={{paddingTop:"30px"}}>
              <div className="col-xl-6 IAmAUser" onClick={this.selectAccountUser}>
                  <div className="row">
                      <div className="col-xl-12" style={{textAlign:"center"}}>
                        <span className="IAmAUserHeader1">I'm a User</span>
                      </div>
                      <div className="col-xl-12" style={{textAlign:"center"}}>
                        <span className="IAmAUserHeader2">Leave rating and reviews</span>
                      </div>
                  </div>
              </div>
              <div className="col-xl-6 IAmACoach" onClick={this.selectAccountCoach}>
                  <div className="row">
                    <div className="col-xl-12" style={{textAlign:"center"}}>
                      <span className="IAmACoachHeader1">I'm a Coach</span>
                    </div> 
                    <div className="col-xl-12" style={{textAlign:"center"}}>
                      <span className="IAmACoachHeader2">Claim my profile</span>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2">
          </div>
        </div>
        </section>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectAccountType);