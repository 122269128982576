import React from "react";
import { connect } from "react-redux";
import "../assets/css/Home.css";
import CoachCard from "../components/CoachCard";
import SearchInput from "../components/SearchInput";
import AdvertisementCard from "../components/AdvertisementCard";
import { message, Spin, Modal, Button, Switch, Select } from "antd";
import {
  setNavbarCustomCss,
  getSearchResultsByCategoryInitiated,
  getSearchResultsByCategoryFeaturedUsersInitiated
} from "../actions/actions";
import "../assets/css/CategorySearchPage.css";
import api from "../api";

const categoryImages = {
  Business: "CategoryCard_Business2",
  Life: "CategoryCard_Life2",
  Relationship: "CategoryCard_Relationship2",
  Health: "CategoryCard_Health2",
  Fitness: "CategoryCard_Fitness2",
  Influencers: "CategoryCard_More2",
  More: "CategoryCard_More2",
  "Business Coach": "CategoryCard_Business2",
  "Life Coach": "CategoryCard_Life2",
  "Relationship Coach": "CategoryCard_Relationship2",
  "Health Coach": "CategoryCard_Health2",
  "Fitness Trainer": "CategoryCard_Fitness2",
  Actors: "CategoryCard_Fitness2",
  "More Coach": "CategoryCard_More2"
};

const mapStateToProps = state => {
  return state;
};
const mapDispatchToProps = dispatch => {
  return {
    setNavbarCustomCss: data => dispatch(setNavbarCustomCss(data))
  };
};
class CategorySearchPage extends React.Component {
  constructor() {
    super();
    this.state = {
      category: "",
      keyword: "",
      from: 0,
      size: 5,
      CoachesResultSet: [],
      categoriesGroupedInMainCategory: [],
      mainCategory: "Business"
    };
  }
  componentDidMount = async () => {
    var self = this;
    this.props.setNavbarCustomCss({
      navbarMode: "darkmode"
    });
    var categoriesGroupedInMainCategory = this.props.navItems;
    var mainCategory = "Business";
    Object.keys(categoriesGroupedInMainCategory).forEach(function(key) {
      var categoryArray = categoriesGroupedInMainCategory[key];

      if (categoryArray.indexOf(self.props.match.params.category) >= 0) {
        mainCategory = key;
      }
    });

    var HeaderImageClass = categoryImages[mainCategory];

    let res_FeaturedCoaches = await api.searchByCategoryFeaturedUsers(
      this.props.match.params.category
    );
    let FeaturedCoaches = res_FeaturedCoaches.data.data;

    FeaturedCoaches = FeaturedCoaches.map(obj => ({ ...obj, featured: true }));

    let res_NonFeaturedCoaches = await api.getSearchResultsByCategory(
      this.props.match.params.category,
      0,
      this.state.size
    );
    let NonFeaturedCoaches = res_NonFeaturedCoaches.data.data;

    var CoachesResultSet = [...FeaturedCoaches, ...NonFeaturedCoaches];
    var hasMore = false;
    if (NonFeaturedCoaches.length > 0) {
      hasMore = true;
    }

    this.setState({
      hasMore: hasMore,
      CoachesResultSet: CoachesResultSet,
      from: this.state.size,
      HeaderImageClass: HeaderImageClass,
      categoriesGroupedInMainCategory: categoriesGroupedInMainCategory
    });
  };
  componentDidUpdate = async (prevProps, prevState) => {
    var self = this;
    if (prevProps != this.props) {
      if (
        prevProps.match.params.category !== this.props.match.params.category
      ) {
        var mainCategory = "Business";
        Object.keys(this.state.categoriesGroupedInMainCategory).forEach(
          function(key) {
            var categoryArray = self.state.categoriesGroupedInMainCategory[key];

            if (categoryArray.indexOf(self.props.match.params.category) >= 0) {
              mainCategory = key;
            }
          }
        );
        var HeaderImageClass = categoryImages[mainCategory];

        let res_FeaturedCoaches = await api.searchByCategoryFeaturedUsers(
          this.props.match.params.category
        );
        let FeaturedCoaches = res_FeaturedCoaches.data.data;

        FeaturedCoaches = FeaturedCoaches.map(obj => ({
          ...obj,
          featured: true
        }));
        let res_NonFeaturedCoaches = await api.getSearchResultsByCategory(
          this.props.match.params.category,
          0,
          this.state.size
        );
        let NonFeaturedCoaches = res_NonFeaturedCoaches.data.data;
        var CoachesResultSet = [...FeaturedCoaches, ...NonFeaturedCoaches];
        var hasMore = false;
        if (NonFeaturedCoaches.length > 0) {
          hasMore = true;
        }
        this.setState({
          hasMore: hasMore,
          CoachesResultSet: CoachesResultSet,
          from: this.state.size,
          HeaderImageClass: HeaderImageClass
        });
      }
    }
  };
  loadMore = async () => {
    let res = await api.getSearchResultsByCategory(
      this.props.match.params.category,
      this.state.from,
      this.state.size
    );
    var hasMore = false;
    if (res.data.data.length > 0) {
      hasMore = true;
    }
    var nextResults = this.state.CoachesResultSet;
    res.data.data.map(prop => {
      nextResults.push(prop);
    });
    this.setState({
      hasMore: hasMore,
      CoachesResultSet: nextResults,
      from: this.state.from + this.state.size
    });
  };
  spinner = () => {
    return (
      <div className="row">
        <div
          className="col-xl-12"
          style={{
            textAlign: "center",
            position: "absolute",
            top: "20px",
            zIndex: "1000"
          }}
        >
          <Spin size="large"></Spin>
        </div>
      </div>
    );
  };
  render() {
    return (
      <div className="col-xl-12">
        {this.props.search.getSearchResultsByCategoryOperationStatus ==
          "InProcess" ||
          (this.props.search.getSearchCategoryFeaturedUsersOperationStatus ==
            "InProcess" &&
            this.spinner())}
        <section style={{ minHeight: window.innerHeight / 1.5 }}>
          <div
            className={"row " + this.state.HeaderImageClass}
            style={{
              minHeight: window.innerHeight / 1.5,
              maxHeight: window.innerHeight / 1.5
            }}
          >
            <div
              className="col-xl-12"
              style={{ minHeight: window.innerHeight / 1.5 }}
            >
              <div className="row CategorySearchPageRow">
                <div className="col-xl-12" style={{ textAlign: "center" }}>
                  <span className="categorySearchPageTitle">
                    {this.props.match.params.category}
                  </span>
                </div>

                <div className="col-xl-2" style={{ textAlign: "center" }}></div>
                <div
                  className="col-xl-8"
                  style={{ textAlign: "center", paddingTop: "5%" }}
                >
                  <SearchInput history={this.props.history} path="search" />
                </div>
                <div className="col-xl-2" style={{ textAlign: "center" }}></div>
              </div>
            </div>
          </div>
          <div className="row categorySearchBackground">
            <div className="col-xl-1"></div>
            <div className="col-xl-10">
              <div className="row">
                <div className="col-xl-12">
                  <CoachCard
                    loadMore={this.loadMore}
                    hasMore={this.state.hasMore}
                    CoachesResultSet={this.state.CoachesResultSet}
                    length={this.state.CoachesResultSet.length}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-1"></div>
          </div>
        </section>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategorySearchPage);
