import React from 'react';
import { connect } from 'react-redux';
import { getUserProfileInitiated, setNavbarCustomCss } from '../actions/actions'

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClick: () => dispatch(getUserProfileInitiated()),
    setNavbarCustomCss: (data) => dispatch(setNavbarCustomCss(data))
  }
}

class UpgradeSuccess extends React.Component {

  componentDidMount() {
    this.props.setNavbarCustomCss({
      navbarMode:'lightmode'
    })
  }

  render() {
    return (
      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "90vh" }}>
          <p>Congratulations! You have successfully upgraded your profile.</p>
          {/* <button onClick={this.props.onClick}>Get Profile</button> */}
          {/* <p>{JSON.stringify(this.props.oauth.newUser.user.username,null,2)}</p> */}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeSuccess);