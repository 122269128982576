import React from 'react';
import { connect } from 'react-redux';
import '../assets/css/CategoryCard.css';

const mapStateToProps = state => {
  return state
}

class CategoryCard extends React.Component {
  
  render() {
    return (
     
        <div className={"category-card " + this.props.category.image2}>
          <div className="category-card-name-wrapper" id={this.props.category.name2}>
            <h5>{this.props.category.name}</h5>
          </div>
        </div>
     
    )
  }
}

export default connect(mapStateToProps)(CategoryCard);