import { NEW_USER_SIGNED_UP,
  USER_AUTHENTICATION_INNITIATED,
  USER_INFO_RECEIVED,
  USER_TYPE_SELECTED,
  USER_SIGNUP_INITIATED,
  USER_SIGNED_UP_SUCCESS,
  USER_SIGNIN_INITIATED,
  USER_SIGNED_IN_SUCCESS,
  VERIFICATION_CODE_SUCCESS,
  VERIFICATION_CODE_FAILURE,
  USER_SIGNOUT } from '../constants/constants';

const initialState = {
  userInfo: {
    id:'',
    username:'',
    attributes: {
      sub: '',
      identities: '',
      name:'',
      email: '',
      email_verified: false,
    }
  },
  newUser: {
    user: {
      username: null,
    },
    userConfirmed: true,
  },
  isUserAuthenticated: false,
  isAuthenticating: false,
  email: '',
  password: '',
  id: '',
  usertype: '',
  token: '',
  username: '',
  isSignedIn: false,
  emailLoginLoader: false,
  facebookLoginLoader: false,
  googleLoginLoader: false,
}

const oauthReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case NEW_USER_SIGNED_UP:
      return {...state, newUser: action.payload}
    case USER_AUTHENTICATION_INNITIATED:
      return {...state, isAuthenticating: true}
    case USER_INFO_RECEIVED:
      console.log("USER_INFO_RECEIVED")
      console.log(action.payload)
      return { ...state, userInfo: action.payload, isUserAuthenticated: true, isAuthenticating: false }
    case USER_TYPE_SELECTED:
      return { ...state, userType: action.payload }
    case USER_SIGNUP_INITIATED:
      return { ...state, email: action.payload.email, password: action.payload.password, emailLoginLoader: true }
    case USER_SIGNED_UP_SUCCESS:
      return { ...state, email: action.payload.data.email, id: action.payload.data.id, emailLoginLoader: false }
    case USER_SIGNIN_INITIATED:
      return { ...state, email: action.payload.email, password: action.payload.password }
    case USER_SIGNED_IN_SUCCESS:
      return { ...state,token: action.payload.data.token, isSignedIn: true }
    case VERIFICATION_CODE_SUCCESS:
      return { ...state, code_verified: true }
    case VERIFICATION_CODE_FAILURE:
      return { ...state, code_verified: false, verificationError: action.payload.error }
    case USER_SIGNOUT:
      return initialState;
    default:
      return state;
  }
}

export default oauthReducer;