import React from 'react';
import '../assets/css/Badge.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {Tooltip } from "antd";
// import { camel } from "case";

class Badge extends React.Component {
  constructor() {
    super();
    this.state = {
      isSelected: false
    };
  }

  render() {
    let width = this.props.width ? this.props.width : '100px';
    return (
        <div className="badge-card" style={{width: width}} onClick={() => {
            this.setState({isSelected: !this.state.isSelected})
          if(this.props.onClick){
            this.props.onClick(this.props.name);
          }
        }}>
           <Tooltip title={this.props.name}>
            <div className="badge-image" style={{backgroundImage: `url('/assets/${this.props.imgName}')`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
              <div className="d-flex justify-content-right badge-check">
                <span className="">{this.state.isSelected ?  <FontAwesomeIcon icon={faCheckCircle} /> : null }</span>
              </div>
            </div>
            <label>{this.props.count}</label>
            </Tooltip>
        </div>
    )
  }
}

export default Badge;