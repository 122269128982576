import React from 'react';
import '../assets/css/PaymentSelector.css';

function PaymentSelector() {
  return (
    <div className="container d-flex justify-content-center align-items-center pb-2 payment-selector">
      <div className="row">
        <div className="col-4 toggler pointer flex-wrap text-right">Purchase just 30 days </div>
        <div className="col-4 toggle-container">
          <label className="toggle">
            <input type="checkbox" className="check" />
            <b className="b switch"></b>
          </label>
        </div>
        <div className="col-4 toggler pointer text-left">Get 4 month on autobill</div>
      </div>
    </div>
  )
}

export default PaymentSelector;