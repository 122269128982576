import React from "react";
import Rating from "react-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as farStar, faCheckCircle, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faStar as fasStar } from "@fortawesome/free-solid-svg-icons";
import {Link} from 'react-router-dom';
import { connect } from "react-redux";
import Badge from "./Badge";
import "../assets/css/CoachCard.css";
import { getProfileByIdInitiated } from "../actions/actions";
import ProfilePicture from '../components/ProfilePicture';
import AdvertisementCard from "../components/AdvertisementCard";
import badges from '../constants/badges';

import InfiniteScroll from "react-infinite-scroller";

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
      getCoachProfile: (id) => dispatch(getProfileByIdInitiated(id))
  }
}

class CoachCardModalPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mockData: {
        "isOneOnOne": true,
        "isGroupCoaching": true,
        "isFreeTraining": false
      }
    };
  }

  handleOnClick = (id) => {
    console.log(id);
    this.props.getCoachProfile(id);
  }
  render() {
    
    const {
      _id,
      fullName,
      aboutMe,
      rating,
      photoLink,
      
    } = this.props.coach;
     var allBadges=[
      { name: "Great Communicator", count: 0,imgName:"Communicator_img.png" },
      { name: "Very Intuitive", count: 0 ,imgName:"Intuitive_img.png"},
      { name: "Problem Solver", count: 0,imgName:"Problem Solver_img.png"},
      { name: "Very Smart", count: 0,imgName:"Smart_img.png"},
      { name: "A True Specialist", count: 0 ,imgName:"Specialist_img.png"},
      { name: "Always On Time", count: 0 ,imgName:"Time_img.png"},
      { name: "Has Vision", count: 0,imgName:"Vision_img.png"},
      { name: "Great Friend", count: 0,imgName:"Good_Friend_img.png"}];
    if(this.props.coach.badges && Array.isArray(this.props.coach.badges)){

      allBadges=allBadges.map((obj1) => {
        this.props.coach.badges.map((obj2)=>{

          if(obj2.name === obj1.name){
            obj1.count=obj2.count;
            
          }
        });
        return obj1;
       
      }); 
     
    }
    const { isOneOnOne, isGroupCoaching, isFreeTraining } = this.state.mockData
    return (
          <div className="row" style={{border: '1px solid #E3E9F3'}}>
            <div className="col-xl-3" style={{display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', backgroundColor: '#FFFFFF',padding:"40px"}}>
              <ProfilePicture photoLink={photoLink}></ProfilePicture>
            </div>
            <div className="col-xl-6" style={{backgroundColor: '#FFFFFF'}}>
              <div className="row">
                <div className="col-xl-12">
                    <span className="CoachCardName">{fullName}</span>
                    {this.props.featured?
                      <button className="btn btnSponsored">Sponsored</button>:
                     <></>
                    }
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                    <span className="CoachCardAboutMe">{aboutMe}</span>
                </div>
              </div>
              <div className="row" style={{padding:"10px 0px"}}>
                <div className="col-xl-12">
                Ratings
                  <span className="star-block">
                    <Rating
                      emptySymbol={
                        <FontAwesomeIcon
                          icon={farStar}
                          style={{ color: "#DF0000" }}
                          inverse={true}
                        />
                      }
                      fullSymbol={
                        <FontAwesomeIcon
                          icon={fasStar}
                          style={{ color: "#DF0000" }}
                          inverse={true}
                        />
                      }
                      fractions={2}
                      initialRating={rating}
                      readonly
                    />{" "}
                    {rating}
                  </span>
              
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  {(allBadges && allBadges.length > 0) ? (<div>
                  {allBadges.map((badge,i) => {
                    return <Badge name={badge.name} imgName={badge.imgName} count={badge.count} width="50px" key={i} />;
                  })}
                </div>) : (<div/>)}
                </div>
              </div>
        
            </div>
            <div className="col-xl-3" style={{backgroundColor: '#F3F5F9'}}>
              <div className="row" >
                <div className="col-xl-12">
                    <span className="CoachOffers">Coach Offers</span>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                <p> {isOneOnOne ? <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#0B3790' }} /> : <FontAwesomeIcon icon={faTimesCircle} style={{ color: '#0B3790' }} />}  One on One </p>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                <p> {isGroupCoaching ? <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#0B3790' }} /> : <FontAwesomeIcon icon={faTimesCircle} style={{ color: '#0B3790' }} />} Group Coaching</p>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <p> {isFreeTraining ? <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#0B3790' }} /> : <FontAwesomeIcon icon={faTimesCircle} style={{ color: '#0B3790' }} />} Free Training</p>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <Link to={{pathname: `/coach/${fullName?fullName.replace(" ","_"):"noname"}/${_id}`}} className="btn btnLearnMore" onClick={()=> this.handleOnClick(_id)}>
                  Learn more
                  </Link>
                </div>
             </div>
            </div>
          </div>
      
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(CoachCardModalPopup);
