import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as fasStar} from '@fortawesome/free-solid-svg-icons';
import Rating from 'react-rating';
import Badge from '../components/Badge';
import badges from '../constants/badges';

const mapStateToProps = state => {
  return state
}

// const badges = [{
//     name: "Communicator"
// }, {
//     name: "Intuitive"
// }, {
//     name: "Problem Solver"
// }, {
//     name: "Smart"
// }, {
//     name: "Specialist"
// }, {
//     name: "Time"
// }, {
//     name: "Vision"
// }, {
//     name: "Good Friend"
// }]

class RateThisCoach extends React.Component {
  constructor() {
    super();
    this.state = {
        rating: 0,
        comment: '',
        badges: [],
        starRatingAwardded: false
    }
  }

  onBadgeClick = (badge) => {

   
    if (!this.state.badges.includes(badge)) {
        const badges = [badge].concat(this.state.badges);
        this.setState({badges});
    } else {
        const badges = [].concat(this.state.badges);
        const index = badges.indexOf(badge);
        badges.splice(index, 1);
        this.setState({badges});
    }
  }

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  handleChangeRating = (rating) => {
      console.log(rating)
      this.setState({rating: rating, starRatingAwardded: true});
  }

  render() {
    return (
      <div>
            <Modal show={this.props.show} onHide={this.props.handleClose} centered>
                <Modal.Body>
                    <form className="rate-coach-container" onSubmit={(event) => this.props.handleSubmit(event, this.state)}>
                        {/* <h4>Relevant Categories</h4>
                        <h5>How do you know this coach?</h5>
                        <div className="form-group">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                                <label className="form-check-label" for="inlineCheckbox1">Business</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
                                <label className="form-check-label" for="inlineCheckbox2">Finance</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3"/>
                                <label className="form-check-label" for="inlineCheckbox3">Money</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3"/>
                                <label className="form-check-label" for="inlineCheckbox3">Career</label>
                            </div>
                        </div> */}
                        <h4>Rating</h4>
                        <h5>Choose one to five stars</h5>
                        <div className="form-group">
                            <Rating
                            emptySymbol={<FontAwesomeIcon icon={farStar} style={{ color: '#DF0000' }} inverse={true} />}
                            fullSymbol={<FontAwesomeIcon icon={fasStar} style={{ color: '#DF0000' }} inverse={true} />}
                            fractions={2}
                            name="rating"
                            onChange={this.handleChangeRating}
                            initialRating={this.state.rating}
                            />
                        </div>
                        <h4>Badges</h4>
                        <h5>Select up to three badges that describes this individual's best qualities.</h5>
                        <div className="form-group">
                            {
                                badges.map((badge,i) => {
                                    return (<Badge name={badge.name} imgName={badge.imgName} onClick={this.onBadgeClick} key={i}/>);
                                })
                            }
                        </div>
                        <h4>Comments</h4> 
                        <h5>Tell us about your experiences.</h5>
                        <div className="form-group">
                            <textarea className="form-control" name="comment" onChange={this.handleChange} value={this.state.comment} required>

                            </textarea>
                        </div>
                        <div className="form-group">
                                <p className="" style={{overflow: 'scroll', fontSize: '.6rem', height: '50px', width:'auto'}}> I agree that my comment is from first hand experience with this individual or company. I agree not to use any hate speech, aggressive language, phone numbers or links in my comment. I agree that I have made every attempt to resolve any issues or problems with this individual or company prior to leaving any negative comment or review below a 2 star. I give this individual or company permission to email me and resolve any issues directly, if need be. By going against these guidelines, my comment may be removed and my account may get banned. Thank you!</p>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" required/>
                                <label className="form-check-label" htmlFor="inlineCheckbox1"> I agree to the above terms and conditions</label>
                            </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-around'}}>
                            <Button className="btn btnRateThisCoach"  type="submit">
                                Submit Rating
                            </Button>
                            <Button className="btn btnRateThisCoach" onClick={this.props.handleClose}>
                                Close
                            </Button>
                        
                        </div>
                    </form>
                </Modal.Body>
                {/* <Modal.Footer style={{display: 'flex', justifyContent: 'space-around'}}>
                    <Button variant="danger" onClick={() => this.props.handleSubmit(this.state)}>
                        Submit Rating
                    </Button>
                    <a onClick={this.props.handleClose}>
                        Close
                    </a>
                </Modal.Footer> */}
            </Modal>
      </div>
    )
  }
}

export default connect(mapStateToProps)(RateThisCoach);