import React from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { message, Spin,Modal,Button,Icon } from "antd";
import { setNavbarCustomCss } from '../actions/actions';
import {
  baseAddress,
  cfLinkToUpgrade,
  cfLinkToUpgradeLevel3,
  cfLinkToUpgradeLevel4,
  cfLinkToUpgradeLevel5,
  cfLinkToUpgradeLevel6,
  cfLinkToUpgradeLevel6Premium,
  cfLinkToUpgradeLevel6Alt,
  cfLinkToUpgradeLevel6Standard,

} from '../constants/cf_levelupgrade_links';


const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNavbarCustomCss: data => dispatch(setNavbarCustomCss(data))
  }
}

class PlanUpgrade extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true
    }
  }
  componentDidMount=async()=>{
  
    this.props.setNavbarCustomCss({
      navbarMode:'darkmode'
    });
   
    try{
     
      //let user=await Auth.currentUserInfo();
      this.setState({isLoading:false});
      if(this.props.oauth.isUserAuthenticated){

        if(this.props.match.params.level=="all"){

          
          var currentUserLevel=this.props.user.userProfile.level;
          var currentUserEmail=this.props.user.userProfile.email;
          var currentUserAddOnLevel=this.props.user.userProfile.addOnLevel;

         
          if(this.props.user.userProfile.affiliateId && currentUserAddOnLevel){
            
            window.location.href = cfLinkToUpgrade+"?email="+currentUserEmail+"&prev-level="+currentUserLevel+"&affiliateId="+this.props.user.userProfile.affiliateId+"&add-on-prev-level="+currentUserAddOnLevel+"";
          }
          else if (this.props.user.userProfile.affiliateId){
          
            window.location.href = cfLinkToUpgrade+"?email="+currentUserEmail+"&prev-level="+currentUserLevel+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
          }
          else if(currentUserAddOnLevel){
          
            window.location.href = cfLinkToUpgrade+"?email="+currentUserEmail+"&prev-level="+currentUserLevel+"&add-on-prev-level="+currentUserAddOnLevel+"";
          }
          else{
           
            window.location.href = cfLinkToUpgrade+"?email="+currentUserEmail+"&prev-level="+currentUserLevel+"";
          }
          
          // if(this.props.user.userProfile.affiliateId)
          // window.location.href = cfLinkToUpgrade+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
          // else
          // window.location.href = cfLinkToUpgrade+"?email="+this.props.user.userProfile.email+"";
        }
        else if(this.props.match.params.level=="level3"){
          if(this.props.user.userProfile.affiliateId)
          window.location.href = cfLinkToUpgradeLevel3+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
          else
          window.location.href = cfLinkToUpgradeLevel3+"?email="+this.props.user.userProfile.email+"";
        }
        else if(this.props.match.params.level=="level4"){
          if(this.props.user.userProfile.affiliateId)
          window.location.href = cfLinkToUpgradeLevel4+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
          else
          window.location.href = cfLinkToUpgradeLevel4+"?email="+this.props.user.userProfile.email+"";
        }
        else if(this.props.match.params.level=="level5"){
          if(this.props.user.userProfile.affiliateId)
          window.location.href = cfLinkToUpgradeLevel5+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
          else
          window.location.href = cfLinkToUpgradeLevel5+"?email="+this.props.user.userProfile.email+"";
        }
   
        else if(this.props.match.params.level=="level6premium"){
          if(this.props.user.userProfile.affiliateId)
          window.location.href = cfLinkToUpgradeLevel6Premium+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
          else
          window.location.href = cfLinkToUpgradeLevel6Premium+"?email="+this.props.user.userProfile.email+"";
        }
        else if(this.props.match.params.level=="level6alt"){
          if(this.props.user.userProfile.affiliateId)
          window.location.href = cfLinkToUpgradeLevel6Alt+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
          else
          window.location.href = cfLinkToUpgradeLevel6Alt+"?email="+this.props.user.userProfile.email+"";
        }
        else if(this.props.match.params.level=="level6standard"){
          if(this.props.user.userProfile.affiliateId)
          window.location.href = cfLinkToUpgradeLevel6Standard+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
          else
          window.location.href = cfLinkToUpgradeLevel6Standard+"?email="+this.props.user.userProfile.email+"";
        }
        else if(this.props.match.params.level=="level6"){
          if(this.props.user.userProfile.affiliateId)
          window.location.href = cfLinkToUpgradeLevel6+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
          else
          window.location.href = cfLinkToUpgradeLevel6+"?email="+this.props.user.userProfile.email+"";
        }
        else{
          if(this.props.user.userProfile.affiliateId)
          window.location.href = baseAddress+this.props.match.params.level+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
          else
          window.location.href = baseAddress+this.props.match.params.level+"?email="+this.props.user.userProfile.email+"";
        }
      }
      else{
        localStorage.setItem("planupgrade", this.props.match.params.level);
        this.setState({
          messageLevel:"error",
          message:"Please sign in to upgrade your plan."
        },
        () =>this.Notify(),
           setTimeout(() => {
            this.props.history.push('/signin')
          }, 1000)
        
        );

      }
    }
    catch(e){
      console.log(e)
      this.setState({
        messageLevel:"error",
        message:"Please sign in to upgrade your plan."
      },
      () =>this.Notify(),
         setTimeout(() => {
          //this.props.history.push('/signin')
        }, 1000)
      
      );

    }
    
  }
  spinner=()=>{
		return(
		<div className="row">
				<div className="col-xl-12" style={{textAlign:"center",position:"absolute",top:"20px",zIndex:"1000"}}>

				<Spin size="large"></Spin>

			</div>
		</div>)
  }
  Notify = () => {
    if (this.state.messageLevel == "success") {
      message.success(this.state.message);
    } else if (this.state.messageLevel == "error") {
      message.error(this.state.message);
    }
  };
  render(){
    return(
      <div className="col-xl-12">
          {this.state.isLoading && this.spinner()}
          <div className="row searchPageLandingSection"  style={{ minHeight: window.innerHeight/1.5,maxHeight: window.innerHeight/1.5}}>
           
            </div>
      </div>
    )
  }
  
}
export default connect(mapStateToProps,mapDispatchToProps)(PlanUpgrade);