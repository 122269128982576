import React from "react";
import { connect } from "react-redux";
import "../assets/css/Home.css";
import CategoryCard from "../components/CategoryCard";
import CategorySection from "./CategorySection";

import SearchInput from "../components/SearchInput";
import Navbar from "../components/Navbar";
import { message, Spin, Modal,Button,Upload, Icon } from "antd";
import queryString from 'query-string';
import api from '../api';
import {
  getBannerTypeInitiated,
  getFeaturedCoachesInitiated,
  postMessageInitiated,
  updateUserProfileInitiated,
  setNavbarCustomCss
} from "../actions/actions";

import {
  baseAddress,
  cfLinkToUpgrade,
  cfLinkToUpgradeLevel3,
  cfLinkToUpgradeLevel4,
  cfLinkToUpgradeLevel5,
  cfLinkToUpgradeLevel6,
  cfLinkToUpgradeLevel6Premium,
  cfLinkToUpgradeLevel6Alt,
  cfLinkToUpgradeLevel6Standard,

} from '../constants/cf_levelupgrade_links';

const mapStateToProps = state => {
  return state;
};
const mapDispatchToProps = dispatch => {
  return {
    getBannerType: () => dispatch(getBannerTypeInitiated()),
    searchCoaches: category => dispatch(getFeaturedCoachesInitiated(category)),
    contactUs: data => dispatch(postMessageInitiated(data)),
    setNavbarCustomCss: data => dispatch(setNavbarCustomCss(data)),
  };
};
class Home extends React.Component {


  constructor(props) {
    super(props);
    const featuredProfiles = [
    
    ];
  
    this.state = {
      yourEmail: "",
      yourMessage: "",
      coachingCategories: [
        {
          name: "Business",
          name2:"Business Coach",
          image:"CategoryCard_Business", 
          image2:"CategoryCard_Business2", 

          featuredProfiles: featuredProfiles,
         
          displayPage: true,
         
        },
        {
          name: "Life",
          name2:"Life Coach",
          image:"CategoryCard_Life", 
          image2:"CategoryCard_Life2", 
          featuredProfiles: featuredProfiles,
         
          displayPage: true,
        },
        {
          name: "Relationship",
          name2:"Relationship Coach",
          image:"CategoryCard_Relationship",
          image2:"CategoryCard_Relationship2",
          featuredProfiles: featuredProfiles,
         
          displayPage: true, 
        },
        {
          name: "Health",
          name2:"Health Coach",
          image:"CategoryCard_Health",
          image2:"CategoryCard_Health2",
          featuredProfiles: featuredProfiles,
         
          displayPage: true,
          overlay:"HealthCategoryOverlay"
        },
        {
          name: "Fitness",
          name2:"Fitness Trainer",
          image:"CategoryCard_Fitness",
          image2:"CategoryCard_Fitness2",
          featuredProfiles: featuredProfiles,
         
          displayPage: true,
          
        },
        {
          name: "Influencers",
          name2:"Actors",
          image:"CategoryCard_More",
          image2:"CategoryCard_More2",
          featuredProfiles: featuredProfiles,
        
          displayPage: false,
        },
        // {
        //   name: "More",
        //   name2:"Acting Coach",
        //   image:"CategoryCard_More",
        //   image2:"CategoryCard_More2",
        //   featuredProfiles: featuredProfiles,
        
        //   displayPage: false,
        // }
      ],
    
    };
  }
  componentDidMount =async()=> {
   
    this.props.setNavbarCustomCss({
      navLinkColor:"white",
      navBackground:"transparent",
      btnColor: 'white',
      btnBorder: 'white',
      navbarMode:'darkmode'
    });
  
    if(this.props.oauth.isUserAuthenticated){
      this.setState({ yourEmail:this.props.user.userProfile.email });
    }
    if (localStorage.getItem("affiliateId") && this.props.oauth.isUserAuthenticated){
      let affiliateId = localStorage.getItem('affiliateId');

      let userProfile = this.props.user.userProfile;
      if(!userProfile.affiliateId){
        try{
          console.log("setting affiliate id with this user:"+affiliateId);
          let res=await api.updateUserAffiliateId(affiliateId);
          localStorage.removeItem('affiliateId');
          let refreshUserProfile=await api.getUserProfile();
        }
        catch(e){
          console.log(e)
        }
        
      }
    }
    let params = queryString.parse(this.props.location.search)
    if(params.affiliate_id){
      if(!this.props.oauth.isUserAuthenticated){
        localStorage.setItem("affiliateId", params.affiliate_id);
      }
    }
    if (localStorage.getItem("claimProfile") &&
      this.props.oauth.isUserAuthenticated && (this.props.user.userProfile.cognitoUserName.startsWith("Facebook")||this.props.user.userProfile.cognitoUserName.startsWith("Google"))){
        this.props.history.push("/claimProfile");
    }
    if(this.props.oauth.isUserAuthenticated && this.props.user.userProfile.level=="level1" && !this.props.user.userProfile.accountType && !localStorage.getItem("claimProfile"))
    {
      
      this.props.history.push("/account-type");
    }
    if (localStorage.getItem("planupgrade") &&
    this.props.oauth.isUserAuthenticated)
    {
      let level = localStorage.getItem('planupgrade');
      
      localStorage.removeItem('planupgrade');
      this.setState({
        messageLevel:"success",
        message:"Welcome , it seems you want to upgrade your plan.We are redirecting you to our sales funnels where you can upgrade your plan."
      },
      () => {this.Notify();
      });

  
        if(level=="all"){
          var currentUserLevel=this.props.user.userProfile.level;
          var currentUserEmail=this.props.user.userProfile.email;
          var currentUserAddOnLevel=this.props.user.userProfile.addOnLevel;

          if(this.props.user.userProfile.affiliateId && currentUserAddOnLevel){
            window.location.href = cfLinkToUpgrade+"?email="+currentUserEmail+"&prev-level="+currentUserLevel+"&affiliateId="+this.props.user.userProfile.affiliateId+"&add-on-prev-level="+currentUserAddOnLevel+"";
          }
          else if (this.props.user.userProfile.affiliateId){
            window.location.href = cfLinkToUpgrade+"?email="+currentUserEmail+"&prev-level="+currentUserLevel+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
          }
          else if(currentUserAddOnLevel){
            window.location.href = cfLinkToUpgrade+"?email="+currentUserEmail+"&prev-level="+currentUserLevel+"&add-on-prev-level="+currentUserAddOnLevel+"";
          }
          else{
            window.location.href = cfLinkToUpgrade+"?email="+currentUserEmail+"&prev-level="+currentUserLevel+"";
          }
          
          // if(this.props.user.userProfile.affiliateId)
          // window.location.href = cfLinkToUpgrade+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
          // else
          // window.location.href = cfLinkToUpgrade+"?email="+this.props.user.userProfile.email+"";
        }
        else if(level=="level3"){
          if(this.props.user.userProfile.affiliateId)
          window.location.href = cfLinkToUpgradeLevel3+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
          else
          window.location.href = cfLinkToUpgradeLevel3+"?email="+this.props.user.userProfile.email+"";
        }
        else if(level=="level4"){
          if(this.props.user.userProfile.affiliateId)
          window.location.href = cfLinkToUpgradeLevel4+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
          else
          window.location.href = cfLinkToUpgradeLevel4+"?email="+this.props.user.userProfile.email+"";
        }
        else if(level=="level5"){
          if(this.props.user.userProfile.affiliateId)
          window.location.href = cfLinkToUpgradeLevel5+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
          else
          window.location.href = cfLinkToUpgradeLevel5+"?email="+this.props.user.userProfile.email+"";
        }
   
        else if(level=="level6premium"){
          if(this.props.user.userProfile.affiliateId)
          window.location.href = cfLinkToUpgradeLevel6Premium+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
          else
          window.location.href = cfLinkToUpgradeLevel6Premium+"?email="+this.props.user.userProfile.email+"";
        }
        else if(level=="level6alt"){
          if(this.props.user.userProfile.affiliateId)
          window.location.href = cfLinkToUpgradeLevel6Alt+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
          else
          window.location.href = cfLinkToUpgradeLevel6Alt+"?email="+this.props.user.userProfile.email+"";
        }
        else if(level=="level6standard"){
          if(this.props.user.userProfile.affiliateId)
          window.location.href = cfLinkToUpgradeLevel6Standard+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
          else
          window.location.href = cfLinkToUpgradeLevel6Standard+"?email="+this.props.user.userProfile.email+"";
        }
        else if(level=="level6"){
          if(this.props.user.userProfile.affiliateId)
          window.location.href = cfLinkToUpgradeLevel6+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
          else
          window.location.href = cfLinkToUpgradeLevel6+"?email="+this.props.user.userProfile.email+"";
        }
        else{
          if(this.props.user.userProfile.affiliateId)
          window.location.href = baseAddress+level+"?email="+this.props.user.userProfile.email+"&affiliateId="+this.props.user.userProfile.affiliateId+"";
          else
          window.location.href = baseAddress+level+"?email="+this.props.user.userProfile.email+"";
        }
     
  
      
    }

    if (localStorage.getItem("LoginToRateCoachName") && localStorage.getItem("LoginToRateCoachId") &&
    this.props.oauth.isUserAuthenticated)
    {
      let CoachName = localStorage.getItem('LoginToRateCoachName');
      let CoachId = localStorage.getItem('LoginToRateCoachId');
      
      localStorage.removeItem('LoginToRateCoachName');
      localStorage.removeItem('LoginToRateCoachId');
      this.setState({
        messageLevel:"success",
        message:"Welcome, redirecting you to the coach profile whom you want to rate."
      },
      () => {this.Notify();
      });
      

      this.props.history.push("/coach/"+CoachName+"/"+CoachId+"");
     
  
      
    }
   

    // this.props.searchCoaches("Business Coach");
    // this.props.searchCoaches("Life Coach");
    // this.props.searchCoaches("Relationship Coach");
    // this.props.searchCoaches("Health Coach");
    // this.props.searchCoaches("Fitness Trainer");
  }
  componentDidUpdate(prevProps,prevState) {
    if(prevProps!==this.props ){
      if (this.props.message.contactUsOperationCompleted){
        if(this.props.message.contactUsOperationStatus=="Completed_Success"){
          this.setState({messageLevel:"success",
          message:"Your message sent successfully. we will get back to you soon."
          },
          () => {this.Notify();this.closeContactUs()});
        }
        else if(this.props.message.contactUsOperationStatus=="Completed_Failed"){
          this.setState({messageLevel:"error",
          message:"Failed to send your message. Please try again."
          },
          () => {this.Notify();this.closeContactUs()});   
        }
      }
 

    }
  }
  closeContactUs = () => {
    this.setState({ contactUs: false });
  };
  openContactUs = () => {
    this.setState({ contactUs: true });
  };
  submitContactUs = () => {

    
    if(this.state.yourEmail.length==0){
      this.setState(
        { messageLevel: "error", message: "Please eneter your email address." },
        () => this.Notify()
      );
    }
    else if(!this.ValidateEmail(this.state.yourEmail)){
      this.setState(
        { messageLevel: "error", message: "Please eneter a valid email address." },
        () => this.Notify()
      );
    }
    else if(this.state.yourMessage.trim().length==0){
      this.setState(
        { messageLevel: "error", message: "Please write some message." },
        () => this.Notify()
      );
    }
   
    else if(this.state.yourEmail.length>0 &&this.state.yourMessage.length>0 && this.ValidateEmail(this.state.yourEmail))
    {
    const body = {
  
      from: this.state.yourEmail,
      subject: "AuthorityAlert Message",
      text: this.state.yourMessage,
      html: this.state.yourMessage
    };
    this.props.contactUs(body);
    }
   

    
  };
  ValidateEmail=(mail) =>
  {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      return (true)
    }
      
      return (false)
  }
  goToCategory = name => {
    this.props.history.push(`/category/${name}`);
  };
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  spinner=()=>{
		return(
		<div className="row">
			<div className="col-xl-12" style={{textAlign:"center",position:"absolute",top:"20px",zIndex:"1000"}}>
				<Spin size="large"></Spin>
			</div>
		</div>)
  }
  spinnerModalPopup = () => {
    return (
      <div style={{ position: "absolute", left: "50%", top: "10px" }}>
        <Spin size="large"></Spin>
      </div>
    );
  };
  Notify = () => {
    if (this.state.messageLevel == "success") {
      message.success(this.state.message);
    } else if (this.state.messageLevel == "error") {
      message.error(this.state.message);
    }
  };

  render() {
    return (
      <div className="col-xl-12">
           <Modal
            title="Contact Us"
            maskClosable={false}
            onOk={this.submitContactUs}
            onCancel={this.closeContactUs}
            visible={this.state.contactUs}
            footer={[
              <Button
                key="submit"
                className="btnSendMessage"
                onClick={this.submitContactUs}
              >
                Send Message
              </Button>
            ]}
          >
            {this.props.message.contactUsOperationStatus=="InProcess" && this.spinnerModalPopup()}
            <div className="row">
              <div className="col-xl-12">
                <span className="sendEmailLabels">Your Email</span>
              </div>
              <div className="col-xl-12" style={{ paddingTop: "10px" }}>
                <input
                  type="email"
                  className="form-control"
                  name="yourEmail"
                  aria-describedby="emailHelp"
                  onChange={this.handleChange}
                  placeholder="Enter email"
                  value={this.state.yourEmail}
                  
                />
              </div>
              <div className="col-xl-12" style={{ paddingTop: "10px" }}>
                <span className="sendEmailLabels">Your Message</span>
               </div>
               <div className="col-xl-12" style={{ paddingTop: "10px" }}>
                <textarea
                  className="form-control"
                  name="yourMessage"
                  onChange={this.handleChange}
                  value={this.state.yourMessage}
                  ></textarea>
               </div>
            </div>
          </Modal> 
        {this.props.search.getFeaturedCoachesOperationStatus=="InProcess"&&this.spinner()}
        <div className="row HomeTop"  style={{ minHeight: window.innerHeight-170 }}>
          <div className="col-xl-1"></div>
          <div className="col-xl-10" style={{minHeight: window.innerHeight-170}}>
           
              <div className="row HomePageRow">
                  <div className="col-xl-12" style={{textAlign:"center"}}>
                    <span className="HomeLandingSectionTitle">
                    Your Reviews Matter
                    </span>
                  </div>
                  <div className="col-xl-12" style={{textAlign:"center"}}>
                    <span className="HomeLandingSectionSubTitle">Get The Truth About Online Authority Brands.</span>
                  </div>
                  <div className="col-xl-2" style={{textAlign:"center"}}></div>
                  
                  <div className="col-xl-8" style={{textAlign:"center",paddingTop:"5%"}}>
                    <SearchInput history={this.props.history} path="search" />

                  </div>
                  <div className="col-xl-12" style={{textAlign:"center"}}></div>
                </div>
             
           
          </div>
          <div className="col-xl-1"></div>
        </div>
        <div className="row HomeSection1">
          <div className="col-xl-12 HomeSection1Header" style={{textAlign:"center",paddingTop:"50px",paddingBottom:"50px"}}>
            <b>How To Use AuthorityAlert</b>
          </div>
          <div className="col-xl-2">
            
          </div>
          <div className="col-xl-8" style={{textAlign:"center"}}>
            <div className="row">
              <div className="col-xl-4" style={{paddingBottom:"10px"}}>
                <div className="row">
                    <div className="col-xl-12">
                        <img className="HomeSection1Img1"></img>
                    </div>
                    <div className="col-xl-12 HomeSection1Subheader">
                    <b>Create Your Free Account</b>

                    </div>
                    <div className="col-xl-12 HomeSection1Text">
                      <span>Register as a user or as a professional service provider and unlock all the benefits of having your own custom profile.</span>

                    </div>
                  </div>
                </div>
              
              <div className="col-xl-4" style={{paddingBottom:"10px"}}>
                <div className="row">
                  <div className="col-xl-12">
                      <img className="HomeSection1Img2"></img>
                  </div>
                  <div className="col-xl-12 HomeSection1Subheader">
                  <b>Search For Profiles You Know</b>

                  </div>
                  <div className="col-xl-12 HomeSection1Text">
                      <span>Look for anyone that helped you grow, experience breakthroughs, wins and “aha moments.”</span>

                  </div>
                </div>
              </div>
              <div className="col-xl-4" style={{paddingBottom:"10px"}}>
              <div className="row">
                <div className="col-xl-12">
                      <img className="HomeSection1Img3"></img>
                  </div>
                  <div className="col-xl-12 HomeSection1Subheader">
                  <b>Share Your Breakthroughs  </b>

                  </div>
                  <div className="col-xl-12 HomeSection1Text">
                    <span>Tell your story about what you learned, how it helped you and why you're grateful for it. </span>

                  </div>
                  </div>
                </div>
            </div>
          </div>
          <div className="col-xl-2">
          </div>
        </div>
        <div className="row HomeSection2">
          <div className="col-xl-12" style={{textAlign:"center",paddingTop:"50px",paddingBottom:"50px"}}>
            <span className="choosecoaching">Popular Categories</span>
          </div>
        </div>
        <div className="row HomeSection3">
          {this.state.coachingCategories.map((category, i) => {
            return (
              <div className="col-xl-4" style={{padding:"0px"}} onClick={() => this.goToCategory(category.name2)} key={i}>
                <CategoryCard category={category} />
              </div>
              );
            })}
        </div>
        {this.state.coachingCategories.map((category, i) => {
          if (category.displayPage) {
            return (
              <CategorySection category={category} navItems={this.props.navItems} key={`${category}-${i}`} />
            );
          }
        })}
        <div className="row Homefooter">
            <div className="col-xl-1"></div>
            <div className="col-xl-10">
              <div className="row" style={{paddingBottom:"50px",paddingTop:"50px"}}>
              <div className="col-xl-2">
               
                <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                  <span className="spanFooterTextHeader">JV Partner Classifieds</span>
                </div>
                <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                  <span className="footerSpan">
                    Looking For a business partner? Find Program Creators, Rainmakers, Angel Investors, SM Influencers, Mentors, Developers and Virtual Assitants -
                      <a target="_blank" className="footerLinks" href="https://authorityalert.online/jv-partner-classifieds-listing">
                        Click here to view
                      </a>
                    </span>
                </div>
                <div className="col-xl-12" style={{paddingBottom:"10px"}}>

                  <a target="_blank" href="https://authorityalert.online/jv-partner-classifieds-listing">
                    <img className="imageFooter1"></img>
                    </a>
                </div>
              
              </div>
              <div className="col-xl-2">
               
               <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                 <span className="spanFooterTextHeader">Special Offers Page</span>
               </div>
               <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                 <span className="footerSpan">
                   List your Special Offer of free training in one of 250 unique category niches -
                     <a target="_blank" className="footerLinks" href="https://authorityalert.online/special-offers-page">
                       Click here to view
                     </a>
                   </span>
               </div>
               <div className="col-xl-12" style={{paddingBottom:"10px"}}>

               <a  target="_blank" href="https://authorityalert.online/special-offers-page"><img className="imageFooter2"></img></a>
               </div>
             
             </div>
             <div className="col-xl-2">
               
               <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                 <span className="spanFooterTextHeader">Affiliates</span>
               </div>
               <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                 <span className="footerSpan">
                 Become an Affiliate and make 50% commissions on over 9 great products and services, including advertising -
                 
                     <a target="_blank" className="footerLinks" href="https://authorityalert.clickfunnels.com/cr-affiliate-setup-l3-pg/898dd72e866 ">
                       Click here to sign up for a free affiliate account
                     </a>
                   </span>
               </div>
               <div className="col-xl-12" style={{paddingBottom:"10px"}}>

                <a  target="_blank" href="https://authorityalert.clickfunnels.com/cr-affiliate-setup-l3-pg/898dd72e866 ">
                 <img className="imageFooter3"></img>
                 </a>
               </div>
             
             </div>
             <div className="col-xl-2">
               
               <div className="col-xl-12" style={{paddingBottom:"10px"}}>
              
                 <span className="spanFooterTextHeader">Social Media</span>
               </div>
              
               <div className="col-xl-12" style={{paddingBottom:"10px"}}>
               <a  target="_blank" href="https://www.facebook.com/authorityalert/">
                 <img className="imageFooter4"></img>
                 </a>
               </div>
               <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                <a target="_blank" href="https://www.instagram.com/authorityalert/">
                 <img className="imageFooter5"></img>
                 </a>
              </div>
              <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                <a target="_blank" href="https://www.linkedin.com/company/authorityalert/">
                 <img className="imageFooter6"></img>
                 </a>
              </div>
              <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                <a target="_blank" href="https://twitter.com/authorityalert">
                 <img className="imageFooter7"></img>
                 </a>
              </div>
              <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                <a target="_blank" href="https://www.youtube.com/authorityalert">
                 <img className="imageFooter8"></img>
                 </a>
              </div>
              <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                <a target="_blank" href="https://www.pinterest.com/authorityalert">
                 <img className="imageFooter9"></img>
                 </a>
              </div>
              <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                <a target="_blank" href="https://www.snapchat.com/add/authorityalert">
                 <img className="imageFooter10"></img>
                 </a>
              </div>
             
             </div>
             <div className="col-xl-2">
               
               <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                 <span className="spanFooterTextHeader">Access Granted Referrals</span>
               </div>
               <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                 <span className="footerSpan">
                   Become a "Preferred Provider" of the CoachRanking community and become one of our "Go-To" professionals for inquiring site visitors -
                     <a target="_blank" className="footerLinks" href="https://authorityalert.online/access-granted-referrals-welcome">
                       Click here to view
                     </a>
                   </span>
               </div>
               <div className="col-xl-12" style={{paddingBottom:"10px"}}>

                 <a target="_blank" href="https://authorityalert.online/access-granted-referrals-welcome">
                   <img className="imageFooter11"></img>
                   </a>
               </div>
             
             </div>
             <div className="col-xl-2">
               
               <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                 <span className="spanFooterTextHeader">Got Questions?</span>
               </div>
               <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                 <span className="footerSpan">
                      Send us an email and we will get right back with you
                   </span>
               </div>
               <div className="col-xl-12" style={{paddingBottom:"10px"}}>

                    <button
                    onClick={this.openContactUs}
                    className="btn btn-contactus"
                  >
                    Contact Us
                  </button>
               </div>
             
             </div>
         
              </div>
            </div>
            <div className="col-xl-1"></div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
