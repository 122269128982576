import React from 'react';
import { connect } from 'react-redux';
import { Auth , Hub} from 'aws-amplify';
import { Link } from 'react-router-dom';
import { message, Spin,Modal,Button,Icon } from "antd";

import { selectUserType, userSignInInitiated,setNavbarCustomCss } from '../actions/actions';
import '../assets/css/SignIn.css';
const smallMessageDuration=5;
const bigMessageDuration=10;

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUserTypeInput: (event) => dispatch(selectUserType(event.target.value)),
    setNavbarCustomCss: (data) => dispatch(setNavbarCustomCss(data))
  }
}

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password:'',
      isLoading: false
    }
  }

  componentDidMount() {
    Hub.listen('auth', (data) => {
     
      console.log("singin");
      console.log(data);
     
      const { payload } = data

      if(payload.event=="signIn_failure"){
        this.setState({
          messageLevel:"error",
          message:payload.data.message,
          isLoading:false,
          },
          () => {this.Notify("error",payload.data.message,smallMessageDuration)} 
        )
      }
      else  if (payload.event=="signIn"){
         document.location.href = '/'
      }
    })
    this.props.setNavbarCustomCss({
     
      navbarMode:'lightmode',
    });

  } 
  

  handleFacebookClick = async() => {
    this.setState({ isLoading: true });
    Auth.federatedSignIn({customState: 'signin',provider: 'Facebook'});
  //   alert("handleFacebookClick")
  //   try{
  //     let user=await Auth.federatedSignIn({provider: 'Facebook'});
  //     console.log("user")
  //     alert("Facebook user authenticated")
  //     console.log(user)
  //    // this.props.history.push('/');
  //    // window.location.reload();
  //   }
  //   catch(err){
  //       alert(err)
  //   }

   };

  handleGoogleClick = async() => {
    this.setState({ isLoading: true });
    Auth.federatedSignIn({provider: 'Google'})
  //   await Auth.federatedSignIn({provider: 'Google'}).then(user => {
  //     this.props.history.push('/');
  //     window.location.reload();
  //   }).catch(err=>{

  //   })
   };

  componentDidUpdate() {
    // if (this.props.oauth.email && this.props.oauth.isSignedIn && this.props.oauth.token) {
    //   this.props.history.push('/test');
    // }
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value,disableSigninButton:true },()=>{
      if (this.state.email.length > 0 && this.state.password.length > 0){
  
        this.setState({ disableSigninButton:false });
      }
    });
  };

  onSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true,disableButton:true });
		try {
      await Auth.signIn(this.state.email.toLowerCase(), this.state.password);
      this.setState({
        isLoading: false ,disableButton:false,})
			//this.props.history.push('/');
		} catch (e) {

      console.log(e)
      
      this.setState({
        isLoading: false ,
        messageLevel:"error",
        message:e.message,
        isLoading:false,
        disableButton:false,
        },
       // () => {this.Notify("error",e.message,smallMessageDuration);}
     )
			
		}
  }
  spinner=()=>{
		return(
		<div className="row">
				<div className="col-xl-12" style={{textAlign:"center",position:"absolute",top:"20px",zIndex:"1000"}}>

				<Spin size="large"></Spin>

			</div>
		</div>)
  }
  Notify = (type,displaymessage,duration) => {
    if(type=="success"){
      message.success(displaymessage,duration)
      }
      else if(type=="error"){
        message.error(displaymessage,duration)
      }
  };
  ForgotPassword=async()=>{
    try 
    {
      if(this.state.email.length==0){
        this.Notify("error","Please enter your email address.",smallMessageDuration);
        return false;
      }
  
      this.setState({ isLoading: true, disableButton: true, });
      let res=await Auth.forgotPassword(this.state.email.toLowerCase());
      console.log(res)
      this.setState({ isLoading: false,PasswordResetModalPopUpVisible: true, disableButton: false, });
    }
    catch (e) 
    {
      console.log(e);
      this.setState({
        isLoading: false,
        disableButton: false,
      });
      this.Notify("error",e.message,bigMessageDuration);
    }
  }
  handlePasswordReset = async event => {
    try{
      this.setState({
        disableButton: true,
        isLoadingModalPopup:true,
      });
      await Auth.forgotPasswordSubmit(
        this.state.email.toLowerCase(),
        this.state.passwordResetCode,
        this.state.passwordResetNewPassword
      );
      this.Notify("success","Password reset successfully.",smallMessageDuration);
      this.setState({
        isLoadingModalPopup:false,
        disableButton:false,
        PasswordResetModalPopUpVisible:false,
      });
    }
    catch (err){
      console.log(err);
      this.setState({
        isLoadingModalPopup:false,
        disableButton:false,
      });
      this.Notify("error",err.message,bigMessageDuration)
    }
  };
  onModalCancel=()=>{
    this.setState({PasswordResetModalPopUpVisible:false});
  }
  render() {
    return (
      <div className="col-xl-12">
         <Modal
            maskClosable={false}
            title="CHECK YOUR EMAIL FOR A ONE-TIME CONFIRMATION CODE AND PASTE IT HERE WITH YOUR NEW PASSWORD."
            onOk = {this.handlePasswordReset}
            onCancel = {this.onModalCancel}
            visible = {this.state.PasswordResetModalPopUpVisible}
              footer={[
              <Button
                key="submit"
                className="btn btnForgotPasswordSendCode"
                onClick={this.handlePasswordReset}
                disabled={this.state.disableButton}
              >
                Change Password
              </Button>
            ]}>
           <div className="row">
             <div className="col-xl-12">
                <span className="propertyFormLable">  Confirmation Code <span className="required-text">*</span></span>
                <div>
                <input type="text" name="passwordResetCode" className="form-control propertyFormControl"  id="passwordResetCode" value={this.state.passwordResetCode} onChange={this.handleChange} />
                </div>
             </div>
             <div className="col-xl-12" style={{paddingTop:"10px"}}>
              <span className="propertyFormLable">  New Password <span className="required-text">*</span></span>
                <div>
                <input type="password" name="passwordResetNewPassword" className="form-control propertyFormControl"  id="passwordResetNewPassword" value={this.state.passwordResetNewPassword} onChange={this.handleChange} />
                </div>
             </div>
           </div>
         </Modal>
          {this.state.isLoading && this.spinner()}
      <section id="signup-section" style={{ minHeight: window.innerHeight-150}}>
   
        <div className="row" style={{width:"100%"}}>
            <div className="col-xl-2"></div>
            <div className="col-xl-8">
            <div className="row" style={{backgroundColor:"white",paddingBottom:"0px"}}>

           
              <div className="col-xl-5 img_SignIn">
                    {/* <img src="/assets/signUp_side_image.png" alt="" height="72%" width="auto" ></img> */}
              </div>
              <div className="col-xl-1">
                    {/* <img src="/assets/signUp_side_image.png" alt="" height="72%" width="auto" ></img> */}
              </div>
              <div className="col-xl-6 paddingOnLargeScreenOnly">
              <div className="row">
                  <div className="col-xl-12 RemovePaddingFifty">
                    <h5 className="socialMediaSignUp">Login to your account</h5>
                  </div>
                  <div className="col-xl-6" >
                  <button className="loginBtn--facebook"
                    onClick={this.handleFacebookClick}></button>
                  </div>
                  <div className="col-xl-6" >
                  <button className="loginBtn--google"
                    onClick={this.handleGoogleClick}></button>
                  </div>
                  <div className="col-xl-12" style={{paddingTop:"20px"}}>
                    <div className="row" style={{margin:"0px"}}>
                      <div className="col-xl-5" style={{padding:"0px"}}><hr className="hrNotVisibleOnSmallScreen"/></div>
                      <div className="col-xl-2" style={{padding:"0px",textAlign:"center"}}>
                        <p>Or</p>
                      </div>
                      <div className="col-xl-5" style={{padding:"0px"}}><hr className="hrNotVisibleOnSmallScreen"/></div>
                    </div>
                  </div>
                  <div className="col-xl-12 PaddingRemoveOnSmallScreen" >
                  <form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <span className="txbLabel">Email address</span>
                    <input type="email" className="form-control txbSignup"
                      id="email"
                      name="email"
                      aria-describedby="emailHelp" placeholder="Enter email"
                      onChange={this.handleChange}
                      value={this.state.email}></input>
                  </div>
                  <div className="form-group">
                  <span className="txbLabel">Password</span>
                    <input type="password" className="form-control txbSignup"
                      id="password" name="password" placeholder="Password"
                      onChange={this.handleChange}
                      value={this.state.password}></input>
                  </div>
  
                  <div className="d-flex justify-content-between">
                    <button type="submit" disabled={this.state.disableButton ||this.state.disableSigninButton} className="btn btnSignup">Sign In</button>
                    <span className="back" onClick={this.ForgotPassword}  disabled={this.state.disableButton}>Forgot Password ?</span>
                    {/* <Link href="#" className="back" to="signup">Create New User</Link> */}
                  </div>
                </form>
             
                  </div>
              </div>
          
         
            </div>
            </div>
            </div>
            <div className="col-xl-2"></div>
      
          </div>
        </section>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);