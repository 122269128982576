import RateThisCoach from "../containers/RateThisCoach";

class AuthorizationProvider {

    userCanAddStoryVideos=false;
    userCanAddSocialMediaLinks=false;
    userCanAddWebsiteLink=false;
    userCanReceiveEmailDirectly=false;
    userCanDeleteComments=false;
    userCanAddTrainingVideos=false;
    userSubscribedForAdvertizing=false;
    userIsAdmin=false;

    upgradeToLevelThreeMessageString="Upgrade to Level3 to add Links";
    upgradeToLevelFourMessageString="Upgrade to Level4 Four to add Links";
    upgradeToLevelFiveMessageString="Upgrade to Level5 Five to add Links";
    upgradeToLevelSixMessageString="Upgrade to Level6 to add Links";
   
    constructor(level) {

        if(level=="level3"){
            this.userCanAddSocialMediaLinks=true
            this.userCanAddStoryVideos=true;
            this.userCanAddWebsiteLink=true;
            this.userCanDeleteComments=true;
        }
        else if(level=="level4"){
            this.userCanAddSocialMediaLinks=true
            this.userCanAddStoryVideos=true;
            this.userCanAddWebsiteLink=true;
            this.userCanAddTrainingVideos=true;
            this.userCanDeleteComments=true;
           
        }
        else if(level=="level5"){
            this.userCanAddSocialMediaLinks=true
            this.userCanAddStoryVideos=true;
            this.userCanAddWebsiteLink=true;
            this.userCanAddTrainingVideos=true;
            this.userCanReceiveEmailDirectly=true;
            this.userCanDeleteComments=true;
          
        }
        else if(level=="level6alt"){
            this.userSubscribedForAdvertizing=true;
        }
        else if(level=="level6standard"){
            this.userSubscribedForAdvertizing=true;
        }
        else if(level=="level6premium"){
            this.userSubscribedForAdvertizing=true;
        }
        else if(level=="20"){
            this.userCanAddSocialMediaLinks=true
            this.userCanAddStoryVideos=true;
            this.userCanAddWebsiteLink=true;
            this.userCanAddTrainingVideos=true;
            this.userCanReceiveEmailDirectly=true;
            this.userCanDeleteComments=true;
            this.userIsAdmin=true;
        }
        
    }

}
export default AuthorizationProvider;