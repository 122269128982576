import React from "react";
import PaymentSelector from "../components/PaymentSelector";
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { List } from "antd";

 

class PlanCard extends React.Component {


  render() {
    return (
      <React.Fragment>
        {this.props.data ? (
          <div className="row">
            <div className="col-xl-12" style={{textAlign:"center",backgroundColor:"#1E4189"}}>
              <span className="PlanCardTitle">{this.props.data.planName}</span>
            </div>
            <div className="col-xl-12" style={{textAlign:"center"}}>
              <span className="PlanCardChargesPrefix">{this.props.data.prefix}</span>
              <span className="PlanCardCharges">{this.props.data.planCharges}</span>
            </div>
            <div className="col-xl-12" style={{textAlign:"center"}}>
              <span className="PlanDescription">{this.props.data.planDesc}</span>
                
            </div>
            <div className="col-xl-12 antListPlanCard" style={{textAlign:"center",paddingBottom:"70px"}}>
            <List
                
                pagination={{simple:true, defaultPageSize: 10,hideOnSinglePage:true}}
                dataSource={this.props.data.planFeatures}
                renderItem={item => (
                  <List.Item>
                    <span>
                   <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#0B3790',float:"left",margin:"5px 15px 25px 15px" }} />
                   <span className="featuresSpan">{item}</span>
                   </span>
                  </List.Item>
                )}
              />
              {/* <ul className="plan-features">
                {this.props.data.planFeatures.map((feature,i) => {
                  return <li key={i}><FontAwesomeIcon icon={faCheckCircle} style={{ color: '#0B3790', position: 'absolute', left: '-1.5em', fontSize: '1.5em' }} inverse={true} /> {feature}</li>;
                })}
              </ul> */}
            </div>
            <div className="col-xl-12" style={{textAlign:"center",position:"absolute",bottom:"20px"}}>
                {
                  this.props.data.stripePlan ? 
                  <button className="btn btnGetStarted" onClick={() => this.props.getStartedPlanSubscription(this.props.data.planId)}>Get Started</button>
                  :
                  <Link className="btn btnGetStarted" to="/">Get Started</Link>
                }
            </div>
          </div>
        ) : (
          "Not defined"
        )}
      </React.Fragment>
    );
  }
}

export default PlanCard;
