import {
  SUBSCRIPTION_REQUEST_INITIATED,
  SUBSCRIPTION_REQUEST_SUCCESS,
  SUBSCRIPTION_REQUEST_FAILURE,
  SUBSCRIPTION_REQUEST_RESET,
  
} from "../constants/constants";

const initialState = {

  subscriptionRequestOperationCompleted : false,
  subscriptionRequestOperationStatus:"Not_Started",
  subscriptionResult:null,

 
 
};

const subscriptionReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SUBSCRIPTION_REQUEST_INITIATED:
      return { ...state, subscriptionRequestOperationCompleted: false,subscriptionRequestOperationStatus:"InProcess"};
    case SUBSCRIPTION_REQUEST_SUCCESS:
      return { ...state, subscriptionResult:action.payload, subscriptionRequestOperationCompleted: true, subscriptionRequestOperationStatus:"Completed_Success"};
     
    case SUBSCRIPTION_REQUEST_FAILURE:
     
      return { ...state, subscriptionResult: action.payload, subscriptionRequestOperationCompleted: true,subscriptionRequestOperationStatus:"Completed_Failed", };
    case SUBSCRIPTION_REQUEST_RESET:
      return { ...state,  subscriptionRequestOperationCompleted: false,subscriptionRequestOperationStatus:"Not_Started", };
   
    default:
      return state;
  }
};

export default subscriptionReducer;
