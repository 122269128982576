// keyword pills
// showcased coaches card


import React from 'react';
import { connect } from 'react-redux';
import '../assets/css/AdvertisementCard.css'

const mapStateToProps = state => {
  return state
}


class AdvertisementCard extends React.Component {

  constructor(props) {
    super(props);
    this.state={
    }
  }
  componentDidMount() {
  } 
  renderAdvertisementCard=()=>{
    if(window.innerWidth>728){
      if(this.props.oauth.isUserAuthenticated){
        var userLevel=this.props.user.userProfile.level;
        if(userLevel=="level1"){
          return(
          
            <div id="outer">
            <iframe id='aa8024f3' name='aa8024f3' src='https://adserver.coachranking.com/revive/www/delivery/afr.php?refresh=60&amp;zoneid=2&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameBorder='0' scrolling='no' width='728' height='90' allow='autoplay'>
              <a href='https://adserver.coachranking.com/revive/www/delivery/ck.php?n=afcf178f&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'>
                <img src='https://adserver.coachranking.com/revive/www/delivery/avw.php?zoneid=2&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=afcf178f' border='0' alt='' />
                </a></iframe>
                </div>

          )
        }
        else if(userLevel=="level2"){
          return(
          <iframe id='a4623e22' name='a4623e22' src='https://adserver.coachranking.com/revive/www/delivery/afr.php?zoneid=13&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameBorder='0' scrolling='no' width='728' height='90' allow='autoplay'>
            <a href='https://adserver.coachranking.com/revive/www/delivery/ck.php?n=af5c921f&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'>
              <img src='https://adserver.coachranking.com/revive/www/delivery/avw.php?zoneid=13&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=af5c921f' border='0' alt='' />
              </a>
          </iframe>
          )
        }
        else if(userLevel=="level3"){
          return(
            <iframe id='a8097b80' name='a8097b80' src='https://adserver.coachranking.com/revive/www/delivery/afr.php?refresh=60&amp;zoneid=10&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameBorder='0' scrolling='no' width='728' height='90' allow='autoplay'>
              <a href='https://adserver.coachranking.com/revive/www/delivery/ck.php?n=a8b14481&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'>
                <img src='https://adserver.coachranking.com/revive/www/delivery/avw.php?zoneid=10&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a8b14481' border='0' alt='' />
                </a></iframe>
          )
        }
        else if(userLevel=="level4"){
          return(
            <iframe id='a87fedfc' name='a87fedfc' src='https://adserver.coachranking.com/revive/www/delivery/afr.php?refresh=60&amp;zoneid=4&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameBorder='0' scrolling='no' width='728' height='90' allow='autoplay'>
              <a href='https://adserver.coachranking.com/revive/www/delivery/ck.php?n=a9d33ada&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'>
                <img src='https://adserver.coachranking.com/revive/www/delivery/avw.php?zoneid=4&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a9d33ada' border='0' alt='' />
                </a></iframe>
          )
        }
        else if(userLevel=="level5"){
          return(
            <iframe id='af558db7' name='af558db7' src='https://adserver.coachranking.com/revive/www/delivery/afr.php?refresh=60&amp;zoneid=7&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameBorder='0' scrolling='no' width='100%' height='auto' allow='autoplay'>
              <a href='https://adserver.coachranking.com/revive/www/delivery/ck.php?n=ac93cab8&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'>
                <img src='https://adserver.coachranking.com/revive/www/delivery/avw.php?zoneid=7&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=ac93cab8' border='0' alt='' />
                </a></iframe>
          )
        }
      }
      else{
        return(
          <iframe id='a54db46c' name='a54db46c' src='https://adserver.coachranking.com/revive/www/delivery/afr.php?refresh=60&amp;zoneid=3&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameBorder='0' scrolling='no' width='728' height='90' allow='autoplay'>
            <a href='https://adserver.coachranking.com/revive/www/delivery/ck.php?n=af2de567&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'>
              <img src='https://adserver.coachranking.com/revive/www/delivery/avw.php?zoneid=3&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=af2de567' border='0' alt='' />
              </a></iframe>
        )
      }
    }
    else{
      if(this.props.oauth.isUserAuthenticated){
        var userLevel=this.props.user.userProfile.level;
        if(userLevel=="level1"){
          return(
              <a href='https://adserver.coachranking.com/revive/www/delivery/ck.php?n=afcf178f&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'>
                <img style={{width:"100%"}} src='https://adserver.coachranking.com/revive/www/delivery/avw.php?zoneid=2&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=afcf178f' border='0' alt='' />
                </a>
          )
        }
        else if(userLevel=="level2"){
          return(

            <a href='https://adserver.coachranking.com/revive/www/delivery/ck.php?n=af5c921f&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'>
              <img style={{width:"100%"}} src='https://adserver.coachranking.com/revive/www/delivery/avw.php?zoneid=13&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=af5c921f' border='0' alt='' />
              </a>
          )
        }
        else if(userLevel=="level3"){
          return(
              <a href='https://adserver.coachranking.com/revive/www/delivery/ck.php?n=a8b14481&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'>
                <img style={{width:"100%"}} src='https://adserver.coachranking.com/revive/www/delivery/avw.php?zoneid=10&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a8b14481' border='0' alt='' />
                </a>
          )
        }
        else if(userLevel=="level4"){
          return(
              <a href='https://adserver.coachranking.com/revive/www/delivery/ck.php?n=a9d33ada&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'>
                <img style={{width:"100%"}} src='https://adserver.coachranking.com/revive/www/delivery/avw.php?zoneid=4&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a9d33ada' border='0' alt='' />
                </a>
          )
        }
        else if(userLevel=="level5"){
          return(
              <a href='https://adserver.coachranking.com/revive/www/delivery/ck.php?n=ac93cab8&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'>
                <img style={{width:"100%"}} src='https://adserver.coachranking.com/revive/www/delivery/avw.php?zoneid=7&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=ac93cab8' border='0' alt='' />
                </a>
          )
        }
      }
      else{
        return(
            <a href='https://adserver.coachranking.com/revive/www/delivery/ck.php?n=af2de567&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'>
              <img style={{width:"100%"}} src='https://adserver.coachranking.com/revive/www/delivery/avw.php?zoneid=3&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=af2de567' border='0' alt='' />
              </a>
        )
      }
    }
}
  render() {
    
    return (
     
        <div className="row">
          <div className="col-xl-2" ></div>
        <div className="col-xl-8" style={{textAlign:"center",padding:"10px 0px"}}>
           
            {this.props.admin.getBannerTypeResult.isGoogleBanner ? 
            <span>Google Ad Banners</span>
            : 
          this.renderAdvertisementCard()

          }
        </div>
        <div className="col-xl-2" ></div>
        </div>   
     
    )
  }
}

export default connect(mapStateToProps)(AdvertisementCard);