import React from 'react';
import { connect } from 'react-redux';
import { Auth  , Hub} from 'aws-amplify';

import { selectUserType, userSignUpInitiated, authenticateUser, addNewUser,setNavbarCustomCss } from '../actions/actions';
import '../assets/css/SignUp.css';
import { message, Spin,Modal,Button,Icon, notification } from "antd";
const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUserTypeInput: (event) => dispatch(selectUserType(event.target.value)),
    onSubmit: (email, password) => dispatch(userSignUpInitiated(email, password)),
    socailAuth: () => dispatch(authenticateUser()),
    onEmailAuth: (newUser) => dispatch(addNewUser(newUser)),
    setNavbarCustomCss: (data) => dispatch(setNavbarCustomCss(data))
  }
}

class SignUp extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      isLoading: false,
      newUser: null,
      claimProcess: false
    }
  }

  componentDidMount() {
    Hub.listen('auth', (data) => {
    
      console.log(data);
     
      const { payload } = data

      if(payload.event=="signUp_failure"){
        
        this.setState({
          messageLevel:"error",
          message:payload.data.message,
       
          isLoading:false,
          },
          () => {this.Notify();}
          
        )
      }
      else if(payload.event=="signUp") {

        this.setState({
          messageLevel:"success",
          message:"You have successfully signed up using your Email: "+payload.data.user.username+" .Please go to your email box and verify your identity by clicking on the link we sent to you.",
         
          isLoading:false,
          },
          () => {this.openNotificationWithIcon();}
          
        )
        // document.location.href = '/'
      }
    })
    this.props.setNavbarCustomCss({
     
      navbarMode:'lightmode'
    })
    if (localStorage.getItem('claimProfile')) {
      this.setState({claimProcess: true});
      // this.props.history.push('/claimProrofile');
    } 
  } 

  handleFacebookClick = () => {
    //this.props.socailAuth(){ customState: 'xyz' }
    Auth.federatedSignIn({ customState: 'signup',provider: 'Facebook' })
  };

  handleGoogleClick = () => {
    Auth.federatedSignIn({ provider: 'Google' })
    //this.props.socailAuth()
  };

  handleError(error) {
    console.log(error);
  }

  componentDidUpdate() {
    // if (this.props.oauth.newUser.user.username && !this.props.oauth.newUser.userConfirmed) {
    //   this.props.history.push('/thanks');
    // }
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  }

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  }
  spinner=()=>{
		return(
		<div className="row">
				<div className="col-xl-12" style={{textAlign:"center",position:"absolute",top:"20px",zIndex:"1000"}}>

				<Spin size="large"></Spin>

			</div>
		</div>)
  }
  Notify = () => {
    if (this.state.messageLevel == "success") {
      message.success(this.state.message);
    } else if (this.state.messageLevel == "error") {
      message.error(this.state.message);
    }
  };
  openNotificationWithIcon = () => {
    notification[this.state.messageLevel]({
      message: 'Signup Successful',
      description: this.state.message,
      duration: 0,
    });
  };
  onSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });

    try {
      const newUser = await Auth.signUp({
        username: this.state.email.toLowerCase(),
        password: this.state.password
      });
      // this.setState({newUser});
      // console.log(newUser);
      // this.props.onEmailAuth(newUser);
    } catch (e) {

      
      if(e.message=="Username cannot be empty" || e.message=="Password cannot be empty")
      this.setState({
        isLoading: false ,
        messageLevel:"error",
        message:e.message,
        isLoading:false,
        },
        () => {this.Notify();}
     )

      //alert(e.message);
    }

    //this.setState({ isLoading: false });
    // this.props.onSubmit(this.state.email, this.state.password);
  }

  googleSuccessResponse = (response) => {
    console.log(response);
  }

  googleFailureResponse = (response) => {
    console.log(response);
  }

  render() {
    return (
      <div className="col-xl-12">
          {this.state.isLoading && this.spinner()}
         <section id="signup-section" style={{ minHeight: window.innerHeight-150}}>
          <div className="row" style={{width:"100%"}}>
            <div className="col-xl-2"></div>
            <div className="col-xl-8">
            <div className="row" style={{backgroundColor:"white",paddingBottom:"0px"}}>
            <div className="col-xl-5 img_container">
              {/* <img src="/assets/signUp_side_image.png" alt="" height="72%" width="auto" ></img> */}
            </div>
            <div className="col-xl-1"></div>
            {!this.state.claimProcess ?
            <div className="col-xl-6 paddingOnLargeScreenOnly" >
             
                <div className="row">
                  <div className="col-xl-12" style={{paddingBottom:"20px"}}>
                      <h5 className="socialMediaSignUp">Social Media Sign Up</h5>
                  </div>
                  <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                    <span className="useYourExistingAccount">Use your existing social media account to login to this site.</span>
                  </div>
                  <div className="col-xl-6">
                    <button onClick={this.handleFacebookClick} className="loginBtn--facebook"></button>
                  </div>
                  <div className="col-xl-6">
                    <button onClick={this.handleGoogleClick} className="loginBtn--google"></button>
                  </div>
                  
                </div>
               
                  <div className="row">
                    <div className="col-xl-12" style={{paddingTop:"20px"}}>
                      <h5 className="socialMediaSignUp">Email Sign Up</h5>
                    </div>
                    <div className="col-xl-12" style={{paddingBottom:"20px"}}>
                      <span className="useYourExistingAccount">Sign Up using your email.</span>
                    </div>
                    <div className="col-xl-12" style={{paddingBottom:"20px"}}>
                    <form onSubmit={this.onSubmit}>
                      <div className="form-group">
                        <span className="txbLabel">Email address</span>
                        
                        <input type="email" className="form-control txbSignup"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp" placeholder="Enter email"
                          onChange={this.handleEmailChange}
                          value={this.state.email}></input>
                      </div>
                      <div className="form-group">
                        <span className="txbLabel">Create Password</span>
                       
                        <input type="password" className="form-control txbSignup"
                          id="exampleInputPassword1" placeholder="Create Password"
                          onChange={this.handlePasswordChange}
                          value={this.state.password}></input>
                   
                      </div>
                      <div className="d-flex justify-content-between">
                        <button type="submit" className="btn btnSignup">Sign Up</button>
                        <a href="/signin" className="back">Back</a>
                      </div>
                    </form>
                  </div></div>
                 
             
           
          </div>
          : 
          <div className="col-xl-6 paddingOnLargeScreenOnly">

            <div className="row">
                  <div className="col-xl-12" style={{paddingBottom:"20px"}}>
                      <h5 className="socialMediaSignUp">Claim Profile is Under Process</h5>
                  </div>
                  <div className="col-xl-12" style={{paddingBottom:"10px"}}>
                      <p>Profile being claimed: <strong>{localStorage.getItem('name')}</strong></p>
                  </div>
                  <div className="col-xl-12" style={{paddingBottom:"20px"}}>
                     <p>Sign In with your social media account to complete the claim profile process.</p>
                  </div>
                  <div className="col-xl-12" style={{paddingBottom:"20px",textAlign:"center"}}>
                       <img className="imgArrowDown"></img>
                  </div>
                  <div className="col-xl-6">
                    <button onClick={this.handleFacebookClick} className="loginBtn--facebook"></button>
                  </div>
                  <div className="col-xl-6">
                    <button onClick={this.handleGoogleClick} className="loginBtn--google"></button>
                  </div>
                  
                </div>
               
        
          </div>
        }
       
            </div>
            <div className="col-xl-2"></div>
          </div>
         </div>

       
        </section>
      
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);