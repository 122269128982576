import {
  GET_USER_PROFILE_INITIATED,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_RESET,
  UPDATE_USER_PROFILE_INITIATED,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
  UPDATE_USER_PROFILE_RESET,
  UPDATE_USER_TYPE_INITIATED,
  UPDATE_USER_TYPE_SUCCESS,
  UPDATE_USER_TYPE_FAILURE,
  UPDATE_USER_TYPE_RESET,
  SET_NAVBAR_CUSTOM_CSS
} from "../constants/constants";

const initialState = {
  userProfile: {
    "_id":"",
    "email": "",
    "cognitoUserName":"",
    "fullName": "",
    "firstName": "",
    "lastName": "",
    "location": "",
    "city": "",
    "state": "",
    "zip": "",
    "website": "",
    //"linkedIn": "",
    //"fbBusinessPage": "",
    "fbProfilePage": "",
    "aboutMe": "",
    "photoLink": "",
    "videoLink": "",
    "phone": "",
    "categoryIds": [],
    "isOneOnOne": false,
    "isGroupCoaching": false,
    "isFreeTraining": false,
    "isCoach": false,
    "level": "",
    "addOnLevel":"",
    "featuredCategory":"",
    "rating": 0,
    "badges":[]
  },
  navbarCustomCss: {
    navbarMode:'darkmode'
    
 },
  
 
  getUserProfileOperationCompleted : false,
  getUserProfileOperationStatus:"Not_Started",
  userProfileError: {},
 
  updateUserProfileOperationCompleted: false,
  updateUserProfileOperationStatus:"Not_Started",
  
  userProfileUpdateError: {},
};

const userReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_USER_PROFILE_INITIATED:
      return { ...state, getUserProfileOperationCompleted: false,getUserProfileOperationStatus:"InProcess"};
    case GET_USER_PROFILE_SUCCESS:
      return { ...state, userProfile: {...state.userProfile, ...action.payload.data}, getUserProfileOperationCompleted: true, getUserProfileOperationStatus:"Completed_Success"};
    case GET_USER_PROFILE_FAILURE:
      return { ...state, userProfileError: action.payload, getUserProfileOperationCompleted: true,getUserProfileOperationStatus:"Completed_Failed", };
      case GET_USER_PROFILE_RESET:
     
    return { ...state,  getUserProfileOperationCompleted: false,getUserProfileOperationStatus:"Not_Started", };
    
      case UPDATE_USER_PROFILE_INITIATED:
      return { ...state, updateUserProfileOperationCompleted : false,updateUserProfileOperationStatus:"InProcess"  };
    case UPDATE_USER_PROFILE_SUCCESS:

      return { ...state, updatedUserProfile: action.payload, updateUserProfileOperationCompleted: true,updateUserProfileOperationStatus:"Completed_Success" };
    case UPDATE_USER_PROFILE_FAILURE:
      return { ...state, userProfileUpdateError: action.payload, updateUserProfileOperationCompleted: true,updateUserProfileOperationStatus:"Completed_Failed" };
      case UPDATE_USER_PROFILE_RESET:
      return { ...state,  updateUserProfileOperationCompleted: false,updateUserProfileOperationStatus:"Not_Started" };
   
 
    case UPDATE_USER_TYPE_INITIATED:
      return { ...state, updateUserTypeOperationCompleted : false,updateUserTypeOperationStatus:"InProcess"  };
    case UPDATE_USER_TYPE_SUCCESS:
      return { ...state, updateUserTypeOperationCompleted: true,updateUserTypeOperationStatus:"Completed_Success" };
    case UPDATE_USER_TYPE_FAILURE:
      return { ...state, updateUserTypeOperationCompleted: true,updateUserTypeOperationStatus:"Completed_Failed" };
      case UPDATE_USER_TYPE_RESET:
      return { ...state,  updateUserTypeOperationCompleted: false,updateUserTypeOperationStatus:"Not_Started" };
    
      case SET_NAVBAR_CUSTOM_CSS:
      return { ...state, navbarCustomCss: action.payload };
    default:
      return state;
  }
};

export default userReducer;
