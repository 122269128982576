import React from 'react'
import {Auth, API} from "aws-amplify";

 const stripe = window.Stripe('pk_test_qJVZlT77TiJUAyxf7iKLUYVg00hp93AYfn'); // test
// const stripe = window.Stripe('pk_live_tYZCqzqkpNJJxGVyBk631owA00cRUH0biR');  // prod
//
export default class Checkout extends React.Component {
    checkout = async (plan) => {
        stripe.redirectToCheckout({
            clientReferenceId: 'wasif.shahzad@12techllc.com',
            items: [
                {plan: plan, quantity: 1}
            ],
            // customerEmail: 'wasif.shahzad@12techllc.com',
            successUrl: 'https://www.authorityalert.com/success?session_id={CHECKOUT_SESSION_ID}',
            cancelUrl: 'https://www.authorityalert.com/fail'
            // successUrl: 'http://localhost:3000/success?session_id={CHECKOUT_SESSION_ID}',
            // cancelUrl: 'http://localhost:3000/fail'

        }).then((result) => {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
            console.log(result)
        }).catch((err) => {
            console.log(err);
            debugger;
        });


    }

    render() {
        return (
            <div className="container">
                <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "90vh" }}>
                <div className="row border-dark form-group">
                    <div className="col-xl-3 border-dark border-info">
                        <div onClick={() =>this.checkout('plan_GGL94bZIxeNEbd')}  // prod: plan_GKlNTlbxkZdOtB test: plan_GGL94bZIxeNEbd
                             className="ant-btn ant-btn btn_styles_1I0r section__btn ant-btn-primary btn-primary mt-5 form-control"
                             style={{width: "100%"}}>Select $97
                        </div>
                    </div>
                    <div className="col-xl-3">
                        <div onClick={() =>this.checkout('plan_GGLTvnArxR0Xqm')}   //prod; plan_GKlOq40hzcegPS   test: plan_GGLTvnArxR0Xqm
                             className="ant-btn ant-btn btn_styles_1I0r section__btn ant-btn-primary btn-primary mt-5 form-control"
                             style={{width: "100%"}}>Select $297
                        </div>
                    </div>
                    <div className="col-xl-3">
                        <div onClick={() =>this.checkout('plan_GHYXKb8Cw3qzW4')}   //prod: plan_GKlbs0IpC0mq3n    test: plan_GHYXKb8Cw3qzW4
                             className="ant-btn ant-btn btn_styles_1I0r section__btn ant-btn-primary btn-primary mt-5 form-control"
                             style={{width: "100%"}}>Select ALT $250
                        </div>
                    </div>
                    <div className="col-xl-3">
                        <div onClick={() =>this.checkout('plan_GHYX9sb7teDBxh')}      //prod: plan_GKlRj8rFKZFBhF   test: plan_GHYX9sb7teDBxh
                             className="ant-btn ant-btn btn_styles_1I0r section__btn ant-btn-primary btn-primary mt-5 form-control"
                             style={{width: "100%"}}>Select STA $400
                        </div>
                    </div>
                    <div className="col-xl-3">
                        <div onClick={() =>this.checkout('plan_GHYWVlK1VkFb2A')}    //prod: plan_GKlSCoDzHJrWRq     test: plan_GHYWVlK1VkFb2A
                             className="ant-btn ant-btn btn_styles_1I0r section__btn ant-btn-primary btn-primary mt-5 form-control"
                             style={{width: "100%"}}>Select PRE $600
                        </div>
                    </div>

                </div>
                </div>
            </div>
                )
                }
                }
