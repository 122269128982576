import React from "react";
import Rating from "react-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";
import { faStar as fasStar } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import InfoPopup from '../components/InfoPopup';
import ProfilePicture from '../components/ProfilePicture';
import "../assets/css/FeaturedProfileCard.css";

const mapStateToProps = state => {
  return state;
};

class FeaturedProfileCard extends React.Component {

  constructor() {
    super()
    this.state = {}
  }
  
  closeInfoPopup = () => {
    this.setState({infoPopup: false});
  }

  openInfoPopup = () => {
    this.setState({infoPopup: true});
  }

  render() {
    return (
      <li className="featuredProfileCardContainer" key={this.props.key}>
        <InfoPopup show={this.state.infoPopup} handleClose={this.closeInfoPopup} profile={this.props.profile}/>
        <div className="featuredProfileCard" style={{position:"relative"}}>
          <div className="row" style={{width:"100%",alignItems:"center",paddingBottom:"0px"}}>
            <div className="col-xl-5">
                <ProfilePicture photoLink={this.props.profile.photoLink}></ProfilePicture>
                <span className="infoIcon" onClick={() => this.openInfoPopup(this.props.profile)}>i</span>
              
            </div>
            <div className="col-xl-7" style={{textAlign:"center",position:"relative",padding:"0px"}}>
              <div className="row" style={{margin:"0px",paddingBottom:"5px"}}>
                <div className="col-xl-12 featuredProfileCardAboutMe">
                  <Link to={{pathname: `/coach/${this.props.profile.fullName?this.props.profile.fullName.replace(" ","_"):"noname"}/${this.props.profile._id}`}}  style={{ textDecoration: 'none', color: 'black' }}>
                   
                      <h6 className="ProfileCardName">{this.props.profile.fullName.slice(0,35)}</h6>
                      <span>{this.props.profile.aboutMe&& this.props.profile.aboutMe.slice(0,50)}</span>
                    
                  </Link>
                </div>
         
              </div>
           
            
      
            </div>
         
         

          </div>
          <div className="row starBlockExternal" style={{position:"absolute", bottom:"5px",right:"10px"}}>
                <div className="col-xl-12" style={{textAlign:"right",padding:"0px"}}>
                 
                  <span className="star-block">

                    <Rating
                      emptySymbol={<FontAwesomeIcon icon={farStar} style={{ color: '#DF0000' ,padding:"0px 2px" }} inverse={true} />}
                      fullSymbol={<FontAwesomeIcon icon={fasStar} style={{ color: '#DF0000' ,padding:"0px 2px"}} inverse={true} />}
                      fractions={2}
                      initialRating={this.props.profile.rating}
                      readonly
                    /> {this.props.profile.rating}
                  </span>
                </div>
              </div>
      
     
        </div>
      </li>
    );
  }
}

export default connect(mapStateToProps)(FeaturedProfileCard);
