const levelTypes = [
    { name: "", value:"" },
    { name: "Level 1", value:"level1" },
    { name: "Level 2", value:"level2"},
    { name: "Level 3", value:"level3"},
    { name: "Level 4", value:"level4"},
    { name: "Level 5", value:"level5"},
    { name: "Admin", value:"20"},
    {name: "Level 6 Alt", value:"level6alt"},
    {name: "Level 6 Standard", value:"level6standard"},
    { name: "Level 6 Premium", value:"level6premium"}]
  export default levelTypes;