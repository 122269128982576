import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import {userSignedOut} from '../actions/actions';
import {levelRestrictions} from '../constants/level-restrictions';
import logo from "../assets/images/logo_authority.png";
import userIcon from "../assets/images/userIcon.svg";
import api from '../api';
import AuthorizationProvider from '../AuthorizationProvider/authorizationProvider';
import "../assets/css/navbar.css";


// const sections = ["Business", "Life", "Relationship", "Health", "Fitness", "More"];
const navItems = { 
    Business: ['Business', 'Sales', 'Writing', 'Social Media', 'Speaking', 'Wealth', 'eCommerce', 'Entrepreneur', 'Internet Marketing', 'Keynote', 'Business Consultant', 'Affiliate Marketing', 'TeamMoney', 'Real Estate', 'Network Marketing', 'Loan Officer', 'Leadership', 'Finance', 'Cryptocurrency', 'Marketing', 'CareerAds Agency', 'LinkedIn', 'Instagram', 'Facebook', 'Retirement'],
    Life: ['Life','Transformational','Spiritual','ADDHD','Sobriety','Teenager','LBGT','Autism','Kabbalah','Christian','Retreat','Astrologist'],
    Relationship: ['Relationship','Dating','Sex','Marriage','Parenting','Love','Matchmaking','Family','Divorce'],
    Health: ['Health','Wellness','Nutritionist','Meditation','Quit Smoking','Hypnotherapist','Performance','NLP','Reiki','Ayurveda','Acupuncturist','Masseur','Diabetes','Cannabis'],
    Fitness: ['Fitness','Yoga','Tai Chi','Dance','Pilates','MMA','Personal Trainer','Martial Arts','TennisSurfing','Swimming','Wrestling','Soccer Clinic','Softball','Football','Golf','Gymnastics','Climbing','Hockey','Ice Skating','Aerialist','Cheerleading','Baseball','Bowling','Boxing'],
    More: ['Guitar','Executive','Matchmaking Service','Interior Designer','Golf Coach','Modeling Agency','Comedians','Dog Breeder','Inline Skating','Kiteboarding','Acrobatics','Magic','Website Developer','Personal','Trainer','Life Money','Sakes','Recruitment Headhunter','Drums','Mediator','Insurance Salesperson','Angel Investor','Agile','Joint Venture','Photographer','Basketball','Pageant','Tarot','Piano','DJ','Vocals','Acting','Prepper and Survival','CPA','Insurance','Dog Trainers','Horse Trainers','Adoption Agency','Fashion Consultant','Employment Headhunter']
}


const mapStateToProps = state => {
    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(userSignedOut())
    }
}
class Navbar extends React.Component {
    constructor() {
        super();
        this.state = {
            isMounted:false,
            navItems: navItems,
            active:false,
        }
    }
    handleLogout = async event => {
        await Auth.signOut();
        document.location.href = '/'
    };
    componentDidMount() { 
        this.authorizationProvider=new AuthorizationProvider(this.props.user.userProfile.level);
        //this.getNavItems();
        this.setState({isMounted:true});
        
    }
 
    setActive=()=>{
        var setStatus=!this.state.active;
        
        this.setState({active:setStatus});
    }
    render() {
        return (
            this.state.isMounted &&
//             <React.Fragment>
//                 <nav class="navbar navbar-expand-lg navbar-light bg-light">
//   <a class="navbar-brand" href="#">Navbar</a>
//   <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//     <span class="navbar-toggler-icon"></span>
//   </button>

//   <div class="collapse navbar-collapse" id="navbarSupportedContent">
//     <ul class="navbar-nav mr-auto">
//       <li class="nav-item active">
//         <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
//       </li>
//       <li class="nav-item">
//         <a class="nav-link" href="#">Link</a>
//       </li>
//       <li class="nav-item dropdown">
//         <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//           Dropdown
//         </a>
//         <div class="dropdown-menu" aria-labelledby="navbarDropdown">
//           <a class="dropdown-item" href="#">Action</a>
//           <a class="dropdown-item" href="#">Another action</a>
//           <div class="dropdown-divider"></div>
//           <a class="dropdown-item" href="#">Something else here</a>
//         </div>
//       </li>
//       <li class="nav-item">
//         <a class="nav-link disabled" href="#">Disabled</a>
//       </li>
//     </ul>
//     <form class="form-inline my-2 my-xl-0">
//       <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"/>
//       <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
//     </form>
//   </div>
// </nav>
//             </React.Fragment>
           
            
           <React.Fragment>
                <nav className={this.props.user.navbarCustomCss.navbarMode +" navbar navbar-expand-lg navbar-light"} style={{color:this.props.user.navbarCustomCss.navLinkColor,backgroundColor:this.props.user.navbarCustomCss.navBackground}}>
                    <Link className="navbar-brand" to="/">
                        <img  src={logo} alt="coachranking logo" width="auto"></img>
                    </Link>
                    <button 
                        onClick={this.setActive}
                        className="customtoggler" type="button" data-toggle="collapse"
                       >
                           <i className="fa fa-bars fa-2x"></i>
                        {/* <span className="navbar-toggler-icon navbar-dark"></span> */}
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className={`navbar-nav ${this.state.active ? "active" : "notactive"}`}>
                            {
                                Object.keys(this.props.navItems).map(category => {
                                    return <li className="nav-item dropdown" key={category} >
                                        <a className="nav-link dropdown-toggle" href="#"
                                            id="navbarDropdown" role="button" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">
                                            {category}
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        {this.props.navItems[category].map(item => {
                                            return <Link onClick={this.setActive} className="dropdown-item" key={item} to={`/category/${item}`}>{item}</Link>
                                            // return <Link className="dropdown-item" key={item} to={`/category/${category}/${item}`}>{item}</Link>
                                        })}
                                            {/* {
                                                category === 'More' && <Link className="dropdown-item" to="/checkout">Checkout</Link>
                                            } */}

                                        </div>
                                    </li>
                                })
                            }
                        </ul>
                        {this.props.oauth.isUserAuthenticated ? (
                            <div  className={`navbarButtons ${this.state.active ? "active" : "notactive"}`}>
                                    {this.authorizationProvider.userIsAdmin? 
                                    <Link onClick={this.setActive} to="/adminpanel" className="btn btnSignIn"  >Admin</Link>
                                    : <></>}
                                <button className="btn btnSignIn"
                                    id="logout-button"
                                    
                                        onClick={this.handleLogout}>Logout
                                </button>
                                <Link onClick={this.setActive} className="btn btnCreateProfile" to="/profile">View Profile</Link>
                            </div>
                        ) : (
                            <div  className={`navbarButtons ${this.state.active ? "active" : "notactive"}`}>
                                <Link onClick={this.setActive} className="btn btnSignIn" to="/signin" >Sign In <img className="userIconImg" src={userIcon}></img></Link>
                                <Link onClick={this.setActive} className="btn btnCreateProfile" to="/signup">Create Profile</Link>
                            </div>
                        )}
                    </div>
                </nav>
            </React.Fragment>
        
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
