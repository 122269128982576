// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
   // "aws_cognito_identity_pool_id": "us-east-1:17331f53-31d7-4fd9-9892-f50f51888f75",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_0T24TrpX2",
    "aws_user_pools_web_client_id": "3r8bhioch6nr0jef844gdshu0h",
    "oauth": {
        "domain": "authorityalert.auth.us-east-1.amazoncognito.com",
        //"domain": "coach-ranking-deploy-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.authorityalert.com/,http://localhost:3000/",
        "redirectSignOut": "https://www.authorityalert.com/,http://localhost:3000/,https://www.authorityalert.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
