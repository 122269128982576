import React from 'react';
import { connect } from 'react-redux';
import { getUserProfileInitiated } from '../actions/actions'

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClick: () => dispatch(getUserProfileInitiated())
  }
}

class UpgradeFail extends React.Component {

  componentDidMount() {
    console.log(this.props);
  }

  render() {
    return (
      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "90vh" }}>
          <p>We could not process your payment. Please retry.</p>
          {/* <button onClick={this.props.onClick}>Get Profile</button> */}
          {/* <p>{JSON.stringify(this.props.oauth.newUser.user.username,null,2)}</p> */}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeFail);